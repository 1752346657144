/* eslint-disable max-len */
function XCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_23_40569)">
        <path
          d="M12.4998 7.50855L7.49984 12.4914M7.49984 7.50855L12.4998 12.4914M18.3332 9.99999C18.3332 14.5866 14.6022 18.3048 9.99984 18.3048C5.39746 18.3048 1.6665 14.5866 1.6665 9.99999C1.6665 5.41338 5.39746 1.69519 9.99984 1.69519C14.6022 1.69519 18.3332 5.41338 18.3332 9.99999Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_40569">
          <rect width="20" height="19.9315" fill="white" transform="translate(0 0.0341797)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default XCircle;
