import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EventEntryRecord')
export class EventEntryRecord {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('value', String)
  value: string;

  @JsonProperty('type', String)
  type: string;
}

type TConstructorParams = {
  name: EventEntryRecord['name'];
  value: EventEntryRecord['value'];
  type: EventEntryRecord['type'];
};

export function constructEventEntryRecord({ name, value, type }: TConstructorParams) {
  const instance = new EventEntryRecord();
  instance.name = name;
  instance.value = value;
  instance.type = type;
  return instance;
}
