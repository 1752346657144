import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';

@JsonObject('StorageTagDeletionStatus')
export class StorageTagDeletionStatus {
  @JsonProperty('soft', Boolean)
  soft: boolean;

  @JsonProperty('marked_for_delete_on', String)
  markedForDeleteOn: string;
}

@JsonObject('StorageTagRecord')
export class StorageTagRecord {
  @JsonProperty('storage_tag', String)
  storageTag: string;

  @JsonProperty('events_count', Number)
  eventsCount: number;

  @JsonProperty('size', Number)
  size: number;

  @JsonProperty('inputs', [String])
  inputs: string[];

  @JsonProperty('deletion_status', StorageTagDeletionStatus)
  deletionStatus: StorageTagDeletionStatus;

  @JsonProperty('display_name', String)
  displayName: string;
}

@JsonObject('StorageTagsResponse')
export class StorageTagsResponse extends ResponseCommon {
  @JsonProperty('entries', [StorageTagRecord])
  entries: StorageTagRecord[];
}
