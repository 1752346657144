import { TComponentsOverrides, TPaletteOverrides } from '../../branding.types';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { alpha } from '@mui/material';

export const getMuiTextFieldStyles = (palette: TPaletteOverrides): TComponentsOverrides => {
  const color = {
    focused: palette.info.main,
    active: palette.grey.A100,
    error: palette.error.main,
    disabled: palette.secondary.light,
    disabledText: palette.secondary[500],
    disabledBackground: palette.secondary.main
  };

  const typography = {
    small: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 400
    },
    medium: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 400
    },
    large: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 400
    }
  };

  const paddings = {
    small: {
      padding: '6px 8px 6px 12px'
    },
    medium: {
      padding: '10px 12px'
    },
    large: {
      padding: '14px 12px'
    }
  };

  return {
    // HELPER
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: '4px',
          lineHeight: '20px',
          fontSize: '12px',
          fontWeight: 400,
          color: palette.secondary[300]
        }
      }
    },

    // INPUT BASE
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: color.disabledText
          }
        },
        formControl: {
          'label[data-shrink=false].MuiFormLabel-root ~ & ::-webkit-input-placeholder': {
            opacity: '1!important'
          }
        }
      }
    },

    // INPUT LABEL
    MuiInputLabel: {
      styleOverrides: {
        shrink: false,
        root: {
          ['&.Mui-disabled']: {
            color: color.disabledText
          },
          ['&.Mui-focused']: {
            color: color.active
          },
          ['&.Mui-error']: {
            color: color.active
          },
          transform: 'none',
          position: 'relative',
          marginBottom: '4px',
          pointerEvents: 'none',
          variants: [
            {
              props: { size: 'large' },
              style: {
                fontSize: '14px',
                lineHeight: '24px'
              }
            },
            {
              props: { size: 'medium' },
              style: {
                fontSize: '14px',
                lineHeight: '24px'
              }
            },
            {
              props: { size: 'small' },
              style: {
                fontSize: '12px',
                lineHeight: '20px'
              }
            }
          ]
        }
      }
    },

    // LABEL
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: color.error
        },
        root: {
          color: color.active
        }
      }
    },

    // OUTLINED
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: color.active,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.secondary.light,
            borderWidth: '1px',
            legend: {
              width: 'auto'
            }
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.secondary.light,
            borderWidth: '1px'
          },
          [`&.${outlinedInputClasses.focused}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: color.focused,
              backgroundColor: alpha(palette.tertiary[700], 0.05),
              borderWidth: '1px'
            },
            [`:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: color.focused,
              borderWidth: '1px'
            }
          },
          [`&.${outlinedInputClasses.error}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: color.error,
              backgroundColor: alpha(palette.error.main, 0.05),
              borderWidth: '1px'
            },
            [`:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: color.error,
              borderWidth: '1px'
            }
          },
          [`&.${outlinedInputClasses.disabled}`]: {
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: color.disabled,
              backgroundColor: color.disabledBackground,
              borderWidth: '1px'
            },
            [`:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: color.disabled,
              borderWidth: '1px'
            }
          },
          variants: [
            {
              props: { size: 'large' },
              style: {
                padding: paddings.large.padding,
                ...typography.large
              }
            },
            {
              props: { size: 'medium' },
              style: {
                padding: paddings.medium.padding,
                ...typography.medium
              }
            },
            {
              props: { size: 'small' },
              style: {
                padding: paddings.small.padding,
                ...typography.small
              }
            }
          ]
        },
        input: {
          padding: 0,
          '&[type=number]': {
            MozAppearance: 'textfield'
          },
          '&[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0
          },
          '&[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0
          }
        },
        notchedOutline: {
          transform: 'none',
          ['> legend > span']: {
            display: 'none'
          }
        }
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            top: 0
          },
          '& label + div': {
            '& .MuiOutlinedInput-notchedOutline': {
              top: '-5px'
            }
          },
          '& .MuiFormHelperText-root': {
            fontSize: '12px',
            lineHeight: '20px',
            fontWeight: 400,
            color: palette.secondary[300]
          }
        }
      }
    }
  };
};
