import { TFilterItem, TruncatedText } from '@arcanna/components';
import { Table } from '@arcanna/generic';
import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'react-i18next';
import { TEventSampleDrawerTableData } from './EventSampleDrawerTable.types';

type TGetColumnsParams = {
  t: TFunction;
  getFilterSourceByName: (name: string) => string | undefined;
  activeFilters: TFilterItem[];
  addAdvancedFilter: (filterItem: TFilterItem) => void;
};

export const getColumns = ({ t, getFilterSourceByName, activeFilters, addAdvancedFilter }: TGetColumnsParams) => {
  const columnHelper = createColumnHelper<TEventSampleDrawerTableData>();

  return [
    columnHelper.accessor('key', {
      id: 'name',
      header: String(t('codeBlock:editTestInputDrawer.tableTab.columns.fieldName')),
      cell: ({ row }) => <TruncatedText variant="subtitle2" text={row.original.key} />,
      size: 120
    }),
    columnHelper.accessor('value', {
      id: 'value',
      header: String(t('codeBlock:editTestInputDrawer.tableTab.columns.value')),
      cell: ({ row, tableSize, customRowSizeValue }) => (
        <Table.GeneralCellWithFilters
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          columnName={getFilterSourceByName(row.original.key)!}
          filterSource={getFilterSourceByName(row.original.key)}
          // @ts-ignore
          value={row.original.value}
          activeFilters={activeFilters}
          addAdvancedFilter={addAdvancedFilter}
          tableSize={tableSize}
          customRowSizeValue={customRowSizeValue}
        />
      )
    })
  ];
};
