import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'src/components/pages/Main/Login/Auth.context';

type TUsePageLeaveBlockerProps = {
  message: string;
  isBlocked: boolean;
};

export function usePageLeaveBlocker({ isBlocked, message }: TUsePageLeaveBlockerProps) {
  const { state: authState } = useAuth();
  const history = useHistory();

  // External navigation: refresh, close tab, etc.
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!isBlocked || authState.isLoggingOut) return;

      event.preventDefault();
      event.returnValue = message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isBlocked, message, authState.isLoggingOut]);

  // Internal navigation: React Router route changes
  useEffect(() => {
    if (!isBlocked || authState.isLoggingOut) return;

    const unblock = history.block(message);

    return () => {
      unblock();
    };
  }, [isBlocked, message, history, authState.isLoggingOut]);
}
