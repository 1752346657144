/* eslint-disable max-len */
function Rocket() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_16205_263126)">
        <path
          d="M10.0002 12.4913L7.50016 9.99983M10.0002 12.4913C11.1642 12.0501 12.2809 11.4936 13.3335 10.8303M10.0002 12.4913V16.6437C10.0002 16.6437 12.5252 16.1869 13.3335 14.9827C14.2335 13.6373 13.3335 10.8303 13.3335 10.8303M7.50016 9.99983C7.94362 8.8533 8.502 7.75427 9.16683 6.71944C10.1378 5.17224 11.4898 3.89833 13.0943 3.01889C14.6988 2.13945 16.5024 1.68371 18.3335 1.69503C18.3335 3.95394 17.6835 7.92363 13.3335 10.8303M7.50016 9.99983H3.3335C3.3335 9.99983 3.79183 7.48348 5.00016 6.67791C6.35016 5.78099 9.16683 6.67791 9.16683 6.67791M3.75016 13.737C2.50016 14.7834 2.0835 17.8894 2.0835 17.8894C2.0835 17.8894 5.20016 17.4742 6.25016 16.2284C6.84183 15.5308 6.8335 14.4595 6.17516 13.8117C5.85125 13.5036 5.42457 13.3256 4.97702 13.3118C4.52946 13.298 4.09256 13.4494 3.75016 13.737Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16205_263126">
          <rect width="20" height="19.9315" fill="white" transform="translate(0 0.0341797)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Rocket;
