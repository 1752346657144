import { Table } from '@arcanna/generic';
import { Stack } from '@mui/material';
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ModelHistory,
  TMetricPerDecisionRecord
} from 'src/components/shared/models/retrain-history/JobAllRetrainHistoryResponse';
import { getColumns } from './ModelHistoryInsights.utils';
import { useJobInfo } from 'src/data-access';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { getCustomLabelsFromJobs } from 'src/components/pages/Main/Jobs/Feedback/Flow/SelectFeedback';

type TModelHistoryInsightsProps = {
  AIModel: ModelHistory;
};

export type TModelHistoryInsightsTableEntry = { label: string } & TMetricPerDecisionRecord;

function ModelHistoryInsights({ AIModel }: TModelHistoryInsightsProps) {
  const { t } = useTranslation(['overview']);
  const { jobId } = useJobIdFromUrl();

  const { data: jobInfoData } = useJobInfo(jobId);
  const customLabels = useMemo(() => (jobInfoData ? getCustomLabelsFromJobs([jobInfoData]) : []), [jobInfoData]);

  const tableData: TModelHistoryInsightsTableEntry[] = useMemo(() => {
    if (!AIModel.newModelMetrics.metricsPerDecision) {
      return [];
    }

    return Object.keys(AIModel.newModelMetrics.metricsPerDecision).map((customLabel) => ({
      label: customLabel,
      ...AIModel.newModelMetrics.metricsPerDecision[customLabel]
    }));
  }, [AIModel.newModelMetrics.metricsPerDecision]);

  const columns = useMemo(() => getColumns(t, customLabels), [t, customLabels]);

  const tableInstance = useReactTable<TModelHistoryInsightsTableEntry>({
    data: tableData,
    columns: columns,
    enableSorting: true,
    enableHiding: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableColumnPinning: false,
    enableColumnResizing: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  return (
    <Stack gap={2}>
      <Table.component<TModelHistoryInsightsTableEntry>
        tableInstance={tableInstance}
        noResultsMessageTitle={t('common:noResults')}
        noResultsMessageSubtitle={t('overview:nothing')}
        isTableResizable={false}
        defaultTableSize={Table.TABLE_SIZES.small}
      />
      <Table.TablePagination<TModelHistoryInsightsTableEntry> tableInstance={tableInstance} />
    </Stack>
  );
}

export default ModelHistoryInsights;
