import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '@arcanna/models/utils';

@JsonObject('GetEventGroupResponseRecordMetrics')
export class GetEventGroupResponseRecordMetrics {
  @JsonProperty('total_events', Number)
  totalEvents: number;

  @JsonProperty('low_confidence', Number)
  lowConfidence: number;

  @JsonProperty('outliers', Number)
  outliers: number;
}

@JsonObject('GetEventGroupResponseRecord')
export class GetEventGroupResponseRecord {
  @JsonProperty('value', String)
  name: string;

  @JsonProperty('metrics', GetEventGroupResponseRecordMetrics)
  metrics: GetEventGroupResponseRecordMetrics;
}

@JsonObject('GetEventGroupResponse')
export class GetEventGroupResponse extends ResponseCommon {
  @JsonProperty('values', [GetEventGroupResponseRecord])
  values: GetEventGroupResponseRecord[];

  @JsonProperty('query_caching_id', String)
  queryCachingId: string;

  @JsonProperty('total', Number)
  total: number;
}
