/* eslint-disable max-len */
export default function TagIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <rect width="36" height="36" rx="18" fill="#3B82F6" fillOpacity="0.1" />
      <path
        d="M14.6667 14.6667H14.675M9.66669 12.3334L9.66669 16.0622C9.66669 16.4698 9.66669 16.6737 9.71274 16.8655C9.75356 17.0355 9.82091 17.1981 9.91229 17.3472C10.0154 17.5154 10.1595 17.6595 10.4477 17.9478L16.8383 24.3383C17.8283 25.3284 18.3233 25.8234 18.8941 26.0089C19.3963 26.172 19.9371 26.172 20.4392 26.0089C21.0101 25.8234 21.5051 25.3284 22.4951 24.3383L24.3383 22.4952C25.3283 21.5051 25.8233 21.0101 26.0088 20.4393C26.1719 19.9372 26.1719 19.3963 26.0088 18.8942C25.8233 18.3234 25.3283 17.8284 24.3383 16.8383L17.9477 10.4478C17.6595 10.1595 17.5154 10.0154 17.3472 9.91235C17.198 9.82097 17.0355 9.75363 16.8654 9.7128C16.6736 9.66675 16.4698 9.66675 16.0621 9.66675L12.3334 9.66675C11.3999 9.66675 10.9332 9.66675 10.5767 9.8484C10.2631 10.0082 10.0081 10.2632 9.84834 10.5768C9.66669 10.9333 9.66669 11.4 9.66669 12.3334ZM15.0834 14.6667C15.0834 14.8969 14.8968 15.0834 14.6667 15.0834C14.4366 15.0834 14.25 14.8969 14.25 14.6667C14.25 14.4366 14.4366 14.2501 14.6667 14.2501C14.8968 14.2501 15.0834 14.4366 15.0834 14.6667Z"
        stroke="#3B82F6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
