/* eslint-disable max-len */
function Share() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M17.5 5.01708H14.8333C13.4332 5.01708 12.7331 5.01708 12.1984 5.28863C11.728 5.52749 11.3455 5.90863 11.1058 6.37743C10.8333 6.91037 10.8333 7.60804 10.8333 9.00338V9.99996M17.5 5.01708L15 2.52563M17.5 5.01708L15 7.50852M8.33333 2.52563H6.5C5.09987 2.52563 4.3998 2.52563 3.86502 2.79719C3.39462 3.03605 3.01217 3.41719 2.77248 3.88599C2.5 4.41893 2.5 5.1166 2.5 6.51194V13.488C2.5 14.8833 2.5 15.581 2.77248 16.1139C3.01217 16.5827 3.39462 16.9639 3.86502 17.2027C4.3998 17.4743 5.09987 17.4743 6.5 17.4743H13.5C14.9001 17.4743 15.6002 17.4743 16.135 17.2027C16.6054 16.9639 16.9878 16.5827 17.2275 16.1139C17.5 15.581 17.5 14.8833 17.5 13.488V11.6609"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Share;
