import Fuse from 'fuse.js';
import moment from 'moment';
import { JobSummaryRecord } from '../../../shared/models/job/JobSummaryRecord';
import { CustomLabel } from '../../../shared/models';
import { AlertType } from '../../../../constants';

function getSearchOptions() {
  return {
    keys: ['title', 'jobDetails.category', 'jobDetails.subcategory'],
    useExtendedSearch: true
  };
}

export enum StatusType {
  ERROR = 'ERROR',
  IDLE = 'IDLE',
  STARTED = 'STARTED',
  INVALID = 'INVALID'
}

export function searchTexts(items: JobSummaryRecord[], searchedTexts: string[]) {
  const fuseSearch = new Fuse(items, getSearchOptions());
  return fuseSearch.search(searchedTexts.join(' ')).map((result: Fuse.FuseResult<JobSummaryRecord>) => result.item);
}

export const feedbackColors = [
  'var(--feedback-color-0)',
  'var(--feedback-color-1)',
  'var(--feedback-color-2)',
  'var(--feedback-color-3)',
  'var(--feedback-color-4)',
  'var(--feedback-color-5)',
  'var(--feedback-color-6)',
  'var(--feedback-color-7)',
  'var(--feedback-color-7)',
  'var(--feedback-color-9)'
];

export const feedbackLabelColors = ['var(--primary-color)', 'var(--warning-500-color)'];

export const feedbackLabelColor = (label: string, labels: string[]) => {
  if (labels === null) return null;
  if (labels.length === 0) return null;
  const index = labels.indexOf(label);
  if (index !== -1) {
    return feedbackLabelColors[index];
  }
  return null;
};

// eslint-disable-next-line
export function guessDate(timestamp: any): string {
  if (moment(timestamp, moment.ISO_8601).isValid()) {
    return moment(timestamp, moment.ISO_8601).toISOString();
  }

  if (moment(timestamp, 'X').isValid() && timestamp.toString().length <= 10) {
    // First timestamp with 11 digits will be in the year 2286
    return moment(timestamp, 'X').toISOString();
  }

  if (moment(timestamp / 1000, 'X').isValid()) {
    return moment(timestamp / 1000, 'X').toISOString();
  }

  return String(timestamp);
}

// eslint-disable-next-line
export function convertDateToISO8601(timestamp: any, type?: string): string {
  if (!type || type === 'date') {
    return guessDate(timestamp);
  }
  switch (type) {
    case 'date_iso8601':
      return moment(timestamp, moment.ISO_8601).toISOString();
    case 'date_unix':
      return moment(timestamp, 'X').toISOString();
    case 'date_millis':
      return moment(timestamp / 1000, 'X').toISOString();
    default:
      return String(timestamp);
  }
}

// eslint-disable-next-line
export function getValueFromType(value: any, type: string): string {
  if (!type) {
    return value;
  }
  if (type.includes('date')) {
    return convertDateToISO8601(value, type);
  }
  return value;
}

// eslint-disable-next-line
export function convertElasticsearchDateToMomentsAgo(timestamp: any): string {
  if (moment(timestamp).isValid()) {
    return moment(timestamp).fromNow();
  }

  if (moment(moment.unix(timestamp)).isValid() && moment(moment.unix(timestamp)).isBefore()) {
    return moment(moment.unix(timestamp)).fromNow();
  }

  if (moment(moment.unix(timestamp / 1000)).isValid() && moment.unix(timestamp / 1000).isBefore()) {
    return moment(moment.unix(timestamp / 1000)).fromNow();
  }

  return timestamp;
}

export function getShortString(str_to_limit: string, length_limit = 25): string {
  return str_to_limit.length <= length_limit ? str_to_limit : `${str_to_limit.substring(0, length_limit - 2)}..`;
}

export const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const dateDiffInDays = (a: Date, b: Date) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const getPeriodicity = (startDate: Date, endDate: Date) => {
  let endDateNotNull = endDate;
  if (endDateNotNull === null) {
    endDateNotNull = new Date();
  }
  const diffDays = dateDiffInDays(startDate, endDateNotNull);

  return (diffDays * 3600 * 24) / 30;
};

export const labelColorClass: Record<string, string> = {
  [AlertType.DROP_ALERT]: 'feedbackColorDrop',
  [AlertType.ESCALATE_ALERT]: 'feedbackColorEscalate'
};

export enum JobCategory {
  RCA = 4,
  DECISION_INTELLIGENCE = 1,
  EVENT_CENTRIC_DECISION_INTELLIGENCE = 7
}

export const UNDECIDED_CONSENSUS_ID = 'undecided';

export const noDecisionResult = new CustomLabel('No decision', '#696C78', 'no_decision');
export const undecidedConsensus = new CustomLabel('Undecided', '#696C78', UNDECIDED_CONSENSUS_ID);

export const isDecisionIntelligenceCategory = (categoryId?: number) =>
  categoryId === JobCategory.DECISION_INTELLIGENCE || categoryId === JobCategory.EVENT_CENTRIC_DECISION_INTELLIGENCE;

export const isDecisionIntelligenceCategoryOnly = (categoryId?: number) => categoryId === JobCategory.DECISION_INTELLIGENCE;

export const isEventCentricDecisionIntelligenceCategory = (categoryId?: number) =>
  categoryId === JobCategory.EVENT_CENTRIC_DECISION_INTELLIGENCE;
