export enum EModelUploadErrorReason {
  InvalidType = 'file-invalid-type',
  FileTooLarge = 'file-too-large'
}

export const getErrorMessageByReason = (reason: EModelUploadErrorReason) => {
  switch (reason) {
    case EModelUploadErrorReason.InvalidType:
      return 'Error. Invalid format. Use ony .zip files.';

    case EModelUploadErrorReason.FileTooLarge:
      return 'Error. File exceeds limit.';

    default:
      return 'Error. An error occured.';
  }
};
