import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('GetExpandedEventByIdRequest')
export class GetExpandedEventByIdRequest extends RequestCommon {
  @JsonProperty('job_id', Number)
  jobId: number;

  @JsonProperty('event_id', String)
  eventId: string;
}

type TConstructorParams = {
  jobId: GetExpandedEventByIdRequest['jobId'];
  eventId: GetExpandedEventByIdRequest['eventId'];
};

export function constructGetExpandedEventByIdRequest({ jobId, eventId }: TConstructorParams): GetExpandedEventByIdRequest {
  const instance = new GetExpandedEventByIdRequest();
  instance.jobId = jobId;
  instance.eventId = eventId;
  return instance;
}
