import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '../utils';

@JsonObject('DeleteStorageTagRequest')
export class DeleteStorageTagRequest extends RequestCommon {
  @JsonProperty('storage_tag', String)
  storageTag: string;
}

type TConstructorParams = {
  storageTag: DeleteStorageTagRequest['storageTag'];
};

export function constructDeleteStorageTagRequest({ storageTag }: TConstructorParams): DeleteStorageTagRequest {
  const instance = new DeleteStorageTagRequest();
  instance.storageTag = storageTag;

  return instance;
}
