import { useJobIdFromUrl } from '@arcanna/hooks';
import { useMemo } from 'react';
import { useJobInfo } from 'src/data-access';

function useGetIntegrationFromStorageTag({ storageTag }: { storageTag: string | undefined }) {
  const { jobId } = useJobIdFromUrl();

  const { data: jobInfoData } = useJobInfo(jobId);

  return useMemo(() => {
    if (!jobInfoData?.info?.status?.supports_multi_input || !storageTag) {
      return {
        currentOrder: [],
        integration: undefined
      };
    }

    const integration = jobInfoData?.info?.pipelineIntegrations.find((int) => int.storageTag === storageTag);

    return {
      currentOrder: jobInfoData?.info?.pipelineIntegrations?.map((int) => int.autoId),
      integration
    };
  }, [jobInfoData?.info?.pipelineIntegrations, jobInfoData?.info?.status?.supports_multi_input, storageTag]);
}

export default useGetIntegrationFromStorageTag;
