import { AddUpdateIntegrationRequest } from '@arcanna/models/Flows/AddUpdateIntegrationRequest';
import { FlowsValidationResponse } from '@arcanna/models/Flows/FlowsValidationResponse';
import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { flowsKeys } from 'src/_srcMUI/requests/Flows/keys';
import { showErrorNotification, showSuccessNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';
import { storageTagKeys } from '../StorageTags/keys';

type TUseCreateUpdateIntegration = {
  jobId: number;
};

function useCreateUpdateIntegration({ jobId }: TUseCreateUpdateIntegration) {
  // OTHER HOOKS
  const queryClient = useQueryClient();

  // SETUP
  const URL = useMemo(() => config.api.flows.addEdit({ jobId }), [jobId]);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<FlowsValidationResponse>>(URL, body)
        .then((response) => Serializer.getInstance().deserializeCommonResponse(response, FlowsValidationResponse)),
    [URL]
  );

  // QUERY
  return useMutation(
    [...flowsKeys.add(), ...flowsKeys.updates()],
    (payload: AddUpdateIntegrationRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);

      return axiosFunction(payloadSerialized);
    },
    {
      onSuccess: async (_, params) => {
        showSuccessNotification('Success', `Integration ${params.operation === 'edit' ? 'modified' : 'created'} successfully.`);
        await Promise.all([
          queryClient.invalidateQueries([config.api.jobInfo.replace(':id', `${jobId}`)]),
          queryClient.invalidateQueries(storageTagKeys.list({ jobId }))
        ]);
      },
      onError: (error: AxiosResponse<ResourceWrapper<ResponseCommon>>, params) => {
        if (error.data?.resource?.request?.reason) {
          showErrorNotification('Error', error.data.resource.request.reason);
        } else {
          showErrorNotification('Error', `Failed to ${params.operation === 'edit' ? 'edit' : 'create'} integration.`);
        }
      }
    }
  );
}

export default useCreateUpdateIntegration;
