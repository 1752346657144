/* eslint-disable max-len */
function InputModal() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <rect width="36" height="36" rx="18" fill="#D946EF" fillOpacity="0.1" />
      <g clipPath="url(#clip0_23_50732)">
        <path
          d="M25.6665 26.3334C25.8999 26.3334 26.0165 26.3334 26.1057 26.288C26.1841 26.2481 26.2478 26.1843 26.2878 26.1059C26.3332 26.0168 26.3332 25.9001 26.3332 25.6667V17.0001C26.3332 16.7667 26.3332 16.6501 26.2878 16.5609C26.2478 16.4825 26.1841 16.4188 26.1057 16.3788C26.0165 16.3334 25.8999 16.3334 25.6665 16.3334L23.6665 16.3334C23.4331 16.3334 23.3165 16.3334 23.2273 16.3788C23.1489 16.4188 23.0852 16.4825 23.0453 16.5609C22.9998 16.65 22.9998 16.7667 22.9998 17.0001V19.0001C22.9998 19.2334 22.9998 19.3501 22.9544 19.4392C22.9145 19.5176 22.8507 19.5814 22.7723 19.6213C22.6832 19.6667 22.5665 19.6667 22.3332 19.6667H20.3332C20.0998 19.6667 19.9831 19.6667 19.894 19.7122C19.8156 19.7521 19.7519 19.8159 19.7119 19.8943C19.6665 19.9834 19.6665 20.1001 19.6665 20.3334V22.3334C19.6665 22.5668 19.6665 22.6834 19.6211 22.7726C19.5811 22.851 19.5174 22.9147 19.439 22.9547C19.3499 23.0001 19.2332 23.0001 18.9998 23.0001H16.9998C16.7665 23.0001 16.6498 23.0001 16.5607 23.0455C16.4823 23.0854 16.4185 23.1492 16.3786 23.2276C16.3332 23.3167 16.3332 23.4334 16.3332 23.6667V25.6667C16.3332 25.9001 16.3332 26.0168 16.3786 26.1059C16.4185 26.1843 16.4823 26.2481 16.5607 26.288C16.6498 26.3334 16.7665 26.3334 16.9998 26.3334L25.6665 26.3334Z"
          stroke="#D946EF"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3332 13.6667C16.3332 13.4334 16.3332 13.3167 16.3786 13.2276C16.4185 13.1492 16.4823 13.0854 16.5607 13.0455C16.6498 13.0001 16.7665 13.0001 16.9998 13.0001H18.9998C19.2332 13.0001 19.3499 13.0001 19.439 13.0455C19.5174 13.0854 19.5811 13.1492 19.6211 13.2276C19.6665 13.3167 19.6665 13.4334 19.6665 13.6667V15.6667C19.6665 15.9001 19.6665 16.0168 19.6211 16.1059C19.5811 16.1843 19.5174 16.2481 19.439 16.288C19.3499 16.3334 19.2332 16.3334 18.9998 16.3334H16.9998C16.7665 16.3334 16.6498 16.3334 16.5607 16.288C16.4823 16.2481 16.4185 16.1843 16.3786 16.1059C16.3332 16.0168 16.3332 15.9001 16.3332 15.6667V13.6667Z"
          stroke="#D946EF"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4998 18.6667C10.4998 18.4334 10.4998 18.3167 10.5453 18.2276C10.5852 18.1492 10.6489 18.0854 10.7273 18.0455C10.8165 18.0001 10.9331 18.0001 11.1665 18.0001H13.1665C13.3999 18.0001 13.5165 18.0001 13.6057 18.0455C13.6841 18.0854 13.7478 18.1492 13.7878 18.2276C13.8332 18.3167 13.8332 18.4334 13.8332 18.6667V20.6667C13.8332 20.9001 13.8332 21.0168 13.7878 21.1059C13.7478 21.1843 13.6841 21.2481 13.6057 21.288C13.5165 21.3334 13.3999 21.3334 13.1665 21.3334H11.1665C10.9331 21.3334 10.8165 21.3334 10.7273 21.288C10.6489 21.2481 10.5852 21.1843 10.5453 21.1059C10.4998 21.0168 10.4998 20.9001 10.4998 20.6667V18.6667Z"
          stroke="#D946EF"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.6665 10.3334C9.6665 10.1001 9.6665 9.98338 9.71192 9.89425C9.75187 9.81585 9.81561 9.75211 9.89401 9.71216C9.98314 9.66675 10.0998 9.66675 10.3332 9.66675H12.3332C12.5665 9.66675 12.6832 9.66675 12.7723 9.71216C12.8507 9.75211 12.9145 9.81585 12.9544 9.89425C12.9998 9.98338 12.9998 10.1001 12.9998 10.3334V12.3334C12.9998 12.5668 12.9998 12.6834 12.9544 12.7726C12.9145 12.851 12.8507 12.9147 12.7723 12.9547C12.6832 13.0001 12.5665 13.0001 12.3332 13.0001H10.3332C10.0998 13.0001 9.98314 13.0001 9.89401 12.9547C9.81561 12.9147 9.75187 12.851 9.71192 12.7726C9.6665 12.6834 9.6665 12.5668 9.6665 12.3334V10.3334Z"
          stroke="#D946EF"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_50732">
          <rect width="20" height="20" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InputModal;
