/* eslint-disable max-len */

function DottedCircleHalfChecked() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" strokeDasharray="4 4" />
      <path
        d="M12 24C8.8174 24 5.76515 22.7357 3.51472 20.4853C1.26428 18.2348 -8.85339e-08 15.1826 0 12C8.85339e-08 8.8174 1.26428 5.76515 3.51472 3.51472C5.76516 1.26428 8.8174 -3.79521e-08 12 0V12L12 24Z"
        fill="#10B981"
      />
    </svg>
  );
}

export default DottedCircleHalfChecked;
