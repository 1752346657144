import * as React from 'react';

import { QueryClient, useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { config } from '../../config';
import { IntegrationSummaryResponse } from '../../components/shared/models/integration/IntegrationSummaryResponse';
import { IntegrationSummaryRequest } from '../../components/shared/models/integration/IntegrationSummaryRequest';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { IntegrationTypes } from '../../components/shared/models/integration/IntegrationTypes';
import { IntegrationSummaryRecord } from '../../components/shared/models/integration/IntegrationSummaryRecord';
import { JsonConvert } from 'json2typescript';

type FetchIntegrationsByType = {
  url: string;
  shouldIncludeInternal: boolean;
  jsonConvert: JsonConvert;
};
export function fetchIntegrationsByType({ url, shouldIncludeInternal, jsonConvert }: FetchIntegrationsByType) {
  return axios
    .post<{
      resource: IntegrationSummaryResponse;
    }>(url, new IntegrationSummaryRequest(config.defaultEventsCount, shouldIncludeInternal))
    .then((responseData: AxiosResponse<{ resource: IntegrationSummaryResponse }>) =>
      jsonConvert.deserializeObject(responseData.data.resource, IntegrationSummaryResponse)
    );
}

type FetchIntegrationById = {
  value: number;
  integrationType: IntegrationTypes;
  queryClient: QueryClient;
  jsonConvert: JsonConvert;
  shouldIncludeInternal: boolean;
};
export async function fetchIntegrationById({
  value,
  integrationType,
  queryClient,
  jsonConvert,
  shouldIncludeInternal
}: FetchIntegrationById) {
  const url = config.api.integrationSummary.replace(':parameterType', `${integrationType}`);
  const integrationSummary = await queryClient.fetchQuery(
    [
      url,
      {
        event_count: config.defaultEventsCount,
        include_internal: true
      }
    ],
    () => fetchIntegrationsByType({ url, shouldIncludeInternal, jsonConvert })
  );
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const integration = integrationSummary.entries.find((record) => record.id === value);

  return integration;
}

type UseIntegrationSummaryType = {
  integrationId: number;
  integrationType?: IntegrationTypes | string;
  shouldIncludeInternal: boolean;
};

export function useIntegrationSummary({ integrationId, integrationType, shouldIncludeInternal }: UseIntegrationSummaryType) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const url = config.api.integrationSummary.replace(':parameterType', `${integrationType}`);

  return useQuery<IntegrationSummaryResponse, Error, IntegrationSummaryRecord | undefined>(
    [
      url,
      {
        event_count: config.defaultEventsCount,
        include_internal: shouldIncludeInternal
      }
    ],
    () => fetchIntegrationsByType({ url, shouldIncludeInternal, jsonConvert }),
    {
      enabled: Boolean(integrationType),
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      select: (data: IntegrationSummaryResponse) => data.entries.find((record) => record.id === integrationId)
    }
  );
}
