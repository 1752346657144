import { TFunction } from 'i18next';
import { EEventSampleDrawerTabs } from '../../EventSampleDrawer.constants';
import EventSampleDrawerTable from './components/EventSampleDrawerTable';

const getEventSampleDrawerBodyTabs = (t: TFunction) => [
  {
    label: t('codeBlock:common.tabs.table'),
    value: EEventSampleDrawerTabs.Table,
    component: EventSampleDrawerTable
  }
];

export { getEventSampleDrawerBodyTabs };
