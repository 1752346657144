import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '../utils';

@JsonObject('AIModelExportRequest')
export class AIModelExportRequest extends RequestCommon {
  @JsonProperty('model_id', String)
  modelId: string;
}

type TConstructorParams = {
  modelId: AIModelExportRequest['modelId'];
};

export function constructAIModelExportRequest({ modelId }: TConstructorParams): AIModelExportRequest {
  const instance = new AIModelExportRequest();
  instance.modelId = modelId;

  return instance;
}
