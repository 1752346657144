/* eslint-disable max-len */
function Okay() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="68" height="86" viewBox="0 0 68 86" fill="none">
      <path
        d="M9.84743 75.0637C-3.28248 61.7434 -3.28248 40.1471 9.84743 26.827C22.9773 13.5069 44.2653 13.5069 57.3953 26.827C70.5254 40.1471 70.5254 61.7434 57.3953 75.0637C44.2653 88.3841 22.9773 88.3841 9.84743 75.0637Z"
        fill="#10B981"
        fillOpacity="0.3"
      />
      <path
        d="M57.9329 1.67134C57.822 1.60766 57.7084 1.54728 57.5918 1.49076C54.8832 0.181229 51.6154 1.31074 50.3081 4.01113L27.7331 50.5095L26.4506 49.1197C26.0687 48.7051 25.6362 48.3643 25.1708 48.0977C24.4973 47.7118 21.6359 46.0546 21.173 45.7875C19.1668 44.6296 16.5488 44.8476 14.7472 46.5043C12.543 48.5408 12.4058 51.9946 14.4412 54.2029L24.5 65.0996C24.8827 65.5143 25.3208 65.8605 25.7988 66.1326C26.4555 66.5062 29.307 68.1592 29.7735 68.43C30.509 68.8568 31.3402 69.1071 32.212 69.1542C32.5715 69.1735 32.9323 69.1589 33.3029 69.1063C35.0891 68.8408 36.6255 67.7143 37.4085 66.0957L64.1152 11.0867C65.3694 8.49801 64.3909 5.39703 61.9406 3.98549C61.8286 3.92071 58.6461 2.08185 57.9329 1.67134Z"
        fill="#059669"
      />
      <path
        opacity="0.15"
        d="M57.9329 1.67134C57.822 1.60766 57.7084 1.54728 57.5918 1.49076C54.8832 0.181229 51.6154 1.31074 50.3081 4.01113L27.7331 50.5095L26.4506 49.1197C26.0687 48.7051 25.6362 48.3643 25.1708 48.0977C24.4973 47.7118 21.6359 46.0546 21.173 45.7875C19.1668 44.6296 16.5488 44.8476 14.7472 46.5043C12.543 48.5408 12.4058 51.9946 14.4412 54.2029L24.5 65.0996C24.8827 65.5143 25.3208 65.8605 25.7988 66.1326C26.4555 66.5062 29.307 68.1592 29.7735 68.43C30.509 68.8568 31.3402 69.1071 32.212 69.1542C32.5715 69.1735 32.9323 69.1589 33.3029 69.1063C35.0891 68.8408 36.6255 67.7143 37.4085 66.0957L64.1152 11.0867C65.3694 8.49801 64.3909 5.39703 61.9406 3.98549C61.8286 3.92071 58.6461 2.08185 57.9329 1.67134Z"
        fill="black"
      />
      <path
        opacity="0.15"
        d="M29.7723 68.4299C29.3075 68.1602 26.4552 66.5069 25.798 66.132C25.3205 65.8599 24.8813 65.5133 24.4989 65.0998L14.44 54.2039C13.9832 53.7058 13.6391 53.1411 13.4004 52.5479L17.4004 54.8559C17.6367 55.4514 17.9857 56.0163 18.4453 56.5145L28.5041 67.4104C28.8788 67.8162 29.305 68.1578 29.7723 68.4299Z"
        fill="black"
      />
      <path
        opacity="0.15"
        d="M55.5743 4.64553C55.061 5.10262 54.6295 5.66724 54.3111 6.32201L31.0921 54.1495L27.7339 50.509L50.3089 4.01144C50.6245 3.35419 51.061 2.79206 51.5746 2.33496L55.5743 4.64553Z"
        fill="#1B2236"
      />
      <path
        d="M61.5955 3.80229C58.8868 2.49276 55.619 3.62227 54.3117 6.32239L31.0919 54.1493L26.4506 49.1193C24.4226 46.9165 20.9685 46.7765 18.7511 48.8155C16.547 50.852 16.4097 54.3059 18.4451 56.5142L28.504 67.4109C29.4702 68.4574 30.7872 69.0766 32.212 69.1538C32.5715 69.1731 32.9324 69.1585 33.3029 69.1058C35.0891 68.8403 36.6255 67.7139 37.4085 66.0953L64.1156 11.0863C65.4262 8.37932 64.2967 5.1121 61.5955 3.80229Z"
        fill="#10B981"
      />
    </svg>
  );
}
export default Okay;
