import { EIcon, Icon } from '@arcanna/generic';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { constructEnableDisableIntegrationRequest } from '@arcanna/models/Flows/EnableDisableIntegrationRequest';
import { constructSoftDeleteStorageTagRequest, StorageTagRecord } from '@arcanna/models/StorageTags';
import useGetIntegrationFromStorageTag from '@arcanna/pages/AddJob/components/AddJobForm/components/AddJobIntegrationDrawerContent/components/IntegrationForm/components/StorageTagSection/hooks/useGetIntegrationFromStorageTag';
import { useEnableDisableIntegration } from '@arcanna/requests/Flows';
import { useToggleSoftDeleteStorageTag } from '@arcanna/requests/StorageTags';
import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import { useCallback } from 'react';
import AlertDialog from 'src/_srcMUI/shared/components/AlertDialog';

type TStorageTagSoftDeleteDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  storageTag: StorageTagRecord;
};

function StorageTagSoftDeleteDialog({ isOpen, handleClose, storageTag }: TStorageTagSoftDeleteDialogProps) {
  const { jobId } = useJobIdFromUrl();

  const { mutateAsync: toggleSoftDeleteStorageTag, isLoading } = useToggleSoftDeleteStorageTag({ jobId });
  const { integration, currentOrder } = useGetIntegrationFromStorageTag({ storageTag: storageTag.storageTag });
  const { mutateAsync: enableDisableIntegration, isLoading: isEnableDisableIntegrationLoading } = useEnableDisableIntegration({
    jobId,
    currentOrder
  });

  const handleSubmit = useCallback(() => {
    toggleSoftDeleteStorageTag(constructSoftDeleteStorageTagRequest({ storageTag: storageTag.storageTag }), {
      onSuccess: async () => {
        handleClose();
        if (integration) {
          await enableDisableIntegration(
            constructEnableDisableIntegrationRequest({
              autoId: integration.autoId,
              enabled: false
            })
          );
        }
      }
    });
  }, [enableDisableIntegration, handleClose, integration, storageTag.storageTag, toggleSoftDeleteStorageTag]);

  return (
    <AlertDialog
      open={isOpen}
      handleSubmit={handleSubmit}
      onClose={handleClose}
      title="Soft Delete"
      iconName={EIcon.Info}
      isLoading={isLoading || isEnableDisableIntegrationLoading}
      modalBody={
        <Stack direction="column">
          <Typography variant="caption" color="inherit" fontSize="14px">
            <Typography component="span" variant="caption" fontSize="14px">
              {storageTag.storageTag}&nbsp;
            </Typography>
            will be soft deleted for&nbsp;
            <Typography component="span" variant="caption" fontSize="14px">
              30 days&nbsp;
            </Typography>
            before permanent removal. Input integrations using it, will be suspended and deleted after this period.
          </Typography>
          <Typography variant="caption" marginTop={1} color="inherit" fontSize="14px">
            You can undo this action anytime before then.
          </Typography>
          <Typography variant="caption" marginTop={1} color="inherit" fontSize="14px">
            Would you like to continue?
          </Typography>
        </Stack>
      }
      mode="confirm"
      confirmBtnText="Yes, Continue"
      submitButtonProps={{
        endIcon: <Icon name={EIcon.CalendarCheck} />
      }}
    />
  );
}

export default StorageTagSoftDeleteDialog;
