import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';
import { FilterRecordV2 } from 'src/_srcMUI/models/utils/FilterRecord';

@JsonObject('GetRandomEventRequest')
export class GetRandomEventRequest extends RequestCommon {
  @JsonProperty('filters', [FilterRecordV2])
  filters: FilterRecordV2[];
}

type TConstructorParams = {
  filters: GetRandomEventRequest['filters'];
};

export function constructGetRandomEventRequest({ filters }: TConstructorParams): GetRandomEventRequest {
  const instance = new GetRandomEventRequest();
  instance.filters = filters;

  return instance;
}
