import { FormControlLabel, FormHelperText, RadioGroup, RadioGroupProps, Stack, Typography, Tooltip } from '@mui/material';
import { Label, RadioButton } from '@arcanna/generic';
import { Controller, useFormContext } from 'react-hook-form';
import { getDataTestIdFormat } from '../../../../utils/getDataTestIdFormat';

export type TRadioOption = {
  label: string;
  value: string | number;
  tooltipText?: string;
  disabled?: boolean;
};

type TRHFRadioGroupProps = RadioGroupProps & {
  options: TRadioOption[];
  name: string;
  label?: string;
  tooltipText?: string;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string | number) => void;
};

function RHFRadioGroup({ options, name, label, tooltipText, required, row, ...otherProps }: TRHFRadioGroupProps) {
  const {
    control,
    setValue,
    watch,
    formState: { isSubmitting }
  } = useFormContext();

  const fieldValue = watch(name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack direction="column" gap={0.5}>
          {label && <Label text={label} tooltipText={tooltipText} required={required} />}
          <RadioGroup
            {...field}
            onChange={(event) => {
              const hasNumberedValues = typeof options[0].value === 'number';
              const newValue = hasNumberedValues ? Number(event.target.value) : event.target.value;

              if (otherProps.onChange) {
                otherProps.onChange(event, newValue);
              } else {
                setValue(name, newValue, { shouldValidate: true });
              }
            }}
            row={row}
            {...otherProps}
            sx={{ gap: 1, ...otherProps.sx }}
          >
            {options.map((option) => (
              <Tooltip arrow placement="right" key={option.value} title={option.tooltipText}>
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  data-test-id={getDataTestIdFormat(`parameter-field-${name}-${option.value}`)}
                  disabled={option.disabled || isSubmitting}
                  control={<RadioButton value={option.value} isChecked={fieldValue === option.value} sx={{ marginRight: 0.5 }} />}
                  label={<Typography variant="subtitle1">{option.label}</Typography>}
                  componentsProps={{}}
                  sx={{ marginLeft: 0, marginBottom: 0 }}
                />
              </Tooltip>
            ))}
          </RadioGroup>
          {!!error && <FormHelperText error>{error.message}</FormHelperText>}
        </Stack>
      )}
    />
  );
}

export default RHFRadioGroup;
