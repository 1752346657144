import { Stack } from '@mui/material';
import { Table } from '@arcanna/generic';
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { UserPerformance } from '../DecisionsDetails';
import useGetColumns from './useGetColumns';
import OverviewNoFeedbackState from '../../OverviewNoFeedbackState';

type UsersPerformanceProps = {
  entries: UserPerformance[];
  isLoading: boolean;
};

export default function UsersPerformance({ entries, isLoading }: UsersPerformanceProps) {
  const { columns } = useGetColumns();

  const tableInstance = useReactTable<UserPerformance>({
    getCoreRowModel: getCoreRowModel(),
    data: entries ?? [],
    columns,
    initialState: {
      pagination: { pageSize: 5 }
    },
    enableMultiRowSelection: false,
    enableRowSelection: false,
    enableColumnResizing: false,
    enableRowPinning: false,
    enableSorting: true,
    enableColumnPinning: false,
    renderFallbackValue: undefined,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  return (
    <Stack spacing={'8px'}>
      <Table.component<UserPerformance>
        tableInstance={tableInstance}
        isTableResizable={false}
        isLoading={isLoading}
        emptyState={<OverviewNoFeedbackState ml={3} width="600px" />}
      />
      <Table.TablePagination<UserPerformance> tableInstance={tableInstance} canChangePageSize={false} />
    </Stack>
  );
}
