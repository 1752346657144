import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { EventExplorerEventInfoRequest } from '../../components/shared/models/event-explorer/EventExplorerEventInfoRequest';
import { EventExplorerEventInfoResponse } from '../../components/shared/models/event-explorer/EventExplorerEventInfoResponse';
import { EventExplorerBucketInfoResponse } from '../../components/shared/models/event-explorer/EventExplorerBucketInfoResponse';
import { EventExplorerBucketInfoRequest } from '../../components/shared/models/event-explorer/EventExplorerBucketInfoRequest';

type UseEventInfo = {
  jobId: number;
  eventId: string;
  includeInternal: boolean;
  enabled?: boolean;
};

export const useEventInfo = ({ jobId, eventId, includeInternal = false, enabled = true }: UseEventInfo) => {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  return useQuery<EventExplorerEventInfoResponse, Error>(
    [config.api.eventInfo.replace(':eventId', `${eventId}`), includeInternal],
    () =>
      axios
        .post<{
          resource: EventExplorerEventInfoResponse;
        }>(config.api.eventInfo.replace(':eventId', `${eventId}`), new EventExplorerEventInfoRequest(jobId, includeInternal))
        .then((responseData: AxiosResponse<{ resource: EventExplorerEventInfoResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, EventExplorerEventInfoResponse)
        ),
    {
      enabled: Boolean(jobId && eventId) && enabled
    }
  );
};

type UseBucketInfo = {
  jobId: number;
  bucketId: string;
};

export const useEventBucketInfo = ({ jobId, bucketId }: UseBucketInfo) => {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<EventExplorerBucketInfoResponse, Error>(
    [config.api.eventExplorerBucketInfo.replace(':bucketId', `${bucketId}`)],
    () =>
      axios
        .post<{
          resource: EventExplorerEventInfoResponse;
        }>(config.api.eventExplorerBucketInfo.replace(':bucketId', `${bucketId}`), new EventExplorerBucketInfoRequest(jobId))
        .then((responseData: AxiosResponse<{ resource: EventExplorerBucketInfoResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, EventExplorerBucketInfoResponse)
        ),
    {
      enabled: Boolean(jobId && bucketId)
    }
  );
};
