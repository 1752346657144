import { Stack, Typography, useTheme } from '@mui/material';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { EIcon, Icon } from '../../../../generic/Icon';
import { useCallback, useState } from 'react';
import { useImportModelSummary } from '@arcanna/requests/AIModels';
import { useJobIdFromUrl } from '@arcanna/hooks';
import useImportModel from '@arcanna/requests/AIModels/useImportModel';
import { InlineNotification } from '@arcanna/components';
import { Spinner } from '../../../../generic/Spinner';
import { AIModelImportSummaryResponse } from '@arcanna/models/AIModels';
import { AxiosResponse } from 'axios';
import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import FileUploadConfirmModal from './components/ModelUploadConfirmModal';
import { EModelUploadErrorReason, getErrorMessageByReason } from './ModelUpload.utils';

type TModelUploadProps = DropzoneOptions;

function ModelUpload({ ...dropzoneProps }: TModelUploadProps) {
  const theme = useTheme();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [errorNotificationTypes, setErrorNotificationTypes] = useState<EModelUploadErrorReason[] | null>(null);
  const [confirmModelData, setConfirmModelData] = useState<AIModelImportSummaryResponse | null>(null);
  const [importSummaryError, setImportSummaryError] = useState<string | null>(null);
  const [importError, setImportError] = useState<string | null>(null);
  const [cachedFilename, setCachedFilename] = useState<string>('');

  const { jobId } = useJobIdFromUrl();

  const { mutateAsync: importModelSummary, isLoading: isSummaryCheckLoading } = useImportModelSummary({ jobId });

  const { mutateAsync: importModel, isLoading: isImporting } = useImportModel({ jobId });

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    const errorCodes = fileRejections.map((fileRejection) => fileRejection.errors[0].code);

    setErrorNotificationTypes((errorCodes as EModelUploadErrorReason[]) ?? null);
  }, []);

  const resetErrors = useCallback(() => {
    setErrorNotificationTypes(null);
    setImportSummaryError(null);
    setImportError(null);
  }, []);

  const removeFile = useCallback(
    (file: File) => {
      const newFiles = [...uploadedFiles];
      newFiles.splice(newFiles.indexOf(file), 1);
      setUploadedFiles(newFiles);
      resetErrors();
    },
    [uploadedFiles, resetErrors]
  );

  const removeFiles = useCallback(() => {
    setUploadedFiles([]);
    resetErrors();
  }, [resetErrors]);

  const onDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = [...uploadedFiles, ...acceptedFiles];

      if (newFiles.length) {
        importModelSummary(
          { files: newFiles, modelId: newFiles[0].name },
          {
            onSuccess: (response) => {
              setConfirmModelData(response.data.resource);
            },
            // @ts-ignore
            onError: (response: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
              removeFiles();
              setImportSummaryError(
                response?.data?.resource?.request?.reason ?? 'An error occured. Please try again with another file'
              );
            }
          }
        );
      }

      setUploadedFiles(newFiles);
    },
    [importModelSummary, removeFiles, uploadedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDragEnter: resetErrors,
    onFileDialogOpen: resetErrors,
    onDropRejected,
    onDropAccepted,
    ...dropzoneProps
  });

  const handleFileImport = (fileName: string) => {
    setConfirmModelData(null);
    setCachedFilename(fileName);
    importModel(
      { files: uploadedFiles, fileName },
      {
        onSuccess: () => {
          removeFiles();
          setCachedFilename('');
        },
        // @ts-ignore
        onError: (response: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
          setImportError(response?.data?.resource?.request?.reason ?? 'An error occured. Please try again.');
        }
      }
    );
  };

  return (
    <Stack gap={0.5}>
      {uploadedFiles.length ? (
        <Stack>
          {uploadedFiles.map((file, index) => (
            <Stack
              sx={{
                display: 'flex',
                width: '420px',
                flexDirection: 'row',
                background: theme.palette.secondary[800],
                gap: 0.5,
                alignItems: 'center',
                padding: 1,
                borderRadius: 0.5
              }}
              key={index}
            >
              {(isSummaryCheckLoading || isImporting) && (
                <Stack position="relative" width="32px">
                  <Spinner isOverlay />
                </Stack>
              )}

              <Stack sx={{ flexGrow: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body2" fontSize="14px" color={theme.palette.secondary[100]}>
                  {`${isSummaryCheckLoading ? 'Uploading ' : ''}${isImporting ? 'Importing ' : ''}${file.name}`}
                </Typography>
                <Icon
                  name={EIcon.Close}
                  htmlColor={theme.palette.secondary[400]}
                  fontSize="small"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => removeFile(file)}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      ) : (
        <Stack
          sx={{
            borderRadius: '4px',
            border: `1px dashed ${theme.palette.secondary[600]}`,
            backgroundColor: theme.palette.secondary[1000],
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            width: '420px',
            height: '34px',
            gap: 1,
            padding: 1
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Icon name={EIcon.Upload} htmlColor={theme.palette.secondary[400]} />
          <Typography variant="caption" color={theme.palette.secondary[400]}>
            Browse your computer or drag and drop your file here.
          </Typography>
        </Stack>
      )}

      {errorNotificationTypes?.length && (
        <Stack gap={0.5} direction="column" paddingTop={0.5}>
          {errorNotificationTypes?.map((error, index) => (
            <InlineNotification
              key={index}
              type="error"
              title={getErrorMessageByReason(error)}
              sx={{ maxHeight: '36px', maxWidth: '420px' }}
            />
          ))}
        </Stack>
      )}

      {(importSummaryError || importError) && (
        <InlineNotification
          textPosition="vertical"
          marginTop={0.5}
          type="error"
          title="Error"
          subtitle={importSummaryError ?? importError ?? ''}
          sx={{ maxWidth: '420px' }}
          actionButton={
            importError
              ? {
                  text: 'Try Again',
                  onClick: () => handleFileImport(cachedFilename),
                  variant: 'text',
                  sx: { padding: 0, color: theme.palette.secondary[300], minHeight: 'unset', height: '20px' }
                }
              : undefined
          }
        />
      )}

      <FileUploadConfirmModal
        confirmModelData={confirmModelData}
        onClose={() => {
          setConfirmModelData(null);
          removeFiles();
        }}
        handleFileImport={handleFileImport}
      />
    </Stack>
  );
}

export default ModelUpload;
