import { EIcon, Icon } from '@arcanna/generic';
import { constructSoftDeleteStorageTagRequest, StorageTagRecord } from '@arcanna/models/StorageTags';
import { Menu } from '@mui/material';
import { IconButton, Stack, Typography } from '@mui/material';
import { MenuItem } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';
import StorageTagSoftDeleteDialog from './components/StorageTagSoftDeleteDialog';
import StorageTagDeleteDialog from './components/StorageTagDeleteDialog';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { useToggleSoftDeleteStorageTag } from '@arcanna/requests/StorageTags';
import DisplayNameEditModal from '../../../DisplayNameEditModal';

type TStorageTagActionsProps = {
  storageTag: StorageTagRecord;
};

function StorageTagActions({ storageTag }: TStorageTagActionsProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isSoftDeleteDialogOpen, setIsSoftDeleteDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isOpenEditDisplayNameModal, setIsOpenEditDisplayNameModal] = useState(false);

  const { jobId } = useJobIdFromUrl();

  const { mutateAsync: toggleSoftDeleteStorageTag } = useToggleSoftDeleteStorageTag({ jobId });

  const isMenuOpen = Boolean(menuAnchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setMenuAnchorEl(null);

  const renderMenuButton = useCallback(
    (iconName: EIcon, label: string, onMenuItemClick: (event: MouseEvent<HTMLLIElement>) => void, testId?: string) => {
      return (
        <MenuItem onClick={onMenuItemClick} className="MuiMenuItem-root--smaller" data-test-id={testId}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4} sx={{ padding: 0 }}>
            <Typography variant="subtitle2">{label}</Typography>
            <Icon name={iconName} fontSize="small" />
          </Stack>
        </MenuItem>
      );
    },
    []
  );

  const handleSoftDelete = useCallback(() => {
    setIsSoftDeleteDialogOpen(true);
    handleCloseMenu();
  }, []);

  const handleCloseSoftDeleteDialog = useCallback(() => {
    setIsSoftDeleteDialogOpen(false);
  }, []);

  const handleDelete = useCallback(() => {
    setIsDeleteDialogOpen(true);
    handleCloseMenu();
  }, []);

  const handleChangeDisplayName = useCallback(() => {
    setIsOpenEditDisplayNameModal(true);
  }, []);

  const handleCloseDeleteDialog = useCallback(() => {
    setIsDeleteDialogOpen(false);
  }, []);

  const handleCancelSoftDelete = useCallback(() => {
    toggleSoftDeleteStorageTag(constructSoftDeleteStorageTagRequest({ storageTag: storageTag.storageTag }));
    handleCloseMenu();
  }, [storageTag.storageTag, toggleSoftDeleteStorageTag]);

  return (
    <>
      <IconButton
        type="button"
        size="small"
        variant="text"
        color="secondary"
        onClick={handleOpenMenu}
        data-test-id="dropdown-menu"
        sx={{
          fontSize: '16px'
        }}
      >
        <Icon name={EIcon.ThreeDots} fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {renderMenuButton(EIcon.Edit16, 'Change Display Name', handleChangeDisplayName)}
        {storageTag.deletionStatus.soft ? (
          <> {renderMenuButton(EIcon.XCircle, 'Cancel Soft Delete', handleCancelSoftDelete)}</>
        ) : (
          <>{renderMenuButton(EIcon.Calendar, 'Soft Delete', handleSoftDelete)}</>
        )}
        {renderMenuButton(EIcon.Delete, 'Delete', handleDelete)}
      </Menu>
      <StorageTagSoftDeleteDialog
        storageTag={storageTag}
        isOpen={isSoftDeleteDialogOpen}
        handleClose={handleCloseSoftDeleteDialog}
      />
      <StorageTagDeleteDialog storageTag={storageTag} isOpen={isDeleteDialogOpen} handleClose={handleCloseDeleteDialog} />
      <DisplayNameEditModal
        isOpenEditDisplayNameModal={isOpenEditDisplayNameModal}
        setIsOpenEditDisplayNameModal={setIsOpenEditDisplayNameModal}
        displayName={storageTag.displayName ?? storageTag.storageTag}
        storageTag={storageTag?.storageTag}
      />
    </>
  );
}

export default StorageTagActions;
