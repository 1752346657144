import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '@arcanna/models/utils';

@JsonObject('GetRawEventByIdResponse')
export class GetRawEventByIdResponse extends ResponseCommon {
  @JsonProperty('alert', Object)
  rawAlert: object;
}

type TConstructorParams = {
  rawAlert: GetRawEventByIdResponse['rawAlert'];
};

export function constructGetRawEventByIdResponse({ rawAlert }: TConstructorParams): GetRawEventByIdResponse {
  const instance = new GetRawEventByIdResponse();
  instance.rawAlert = rawAlert;
  return instance;
}
