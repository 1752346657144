import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { showErrorNotification, showSuccessNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';
import { storageTagKeys } from './keys';
import { SoftDeleteStorageTagRequest } from '@arcanna/models/StorageTags';
import { jobInfoKey } from 'src/data-access';

function useToggleSoftDeleteStorageTag({ jobId }: { jobId: number }) {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);
  const queryClient = useQueryClient();

  // SETUP
  const URL = useMemo(() => config.api.storageManagement.softDelete({ jobId }), [jobId]);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<ResponseCommon>>(URL, body)
        .then((response) => Serializer.getInstance().deserializeCommonResponse(response, ResponseCommon)),
    [URL]
  );

  return useMutation(
    storageTagKeys.delete(),
    (payload: SoftDeleteStorageTagRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);
      return axiosFunction(payloadSerialized);
    },
    {
      onSuccess: async () => {
        await Promise.all([jobInfoKey(jobId), queryClient.invalidateQueries(storageTagKeys.list({ jobId }))]);
        showSuccessNotification(
          t('requests:storageTags.softDelete.success.title'),
          t('requests:storageTags.softDelete.success.subtitle')
        );
      },
      onError: (error: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
        if (error?.data?.resource?.request?.reason) {
          showErrorNotification(t('requests:storageTags.softDelete.error.title'), error.data.resource.request.reason);
        } else {
          showErrorNotification(
            t('requests:storageTags.softDelete.error.title'),
            t('requests:storageTags.softDelete.error.subtitle')
          );
        }
      }
    }
  );
}

export default useToggleSoftDeleteStorageTag;
