import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('DeleteIntegrationRequest')
export class DeleteIntegrationRequest extends RequestCommon {
  @JsonProperty('auto_id', String)
  autoId: string;
}

type TConstructorParams = {
  autoId: DeleteIntegrationRequest['autoId'];
};

export function constructDeleteIntegrationRequest({ autoId }: TConstructorParams) {
  const instance = new DeleteIntegrationRequest();
  instance.autoId = autoId;

  return instance;
}
