import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('GetEventsBatchFilterRecord')
export class GetEventsBatchFilterRecord {
  @JsonProperty('field', String)
  field: string;

  @JsonProperty('operator', String)
  operator: string;

  // Page crashes if I use String || [String]
  @JsonProperty('value', Any)
  value: string | string[];

  @JsonProperty('status', String, true)
  status? = 'active';
}

@JsonObject('GetEventsBatchRequest')
export class GetEventsBatchRequest extends RequestCommon {
  @JsonProperty('job_ids', [Number])
  jobIds: number[];

  @JsonProperty('from', Number)
  from: number;

  @JsonProperty('size', Number)
  size: number;

  @JsonProperty('sort_by_column', String)
  sortByColumn: string;

  @JsonProperty('sort_order', String)
  sortOrder: string;

  @JsonProperty('start_date', String)
  startDate: string | undefined;

  @JsonProperty('end_date', String)
  endDate: string | undefined;

  @JsonProperty('filters', [GetEventsBatchFilterRecord])
  filters: GetEventsBatchFilterRecord[];
}

type TConstructorParams = {
  jobIds: GetEventsBatchRequest['jobIds'];
  pageCount: GetEventsBatchRequest['from'];
  pageSize: GetEventsBatchRequest['size'];
  sortByColumn: GetEventsBatchRequest['sortByColumn'];
  sortOrder: GetEventsBatchRequest['sortOrder'];
  startDate: GetEventsBatchRequest['startDate'];
  endDate: GetEventsBatchRequest['endDate'];
  filters: GetEventsBatchRequest['filters'];
};

export function constructGetEventsBatchRequest({
  jobIds,
  pageCount,
  pageSize,
  sortByColumn,
  sortOrder,
  startDate,
  endDate,
  filters
}: TConstructorParams): GetEventsBatchRequest {
  const instance = new GetEventsBatchRequest();
  instance.jobIds = jobIds;
  instance.from = (pageCount - 1) * pageSize;
  instance.size = pageSize;
  instance.sortByColumn = sortByColumn;
  instance.sortOrder = sortOrder;
  instance.startDate = startDate;
  instance.endDate = endDate;
  instance.filters = filters;

  return instance;
}
