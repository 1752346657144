const createAndDownloadBlob = (data: BlobPart, filename: string, options?: undefined | object): void => {
  const url = window.URL.createObjectURL(new Blob([data], options));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export { createAndDownloadBlob };
