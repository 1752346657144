import { EIcon, EmptyState } from '@arcanna/generic';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { TEmptyStateProps } from 'src/_srcMUI/shared/generic/EmptyState/EmptyState';
import { isEventCentricDecisionIntelligenceCategory } from 'src/components/pages/Main/Jobs/helper';
import { config } from 'src/config';
import { useJobInfo } from 'src/data-access';

type TOverviewNoFeedbackStateProps = Partial<TEmptyStateProps>;

function OverviewNoFeedbackState({ ...otherProps }: TOverviewNoFeedbackStateProps) {
  const theme = useTheme();
  const { jobId } = useJobIdFromUrl();
  const { data: jobInfoData } = useJobInfo(jobId);

  const isECDIJob = useMemo(
    () => isEventCentricDecisionIntelligenceCategory(jobInfoData?.info?.jobDetails?.category_id),
    [jobInfoData]
  );

  return (
    <EmptyState
      iconType={EIcon.SearchColored}
      {...otherProps}
      title={'Start by training your AI model.'}
      subtitle={
        <Typography component="span" variant="body2" color={theme.palette.secondary[300]}>
          Make sure you give feedback before training your AI model. You can give feedback from the
          {isECDIJob ? (
            <Link to={config.routes.eventExplorer.replace(':id', String(jobId))}>
              <Typography
                component="span"
                variant="body2"
                marginLeft={0.5}
                sx={{
                  cursor: 'pointer',
                  color: `${theme.palette.secondary[0]} !important`,
                  '&:hover': { color: `${theme.palette.primary.main} !important` }
                }}
              >
                Explorer section.
              </Typography>
            </Link>
          ) : (
            <Link to={config.routes.feedbackFlow.replace(':id', String(jobId))}>
              <Typography
                component="span"
                variant="body2"
                marginLeft={0.5}
                sx={{
                  cursor: 'pointer',
                  color: `${theme.palette.secondary[0]} !important`,
                  '&:hover': { color: `${theme.palette.primary.main} !important` }
                }}
              >
                Feedback section.
              </Typography>
            </Link>
          )}
        </Typography>
      }
    />
  );
}

export default OverviewNoFeedbackState;
