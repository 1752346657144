import { ResourceWrapper } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { eventKeys } from '../keys';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';
import { GetEventsBatchFilterRecord } from '@arcanna/models/Event/GetEventsBatchRequest';
import { constructGetEventGroupsRequest } from '@arcanna/models/Event/GetEventGroupsRequest';
import { GetEventGroupResponse } from '@arcanna/models/Event/GetEventGroupResponse';
import _ from 'lodash';
import { eventGroupingQueryCachingStore } from './useGetEventGroups.utils';

export type TUseGetEventGroupsProps = {
  jobIds: number[];
  groupedField: string;
  startDate: Date | null;
  endDate: Date | null;
  filters: GetEventsBatchFilterRecord[];
  pageSize: number;
  pageCount: number;
};

function useGetEventGroups({ jobIds, groupedField, startDate, endDate, filters, pageSize, pageCount }: TUseGetEventGroupsProps) {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);

  const queryCachingIdsPerJobs = eventGroupingQueryCachingStore((state) => state.queryCachingIdsPerJobs);
  const setQueryCachingIdPerJobs = eventGroupingQueryCachingStore((state) => state.setQueryCachingIdPerJobs);

  const existingQueryCachedValue = useMemo(() => {
    const jobsKey = jobIds.sort().join(',');

    return queryCachingIdsPerJobs[jobsKey];
  }, [queryCachingIdsPerJobs, jobIds]);

  // SETUP
  const URL = useMemo(() => config.api.events.groupMetrics, []);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<GetEventGroupResponse>>(URL, body)
        .then((responseData) => Serializer.getInstance().deserializeCommonResponse(responseData, GetEventGroupResponse)),
    [URL]
  );

  // PAYLOAD
  const payload = useMemo(() => {
    const areDifferentParams = !_.isEqual(
      { groupedField, startDate, endDate, filters },
      _.omit(existingQueryCachedValue, 'queryCachingId')
    );

    return constructGetEventGroupsRequest({
      jobIds,
      groupedField,
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      filters,
      queryCachingId: areDifferentParams ? undefined : existingQueryCachedValue?.queryCachingId,
      pageSize,
      pageCount
    });
  }, [jobIds, groupedField, startDate, endDate, filters, existingQueryCachedValue, pageSize, pageCount]);
  const payloadSerialized = useMemo(() => Serializer.getInstance().serializeObject(payload), [payload]);

  // QUERY
  return useQuery(
    eventKeys.getGroupMetrics({ jobIds, groupedField, startDate, endDate, filters, pageSize, pageCount }),
    () => axiosFunction(payloadSerialized),
    {
      onError: (error: AxiosResponse<ResourceWrapper<GetEventGroupResponse>>) => {
        showErrorNotification(
          t('requests:events.groups.error.title'),
          error.data?.resource?.request?.reason ?? t('requests:events.groups.error.subtitle')
        );
      },
      onSuccess: (data) => {
        if (existingQueryCachedValue?.queryCachingId !== data.queryCachingId) {
          setQueryCachingIdPerJobs(jobIds, { groupedField, startDate, endDate, filters }, data.queryCachingId);
        }
      }
    }
  );
}

export default useGetEventGroups;
