import { AIModelImportSummaryResponse } from '@arcanna/models/AIModels';
import { ResourceWrapper } from '@arcanna/models/utils';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';
import { LOCAL_STORAGE_TOKEN_NAME } from 'src/components/pages/Main/Login/AuthConstants';
import { config } from 'src/config';
import { AIModelKeys } from './keys';

function useImportModelSummary({ jobId }: { jobId: number }) {
  // SETUP
  const URL = useMemo(() => config.api.aiModel.importSummary(jobId), [jobId]);
  const axiosFunction = useCallback(
    (formData: FormData) =>
      axios.post<ResourceWrapper<AIModelImportSummaryResponse>>(URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }),
    [URL]
  );

  return useMutation(AIModelKeys.importModelSummary(), ({ files }: { files: File[]; modelId: string }) => {
    const formData = new FormData();

    const body = JSON.stringify({
      user_session_id: localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME) ?? '',
      model_id: files[0]?.name?.split('.')?.[0],
      filename: files[0]?.name
    });
    formData.append('body', body);
    formData.append('file', files[0]);

    return axiosFunction(formData);
  });
}

export default useImportModelSummary;
