import { useJobIdFromUrl } from '@arcanna/hooks';
import { useEventExplorerFiltersFields } from 'src/data-access';
import { Select } from '@arcanna/generic';
import TruncatedText from '../../TruncatedText';
import _ from 'lodash';

type TFieldsSelect = {
  onChangeField: (value: string) => void;
  selectedField: string;
};

export const ALL_FIELDS_VALUE = 'All Fields';

export default function FieldsSelect({ onChangeField, selectedField }: TFieldsSelect) {
  const { jobId } = useJobIdFromUrl();

  const { data: fields } = useEventExplorerFiltersFields({ jobId });

  return (
    <Select
      sx={{ width: '200px' }}
      isTextInputIncluded={false}
      onChange={(value: string) => {
        onChangeField(!_.isEmpty(value) ? value : ALL_FIELDS_VALUE);
      }}
      value={selectedField}
      options={[{ name: ALL_FIELDS_VALUE }, ...(fields?.fields_list ?? [])]?.map((field) => ({
        label: field.name,
        value: field.name
      }))}
      renderOptionLabel={(option) => (
        <TruncatedText variant="caption" text={typeof option === 'string' ? option : option.label} />
      )}
    />
  );
}
