import { TFilterItem } from '@arcanna/components';
import { TUseGetEventGroupsProps } from './useGetEventGroups/useGetEventGroups';
import { TUseGetEventsBatch } from './useGetEventsBatch';

export const eventKeys = {
  all: ['events'] as const,

  gets: () => [...eventKeys.all, 'get'] as const,

  randoms: () => [...eventKeys.gets(), 'random'] as const,
  random: (jobId: string | number, filters: TFilterItem[] | undefined) =>
    [...eventKeys.randoms(), String(jobId), filters] as const,

  raws: () => [...eventKeys.gets(), 'raw'] as const,
  raw: (eventId: string | number) => [...eventKeys.raws(), String(eventId)] as const,

  get: (eventId: string | number) => [...eventKeys.gets(), String(eventId)] as const,
  getBatch: (params?: Omit<TUseGetEventsBatch, 'enabled'>) =>
    params ? [...eventKeys.gets(), 'event-batch', params] : ([...eventKeys.gets(), 'event-batch'] as const),
  getFields: (eventId: string | undefined) => [...eventKeys.gets(), 'event-fields', eventId] as const,
  getGroupMetrics: (params?: TUseGetEventGroupsProps) =>
    params ? [...eventKeys.gets(), 'event-group-metrics', params] : ([...eventKeys.gets(), 'event-group-metrics'] as const),
  getGroupFields: (jobId: string | number) => [...eventKeys.gets(), 'event-group-fields', jobId] as const,
  similarity: (jobId: string | number, eventId: string | number | undefined) =>
    [...eventKeys.gets(), 'similarity', eventId, jobId] as const
};
