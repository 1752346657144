import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('IntegrationDecisionPointRecord')
export class IntegrationDecisionPointRecord {
  @JsonProperty('name', String)
  name: string | undefined;

  @JsonProperty('type', String)
  type: string | undefined;

  @JsonProperty('is_decision_point', Boolean)
  isDecisionPoint: boolean;

  @JsonProperty('is_event_explorer_column', Boolean)
  isEventExplorerColumn: boolean;
}
