import { StorageTagDeletionStatus } from '@arcanna/models/StorageTags';
import { useMemo } from 'react';
import { dateDiffInDays } from 'src/components/pages/Main/Jobs/helper';

type TStorageTagDeletionStatusProps = {
  storageTagDeletionStatus: StorageTagDeletionStatus;
};

function StorageTagDeletionStatusComponent({ storageTagDeletionStatus }: TStorageTagDeletionStatusProps) {
  const deletionStatusText = useMemo(() => {
    if (!storageTagDeletionStatus.soft) {
      return 'No';
    }

    return `${dateDiffInDays(new Date(), new Date(storageTagDeletionStatus.markedForDeleteOn))} Days Left`;
  }, [storageTagDeletionStatus]);

  return <>{deletionStatusText}</>;
}

export default StorageTagDeletionStatusComponent;
