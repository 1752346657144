import { Palette } from '@mui/material/styles';
import { TFunction } from 'i18next';
import { EIcon } from '@arcanna/generic';

const getConfidenceScoreColor = (palette: Palette, isLowConfidenceScore: boolean | undefined) => {
  if (isLowConfidenceScore) return palette.error[400];
  if (isLowConfidenceScore === false) return palette.success[300];
  return palette.success[300];
};

const getConfidenceScoreLabel = (t: TFunction, isLowConfidenceScore: boolean | undefined) => {
  if (isLowConfidenceScore) return t('feedback:confidenceScoreLow');
  if (isLowConfidenceScore === false) return t('feedback:confidenceScoreHigh');
  return t('feedback:confidenceScoreHigh');
};

const getIconName = (isAttentionRequired: boolean, isConfidenceScoreHigh: boolean) => {
  if (isAttentionRequired) {
    return EIcon.AlertTriangle;
  }
  if (isConfidenceScoreHigh) {
    return EIcon.FaceSmile;
  }

  return null;
};

export { getConfidenceScoreColor, getConfidenceScoreLabel, getIconName };
