import { Stack, Switch, SwitchProps, Theme, Typography, useTheme } from '@mui/material';
import { Label } from '@arcanna/generic';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';

type TRHFSwitchProps = SwitchProps & {
  name: string;
  label?: string;
  tooltipText?: string;
  tooltipImportantText?: string;
  required?: boolean;
  dataTestId?: string;
  textPosition?: 'vertical' | 'horizontal';
  isTextShown?: boolean;
  sx?: SxProps<Theme>;
};

function RHFSwitch({
  label,
  tooltipText,
  tooltipImportantText,
  name,
  required,
  disabled,
  dataTestId,
  textPosition = 'vertical',
  isTextShown = true,
  sx
}: TRHFSwitchProps) {
  const theme = useTheme();
  const {
    control,
    formState: { isSubmitting }
  } = useFormContext();
  const { t } = useTranslation('common');

  const toBoolValue = (field: ControllerRenderProps<FieldValues, string>) => {
    return field.value == 'true' || field.value == true;
  };

  return (
    <Controller
      name={name}
      data-test-id={'Ai de PLM'}
      control={control}
      render={({ field }) => (
        <Stack direction={textPosition === 'vertical' ? 'column' : 'row'} gap={0.5}>
          {textPosition === 'vertical' && label && (
            <Label text={label} required={required} tooltipText={tooltipText} tooltipImportantText={tooltipImportantText} />
          )}
          <Stack direction="row" alignItems="center" gap={1} sx={sx}>
            <Switch
              checked={toBoolValue(field)}
              // @ts-expect-error TS(2783): 'name' is specified more than once, so this usage ...
              name={name}
              disabled={disabled || isSubmitting}
              data-test-id={dataTestId}
              // @ts-expect-error TS(2322): Type 'false | { opacity: ".7"; } | undefined' is n...
              sx={disabled && { opacity: '.7' }}
              {...field}
            />
            {textPosition === 'horizontal' && label && (
              <Label text={label} required={required} tooltipText={tooltipText} tooltipImportantText={tooltipImportantText} />
            )}
            {textPosition === 'vertical' && isTextShown && (
              <Typography
                component="span"
                fontSize="14px"
                color={toBoolValue(field) ? theme.palette.secondary[100] : theme.palette.secondary[400]}
              >
                {toBoolValue(field) ? t('common:enabled') : t('common:disabled')}
              </Typography>
            )}
          </Stack>
        </Stack>
      )}
    />
  );
}

export default RHFSwitch;
