import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '../utils';
import { CustomLabel } from 'src/components/shared/models';

@JsonObject('AIModelImportSummaryResponse')
export class AIModelImportSummaryResponse extends ResponseCommon {
  @JsonProperty('features', [String])
  features: string[];

  @JsonProperty('custom_labels', [CustomLabel])
  custom_labels: CustomLabel[];
}
