import { Stack, Typography, useTheme } from '@mui/material';
import GenericOverviewCard from '../GenericOverviewCard';
import { renderNumericValue } from '../Overview.utils';
import { formatBytes } from '@arcanna/utils';

type TProcessedDetailsProps = {
  decisionsMade: number;
  processedEvents: number;
  processedData: number;
  isLoading: boolean;
};

function ProcessedDetails({ decisionsMade, processedEvents, processedData, isLoading }: TProcessedDetailsProps) {
  const { palette } = useTheme();

  return (
    <GenericOverviewCard height={'110px'} isLoading={isLoading}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2} py="11px">
        <Stack width={'140px'}>
          <Typography variant="subtitle1" color={palette.secondary[200]}>
            Decisions Made
          </Typography>
          {decisionsMade ? (
            renderNumericValue(decisionsMade, palette.secondary[100])
          ) : (
            <Typography fontSize={'28px'} fontWeight={700} lineHeight={'36px'}>
              0
            </Typography>
          )}
        </Stack>
        <Stack marginRight={'32px'}>
          <Typography variant="subtitle1" color={palette.secondary[200]}>
            Processed Events
          </Typography>
          {processedEvents ? (
            renderNumericValue(processedEvents, palette.secondary[100])
          ) : (
            <Typography fontSize={'28px'} fontWeight={700} lineHeight={'36px'}>
              0
            </Typography>
          )}
        </Stack>
        <Stack>
          <Typography variant="subtitle1" color={palette.secondary[200]}>
            Processed Data
          </Typography>
          <Typography fontSize={'28px'} fontWeight={700} lineHeight={'36px'}>
            {processedData ? formatBytes(processedData) : '0'}
          </Typography>
        </Stack>
      </Stack>
    </GenericOverviewCard>
  );
}

export default ProcessedDetails;
