/* eslint-disable max-len */

function DottedCircleFullUnchecked() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="currentColor" strokeDasharray="4 4" />
    </svg>
  );
}

export default DottedCircleFullUnchecked;
