import { Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import ModelManagementTable from './components/ModelManagementTable';
import ModelUpload from './components/ModelUpload';

function ModelManagement() {
  const theme = useTheme();

  return (
    <Card sx={{ maxWidth: '900px' }}>
      <CardContent sx={{ padding: '16px' }}>
        <Typography mb={1.5} color={theme.palette.secondary[200]} variant="body2" fontSize="14px">
          AI Models
        </Typography>

        <Stack mb={5}>
          <Typography color={theme.palette.secondary[200]} variant="body2" fontSize="14px">
            Import Arcanna AI Model
          </Typography>
          <Typography mb={1} color={theme.palette.secondary[300]} variant="caption">
            Supported formats: ZIP. 5mb max file size.
          </Typography>
          <ModelUpload
            maxSize={5 * 1024 * 1024} // 5MB
            maxFiles={1}
            accept={{ 'application/zip': ['.zip'], 'application/x-zip-compressed': ['.zip'] }}
          />
        </Stack>

        <ModelManagementTable />
      </CardContent>
    </Card>
  );
}

export default ModelManagement;
