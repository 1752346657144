import { DeleteIntegrationRequest } from '@arcanna/models/Flows/DeleteIntegrationRequest';
import { GenericIntegrationRecord } from '@arcanna/models/Flows/GenericIntegrationRecord';
import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { flowsKeys } from 'src/_srcMUI/requests/Flows/keys';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import { showErrorNotification, showSuccessNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';
import { jobInfoKey } from 'src/data-access';
import _ from 'lodash';
import { storageTagKeys } from '../StorageTags/keys';

type TUseDeleteIntegrationProps = {
  jobId: number;
  currentOrder: string[];
};

function useDeleteIntegration({ jobId, currentOrder }: TUseDeleteIntegrationProps) {
  // OTHER HOOKS
  const queryClient = useQueryClient();

  // SETUP
  const URL = useMemo(() => config.api.flows.delete({ jobId }), [jobId]);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<ResponseCommon>>(URL, body)
        .then((response) => Serializer.getInstance().deserializeCommonResponse(response, ResponseCommon)),
    [URL]
  );

  // QUERY
  return useMutation(
    flowsKeys.deletes(),
    (payload: DeleteIntegrationRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);

      return axiosFunction(payloadSerialized);
    },
    {
      onMutate: async (payload: DeleteIntegrationRequest) => {
        const queryKey = jobInfoKey(jobId);

        // Snapshot the previous value
        const previousJobInfo = queryClient.getQueryData<JobInfoResponse>(queryKey);

        // Optimistically update the cache
        if (previousJobInfo && previousJobInfo.info && previousJobInfo.info.pipelineIntegrations) {
          const newJobInfo = _.cloneDeep(previousJobInfo);
          const integrations = _.cloneDeep(newJobInfo?.info?.pipelineIntegrations);

          const integrationIndex = integrations?.findIndex(
            (integration: GenericIntegrationRecord) => integration.autoId === payload.autoId
          );

          if (integrationIndex !== undefined && integrationIndex >= 0 && integrations) {
            integrations.splice(integrationIndex, 1);

            // sort the integrations based on the currentOrder
            const sortedIntegrations = _.sortBy(integrations, (integration) => currentOrder.indexOf(integration.autoId));

            if (newJobInfo.info) {
              newJobInfo.info.pipelineIntegrations = sortedIntegrations;
            }

            // Update the cache
            queryClient.setQueryData(queryKey, newJobInfo);
          }
        }

        // Return context with previous data for rollback
        return { previousJobInfo };
      },
      onSuccess: async () => {
        showSuccessNotification('Success', 'Integration deleted successfully.');
        await queryClient.invalidateQueries(storageTagKeys.list({ jobId }));
      },
      onError: (
        error: AxiosResponse<ResourceWrapper<ResponseCommon>>,
        __,
        context: { previousJobInfo?: JobInfoResponse } | undefined
      ) => {
        // Rollback to previous data
        if (context?.previousJobInfo) {
          const integrations = context.previousJobInfo?.info?.pipelineIntegrations;
          const sortedIntegrations = _.sortBy(integrations, (integration) => currentOrder.indexOf(integration.autoId));

          const newJobInfo = _.cloneDeep(context.previousJobInfo);

          if (newJobInfo.info) {
            newJobInfo.info.pipelineIntegrations = sortedIntegrations;
          }

          queryClient.setQueryData(jobInfoKey(jobId), newJobInfo);
        }

        if (error.data?.resource?.request?.reason) {
          showErrorNotification('Error', error.data.resource.request.reason);
        } else {
          showErrorNotification('Error', 'Failed to delete integration.');
        }
      }
    }
  );
}

export default useDeleteIntegration;
