import { Box, Typography } from '@mui/material';
import { memo } from 'react';

function EventSampleDrawerSubtitle() {
  return (
    <Box>
      <Typography variant="subtitle2">Add an event to test your enrichment. You can:</Typography>
      <Box sx={{ pl: '4px' }}>
        <Typography variant="subtitle2">1. Use a random one (default).</Typography>
        <Typography variant="subtitle2">2. Add an existing event ID from Explorer.</Typography>
      </Box>
    </Box>
  );
}

export default memo(EventSampleDrawerSubtitle);
