import { apiKeysKeys } from '@arcanna/requests/ApiKeys';
import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { IntegrationDeleteRequest } from '../../components/shared/models/integration/IntegrationDeleteRequest';
import { useTranslation } from 'react-i18next';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { storageTagKeys } from '@arcanna/requests/StorageTags/keys';

type Response = AxiosResponse<{ resource: { request: CommonResponse } }>;

export function useDeleteIntegration() {
  const { t } = useTranslation(['common']);
  const queryClient = useQueryClient();
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useMutation(
    (integrationId: number) => {
      const url = config.api.integrationDelete.replace(':integrationId', `${integrationId}`);
      return axios.post<{ resource: { request: CommonResponse } }>(url, new IntegrationDeleteRequest());
    },
    {
      onSuccess: (responseData: Response) => {
        if (!responseData.data) {
          return;
        }

        if (!responseData.data.resource) {
          // eslint-disable-next-line
          showErrorNotification(t('common:actionCompleted'), (responseData.data.resource as any).request.reason);
          return;
        }

        const jobDeleteResponse = jsonConvert.deserializeObject(responseData.data.resource.request, CommonResponse);

        if (jobDeleteResponse.status === 'OK') {
          showSuccessNotification(t('common:actionCompleted'), t('common:deletedSuccessfully'));
        } else {
          showErrorNotification(t('common:error'), t('common:deletedError') + jobDeleteResponse.reason);
        }
      },
      onError: (responseData: Response) => {
        showErrorNotification(t('common:error'), t('common:deletedError') + responseData.data.resource.request.reason);
      },
      onSettled: () => {
        queryClient.invalidateQueries([config.api.integrations]);
        queryClient.invalidateQueries(apiKeysKeys.list());
        queryClient.invalidateQueries(storageTagKeys.all);
      }
    }
  );
}
