import { JsonObject, JsonProperty } from 'json2typescript';
import { IntegrationTypes } from 'src/components/shared/models/integration/IntegrationTypes';
import { JobCreateParameterRecord } from 'src/components/shared/models/job/JobCreateParameterRecord';
import { JobIntegrationThrottleRecord } from 'src/components/shared/models/job/JobIntegrationThrottleRecord';
import { ContextEnrichmentRecord } from '../ContextEnrichment';
import { CodeBlockIntegrationRecord } from './CodeBlockIntegrationRecord';
import { FilterRecordV2 } from '@arcanna/models/utils/FilterRecord';

@JsonObject('GenericIntegrationRecord')
export class GenericIntegrationRecord {
  @JsonProperty('auto_id', String)
  autoId: string;

  @JsonProperty('id', Number, true)
  id: number | undefined;

  @JsonProperty('title', String)
  title: string;

  @JsonProperty('integration_type', String)
  integrationType: IntegrationTypes;

  @JsonProperty('enabled', Boolean)
  enabled: boolean;

  @JsonProperty('params', [JobCreateParameterRecord])
  params: JobCreateParameterRecord[] | null;

  @JsonProperty('throttle', JobIntegrationThrottleRecord)
  throttle: JobIntegrationThrottleRecord | undefined;

  @JsonProperty('context_enrichment', ContextEnrichmentRecord, true)
  contextEnrichment: ContextEnrichmentRecord | null;

  @JsonProperty('code_block', CodeBlockIntegrationRecord, true)
  codeBlock: CodeBlockIntegrationRecord | null;

  @JsonProperty('storage_tag', String, true)
  storageTag?: string;

  @JsonProperty('storage_tag_display_name', String, true)
  storageTagDisplayName?: string;

  @JsonProperty('filters', [FilterRecordV2], true)
  filters?: FilterRecordV2[];
}

type TConstructorParams = {
  autoId: GenericIntegrationRecord['autoId'];
  id: GenericIntegrationRecord['id'];
  title: GenericIntegrationRecord['title'];
  integrationType: GenericIntegrationRecord['integrationType'];
  enabled: GenericIntegrationRecord['enabled'];
  params: GenericIntegrationRecord['params'];
  throttle: GenericIntegrationRecord['throttle'];
  contextEnrichment: GenericIntegrationRecord['contextEnrichment'];
  codeBlock: GenericIntegrationRecord['codeBlock'];
  storageTag?: GenericIntegrationRecord['storageTag'];
  storageTagDisplayName?: GenericIntegrationRecord['storageTagDisplayName'];
  filters?: GenericIntegrationRecord['filters'];
};

export function constructGenericIntegrationRecord({
  autoId,
  id,
  title,
  integrationType,
  enabled,
  params,
  throttle,
  contextEnrichment,
  codeBlock,
  storageTag,
  storageTagDisplayName,
  filters
}: TConstructorParams) {
  const instance = new GenericIntegrationRecord();
  instance.autoId = autoId;
  instance.id = id;
  instance.title = title;
  instance.integrationType = integrationType;
  instance.enabled = enabled;
  instance.params = params;
  instance.throttle = throttle;
  instance.contextEnrichment = contextEnrichment;
  instance.codeBlock = codeBlock;
  instance.storageTag = storageTag;
  instance.storageTagDisplayName = storageTagDisplayName;
  instance.filters = filters;

  return instance;
}
