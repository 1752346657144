import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { BucketTrainingStatus } from 'src/constants/BucketTrainingStatus';
import { getConfidenceScoreColor, getConfidenceScoreLabel, getIconName } from './RequireAttention.utils';
import { useTranslation } from 'react-i18next';
import { Fragment, ReactNode, useMemo } from 'react';
import { Icon } from '@arcanna/generic';
import { HTML_CODES, renderHTMLCode } from '@arcanna/constants';
import { EEventTrainingStatus } from '@arcanna/models/Event/GetEventsBatchResponse';

type TRequireAttentionProps = {
  confidenceScore: number;
  isOutlier?: boolean;
  isConsensusScoreLow?: boolean;
  isConsensusFlipping?: boolean;
  hasConsensusChangedFromLastRetrain?: boolean;
  lowConfidenceScore?: boolean;
  trainingStatus: BucketTrainingStatus | EEventTrainingStatus;
};

function RequireAttention({
  confidenceScore,
  isOutlier,
  isConsensusScoreLow,
  isConsensusFlipping,
  hasConsensusChangedFromLastRetrain,
  lowConfidenceScore,
  trainingStatus
}: TRequireAttentionProps) {
  const { palette } = useTheme();
  const { t } = useTranslation('feedback');

  const isConfidenceScoreHidden = useMemo(
    () => [BucketTrainingStatus.IN_MODEL, EEventTrainingStatus.IN_MODEL].includes(trainingStatus),
    [trainingStatus]
  );

  const doesLowConfidenceScoreRequireAttention = useMemo(
    () => lowConfidenceScore && !isConfidenceScoreHidden,
    [lowConfidenceScore, isConfidenceScoreHidden]
  );

  const isConfidenceScoreHigh = useMemo(
    () => !isConfidenceScoreHidden && !doesLowConfidenceScoreRequireAttention && confidenceScore !== 0,
    [isConfidenceScoreHidden, doesLowConfidenceScoreRequireAttention, confidenceScore]
  );

  const attentionRequiredCount = useMemo(() => {
    return [
      doesLowConfidenceScoreRequireAttention,
      isOutlier,
      isConsensusScoreLow,
      isConsensusFlipping,
      hasConsensusChangedFromLastRetrain
    ].filter(Boolean).length;
  }, [
    doesLowConfidenceScoreRequireAttention,
    isOutlier,
    isConsensusScoreLow,
    isConsensusFlipping,
    hasConsensusChangedFromLastRetrain
  ]);

  const iconName = getIconName(attentionRequiredCount !== 0, isConfidenceScoreHigh);

  const confidenceScoreColor = getConfidenceScoreColor(palette, lowConfidenceScore);
  const confidenceScoreLabel = getConfidenceScoreLabel(t, lowConfidenceScore);

  const bulletPointComponent = (
    <Typography component="span" fontWeight="inherit" color="inherit" fontSize="inherit" lineHeight="inherit">
      <span dangerouslySetInnerHTML={renderHTMLCode(HTML_CODES.BULLET_POINT)} />
    </Typography>
  );

  const renderCommonComponent = (key: string, content: ReactNode) => (
    <Stack key={key} direction="row" alignItems="flex-start" spacing={0.5}>
      {attentionRequiredCount > 2 && bulletPointComponent}
      {content}
    </Stack>
  );

  const renderConfidenceScore = () =>
    renderCommonComponent(
      'confidenceScore',
      <Box>
        <Typography
          component="span"
          fontWeight={700}
          color={confidenceScoreColor}
          fontSize="inherit"
          lineHeight="inherit"
          display="inline"
        >
          {confidenceScoreLabel}
          <span dangerouslySetInnerHTML={renderHTMLCode(HTML_CODES.SPACE)} />
        </Typography>
        {t('feedback:confidenceScore')}
        <span dangerouslySetInnerHTML={renderHTMLCode(HTML_CODES.SPACE)} />
        <Typography component="span" fontWeight={700} color="inherit" fontSize="inherit" lineHeight="inherit" display="inline">
          {confidenceScore}
          <span dangerouslySetInnerHTML={renderHTMLCode(HTML_CODES.PERCENTAGE)} />
        </Typography>
        <span dangerouslySetInnerHTML={renderHTMLCode(HTML_CODES.DOT)} />
      </Box>
    );

  const renderConsensusScore = () =>
    renderCommonComponent(
      'consensusScore',
      <Box>
        <Typography
          component="span"
          fontWeight={700}
          color={palette.error[400]}
          fontSize="inherit"
          lineHeight="inherit"
          display="inline"
        >
          {t('feedback:consensusScoreWeak')}
          <span dangerouslySetInnerHTML={renderHTMLCode(HTML_CODES.SPACE)} />
        </Typography>
        {t('feedback:consensusScore')}
      </Box>
    );

  const renderOutlier = () =>
    renderCommonComponent(
      'outlier',
      <Typography fontWeight="inherit" color="inherit" fontSize="inherit" lineHeight="inherit">
        {t('feedback:outlierTooltip')}
      </Typography>
    );

  const renderConsensusChangedFromLastRetrain = () =>
    renderCommonComponent(
      'consensusChanged',
      <Typography fontWeight="inherit" color="inherit" fontSize="inherit" lineHeight="inherit">
        {t('feedback:consensusChangedFromLastRetrainTooltip')}
      </Typography>
    );

  const renderConsensusFlipping = () =>
    renderCommonComponent(
      'consensusFlipping',
      <Typography fontWeight="inherit" color="inherit" fontSize="inherit" lineHeight="inherit">
        {t('feedback:consensusFlippingTooltip')}
      </Typography>
    );

  const renderComponents = () => {
    const components = [];
    if (doesLowConfidenceScoreRequireAttention || isConfidenceScoreHigh) components.push(renderConfidenceScore());
    if (isConsensusScoreLow) components.push(renderConsensusScore());
    if (isOutlier) components.push(renderOutlier());
    if (hasConsensusChangedFromLastRetrain) components.push(renderConsensusChangedFromLastRetrain());
    if (isConsensusFlipping) components.push(renderConsensusFlipping());

    return components.map((component, index) => <Fragment key={index}>{component}</Fragment>);
  };

  return (
    <Stack alignItems="center" gap={0.5}>
      <Tooltip
        placement="top"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              color: palette.secondary[100],
              background: palette.secondary[600]
            }
          },
          arrow: {
            sx: {
              color: palette.secondary[600]
            }
          }
        }}
        title={
          <Stack direction="column" fontWeight={400} lineHeight="20px" color={palette.secondary[100]} maxWidth="270px">
            {renderComponents()}
          </Stack>
        }
        enterDelay={1000}
        enterNextDelay={1000}
      >
        <Stack>
          {iconName && (
            <Icon
              name={iconName}
              htmlColor={palette.secondary[300]}
              sx={{
                fontSize: '16px',
                '&hover': {
                  color: palette.secondary[100]
                }
              }}
            />
          )}
        </Stack>
      </Tooltip>
    </Stack>
  );
}

export default RequireAttention;
