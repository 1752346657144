import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from 'src/_srcMUI/models/utils';

@JsonObject('FlowsValidationRecord')
export class FlowsValidationRecord {
  @JsonProperty('auto_id', String)
  autoId: string;

  @JsonProperty('validation_level', String)
  validationLevel: string;

  @JsonProperty('validation_message', String)
  validationMessage: string;
}

@JsonObject('FlowsValidationResponse')
export class FlowsValidationResponse extends ResponseCommon {
  @JsonProperty('validation', [FlowsValidationRecord])
  validations: FlowsValidationRecord[];
}
