import { RequestCommon, ResourceWrapper } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { GetRawEventByIdResponse } from '@arcanna/models/Event';
import { eventKeys } from './keys';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';

type TUseGetRandomEventProps = {
  jobId: number;
  eventId: string;
  enabled?: boolean;
};

function useGetRawEventById({ jobId, eventId, enabled = true }: TUseGetRandomEventProps) {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);

  // SETUP
  const URL = useMemo(() => config.api.jobRawAlert.replace(':id', String(jobId)).replace(':alertId', eventId), [eventId, jobId]);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<GetRawEventByIdResponse>>(URL, body)
        .then((responseData) => Serializer.getInstance().deserializeCommonResponse(responseData, GetRawEventByIdResponse)),
    [URL]
  );

  // PAYLOAD
  const payload = useMemo(() => new RequestCommon(), []);
  const payloadSerialized = useMemo(() => Serializer.getInstance().serializeObject(payload), [payload]);

  // QUERY
  return useQuery(eventKeys.raw(eventId), () => axiosFunction(payloadSerialized), {
    onError: () => {
      showErrorNotification(t('requests:events.getRawById.error.title'), t('requests:events.getRawById.error.subtitle'));
    },
    enabled: enabled,
    staleTime: Infinity
  });
}

export default useGetRawEventById;
