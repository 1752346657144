import { DocsButton } from '@arcanna/components';
import { Collapsible, Spinner } from '@arcanna/generic';
import { constructEventEntryRecord, constructGetRandomEventResponse, GetRandomEventResponse } from '@arcanna/models/Event';
import { Box, Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../../../../config';
import { useEventInfo } from '../../../../../../data-access';

type TEventSampleDrawerCollapsibleProps = {
  jobId: number;
  setEventSample: (event: GetRandomEventResponse) => void;
  eventSample: GetRandomEventResponse | undefined;
  eventId: string;
  setEventId: (eventId: string) => void;
};

function EventSampleDrawerCollapsible({
  jobId,
  setEventSample,
  eventSample,
  eventId,
  setEventId
}: TEventSampleDrawerCollapsibleProps) {
  const { t } = useTranslation(['codeBlock']);
  const { palette } = useTheme();
  const jobIdString = jobId.toString();

  const {
    refetch: getEventById,
    isRefetchError: isGetEventByIdError,
    isRefetching: isGetEventByIdRefetching
  } = useEventInfo({
    jobId,
    eventId: eventSample?.alertId ?? '',
    includeInternal: false,
    enabled: false
  });

  const handleAddEvent = useCallback(async () => {
    const response = await getEventById();
    if (!isGetEventByIdError && response.data?.entries) {
      const newEventSample = constructGetRandomEventResponse({
        entries: response.data.entries.map((entry) => {
          return constructEventEntryRecord({
            name: entry.name ?? '',
            value: entry.value ?? '',
            type: entry.type ?? ''
          });
        }),
        alertId: eventId ?? '',
        rawAlert: ''
      });

      setEventSample(newEventSample);
    }
  }, [eventId, getEventById, isGetEventByIdError, setEventSample]);

  useEffect(() => {
    if (eventId) {
      handleAddEvent();
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Collapsible
      heading={<Typography variant="subtitle2">{t('codeBlock:editTestInputDrawer.collapsible.title')}</Typography>}
      size="extra-small"
      isForcedExpanded={Boolean(eventId)}
    >
      <Stack p="12px 16px" width="100%" gap={1} bgcolor={palette.background.default} borderRadius="4px">
        <Box>
          <Typography variant="subtitle2" color={palette.secondary[300]}>
            <Typography variant="inherit" color="inherit" component="span">
              {t('codeBlock:editTestInputDrawer.collapsible.helperText')}
            </Typography>
            <DocsButton
              url={config.routes.eventExplorer.replace(':id', jobIdString)}
              buttonTitle={t('codeBlock:editTestInputDrawer.collapsible.explorer')}
              buttonProps={{
                variant: 'text',
                sx: {
                  '& .MuiButton-endIcon': {
                    marginLeft: '2px'
                  },
                  '&.MuiButtonBase-root': {
                    height: 'unset',
                    fontSize: '12px',
                    lineHeight: '20px',
                    padding: 0,
                    fontWeight: 500
                  }
                }
              }}
            />
          </Typography>
        </Box>
        <Stack direction="row" alignItems="flex-end" justifyContent="flex-start" gap={1} mb={1}>
          <TextField
            size="small"
            label={
              <Typography variant="subtitle2" color={palette.secondary[200]}>
                {t('codeBlock:editTestInputDrawer.collapsible.label')}
              </Typography>
            }
            sx={{
              width: '467px'
            }}
            inputProps={{
              sx: { height: '24px' }
            }}
            value={eventId}
            onChange={(event) => setEventId(event.target.value)}
          />
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={!eventId || isGetEventByIdRefetching}
            endIcon={isGetEventByIdRefetching && <Spinner />}
            onClick={handleAddEvent}
          >
            {t('codeBlock:common.buttons.addEvent')}
          </Button>
        </Stack>
      </Stack>
    </Collapsible>
  );
}

export default EventSampleDrawerCollapsible;
