import { Drawer } from '@arcanna/generic';
import { ModelHistory } from 'src/components/shared/models/retrain-history/JobAllRetrainHistoryResponse';
import ModelHistoryInsights from './components/ModelHistoryInsights';
import ModelHistoryConfusionMatrix from './components/ModelHistoryConfusionMatrix';
import { Stack } from '@mui/material';

type TModelHistoryPerformanceDrawerProps = {
  isOpen: boolean;
  handleClose: () => void;
  AIModel: ModelHistory;
};

function ModelHistoryPerformanceDrawer({ isOpen, handleClose, AIModel }: TModelHistoryPerformanceDrawerProps) {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      header={`${AIModel.modelFriendlyName ?? AIModel.modelId} Performance`}
    >
      <Stack gap={4}>
        <ModelHistoryInsights AIModel={AIModel} />
        <ModelHistoryConfusionMatrix AIModel={AIModel} />
      </Stack>
    </Drawer>
  );
}

export default ModelHistoryPerformanceDrawer;
