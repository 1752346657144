export const flowsKeys = {
  all: ['flows'] as const,

  list: () => [...flowsKeys.all, 'list'] as const,

  deletes: () => [...flowsKeys.all, 'delete'] as const,
  delete: (autoId: string) => [...flowsKeys.deletes(), autoId] as const,

  enablesDisables: () => [...flowsKeys.all, 'enableDisable'] as const,
  enableDisable: (autoId: string) => [...flowsKeys.enablesDisables(), autoId] as const,

  updates: () => [...flowsKeys.all, 'update'] as const,
  update: (autoId: string) => [...flowsKeys.updates(), autoId] as const,

  add: () => [...flowsKeys.all, 'add'] as const,

  order: () => [...flowsKeys.all, 'order'] as const,

  integrationSubcategoryDecisionPoints: ['integrationSubcategoryDecisionPoints'] as const,

  integrationSubcategoryDecisionPointsList: (jobId: number, subcategoryId: number) =>
    [...flowsKeys.integrationSubcategoryDecisionPoints, 'list', jobId, subcategoryId] as const
};
