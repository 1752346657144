import { JsonObject, JsonProperty } from 'json2typescript';
import { FilterRecordV2 } from 'src/_srcMUI/models/utils/FilterRecord';

@JsonObject('ContextEnrichmentField')
export class ContextEnrichmentField {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('is_custom', Boolean, true)
  isCustom?: boolean | undefined;

  @JsonProperty('is_decision_point', Boolean, true)
  isDecisionPoint?: boolean | undefined;

  @JsonProperty('is_visible_in_explorer_table', Boolean, true)
  isEventExplorerColumn?: boolean | undefined;
}

@JsonObject('IntegrationParamsSimple')
export class IntegrationParamsSimple {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('value', String)
  value: string;

  @JsonProperty('is_vaulted', Boolean, true)
  isVaulted?: boolean | undefined;

  @JsonProperty('should_encrypt', Boolean, true)
  shouldEncrypt?: boolean | undefined;
}

@JsonObject('IntegrationParamsComplex')
export class IntegrationParamsComplex {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('value', [IntegrationParamsSimple], true)
  value?: IntegrationParamsSimple[];
}

@JsonObject('ContextEnrichmentRecord')
export class ContextEnrichmentRecord {
  @JsonProperty('context_id', String)
  contextId: string;

  @JsonProperty('enrichment_name', String)
  internalId: string;

  @JsonProperty('friendly_name', String)
  contextName: string;

  @JsonProperty('integration_id', String)
  integrationId: string;

  @JsonProperty('integration_params_simple', [IntegrationParamsSimple])
  integrationParamsSimple: IntegrationParamsSimple[] | undefined;

  @JsonProperty('integration_params_complex', [IntegrationParamsComplex])
  integrationParamsComplex: IntegrationParamsComplex[] | undefined;

  @JsonProperty('body_value', String, true)
  bodyValue: string | undefined;

  @JsonProperty('body_type', String, true)
  bodyType: string | undefined;

  @JsonProperty('field_mapping', [ContextEnrichmentField], true)
  fieldMapping: ContextEnrichmentField[] | undefined;

  @JsonProperty('filters', [FilterRecordV2], true)
  filters: FilterRecordV2[] | undefined;
}

type TConstructorParams = {
  contextId: string;
  internalId: string;
  contextName: string;
  integrationId: string;
  integrationParamsSimple: IntegrationParamsSimple[] | undefined;
  integrationParamsComplex: IntegrationParamsComplex[] | undefined;
  bodyValue: string | undefined;
  bodyType: string | undefined;
  fieldMapping: ContextEnrichmentField[] | undefined;
  filters: FilterRecordV2[] | undefined;
};

export function constructContextEnrichmentRecord(params: TConstructorParams): ContextEnrichmentRecord {
  const {
    contextId,
    internalId,
    contextName,
    integrationId,
    integrationParamsSimple,
    integrationParamsComplex,
    bodyValue,
    bodyType,
    fieldMapping,
    filters
  } = params;

  const record = new ContextEnrichmentRecord();
  record.contextId = contextId;
  record.internalId = internalId;
  record.contextName = contextName;
  record.integrationId = integrationId;
  record.integrationParamsSimple = integrationParamsSimple;
  record.integrationParamsComplex = integrationParamsComplex;
  record.bodyValue = bodyValue;
  record.bodyType = bodyType;
  record.fieldMapping = fieldMapping;
  record.filters = filters;

  return record;
}
