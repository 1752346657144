import { styled, Table, TableProps } from '@mui/material';

const StyledModelHistoryConfusionMatrix = styled(Table)<TableProps>(({ theme }) => ({
  borderCollapse: 'separate',

  'thead th': {
    position: 'sticky',
    top: '0',
    backgroundColor: theme.palette.secondary[1000]
  },

  'tbody td:first-child': {
    position: 'sticky',
    left: '0',
    backgroundColor: theme.palette.secondary[1000]
  },

  'thead th:first-child': {
    left: 0,
    zIndex: 1
  },

  'td:not(:first-child)': {
    textAlign: 'center'
  }
}));

export default StyledModelHistoryConfusionMatrix;
