import { Box, useTheme } from '@mui/material';

type TProgressBarProps = {
  percent: number;
  color: string;
  bgColor?: string;
};

function ProgressBar({ percent, color, bgColor }: TProgressBarProps) {
  const { palette } = useTheme();
  const clampedPercent = Math.min(Math.max(percent, 0), 100);

  return (
    <Box
      sx={{
        width: '100%',
        height: '4px',
        backgroundColor: bgColor ?? palette.secondary[700],
        borderRadius: '4px',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          width: `${clampedPercent}%`,
          height: '100%',
          backgroundColor: color,
          borderRadius: 'inherit',
          transition: 'width 0.2s ease-in-out'
        }}
      />
    </Box>
  );
}

export default ProgressBar;
