import { EIcon, Icon } from '@arcanna/generic';
import { Stack, Typography } from '@mui/material';
import React from 'react';

const ECDIFieldsTab = React.lazy(() => import('./components/ECDIFieldsTab'));
const ECDIExplorerTab = React.lazy(() => import('./components/ECDIExplorerTab'));
const ECDIExplorerKBTab = React.lazy(() => import('./components/ECDIExplorerKBTab'));

export enum EECDIExplorerTabs {
  FIELDS = 'fields',
  EVENTS = 'events',
  KB_VIEW = 'kbView'
}

export const getTabsConfig = () => [
  {
    tabKey: EECDIExplorerTabs.FIELDS,
    title: (
      <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
        <Icon name={EIcon.Brain} fontSize="medium" />
        <Typography variant="subtitle1" fontWeight={500} color="inherit">
          Decision Points
        </Typography>
      </Stack>
    ),
    component: ECDIFieldsTab
  },
  {
    tabKey: EECDIExplorerTabs.EVENTS,
    title: (
      <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
        <Icon name={EIcon.SearchLg} fontSize="medium" />
        <Typography variant="subtitle1" fontWeight={500} color="inherit">
          Events
        </Typography>
      </Stack>
    ),
    component: ECDIExplorerTab
  },
  {
    tabKey: EECDIExplorerTabs.KB_VIEW,
    title: (
      <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
        <Icon name={EIcon.Eye} fontSize="medium" />
        <Typography variant="subtitle1" fontWeight={500} color="inherit">
          Review Feedback
        </Typography>
      </Stack>
    ),
    component: ECDIExplorerKBTab
  }
];
