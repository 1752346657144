import { TruncatedText } from '@arcanna/components';
import { EIcon, Icon, Pagination, Spinner, TPageSize } from '@arcanna/generic';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { Box, InputAdornment, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../generic/Checkbox';
import { intersection, not, union } from './TransferList.utils';

export type TTransferListProps = {
  allItems: string[];
  rightListValue?: string[];
  onChange: (value: string[]) => void;
  height?: string | number;
  isLoading?: boolean;
  isSubmitting?: boolean;
  dataTestId?: string;
};

export function TransferList({
  allItems,
  rightListValue = [],
  onChange,
  isLoading,
  height = 230,
  isSubmitting,
  dataTestId
}: TTransferListProps) {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const [checkedElements, setCheckedElements] = useState<string[]>([]);
  const [leftElements, setLeftElements] = useState<string[]>([]);
  const [rightElements, setRightElements] = useState<string[]>([]);
  const [leftSearchText, setLeftSearchText] = useState('');
  const [rightSearchText, setRightSearchText] = useState('');

  const [leftPage, setLeftPage] = useState(1);
  const [leftPageSize, setLeftPageSize] = useState<TPageSize>(20);
  const [rightPage, setRightPage] = useState(1);
  const [rightPageSize, setRightPageSize] = useState<TPageSize>(20);

  useEffect(() => {
    setLeftElements(allItems.filter((item) => !rightListValue.includes(item)));
    setRightElements(rightListValue || []);
    setLeftPage(1);
    setRightPage(1);
  }, [allItems, rightListValue]);

  useEffect(() => {
    setLeftElements(allItems.filter((item) => !rightListValue.includes(item)));
    setRightElements(rightListValue || []);
  }, [allItems, rightListValue]);

  const leftChecked = intersection(checkedElements, leftElements);
  const rightChecked = intersection(checkedElements, rightElements);

  const handleToggle = (value: string) => () => {
    const currentIndex = checkedElements.indexOf(value);
    const newChecked = [...checkedElements];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedElements(newChecked);
  };

  const handleCheckedRight = () => {
    const updatedRight = rightElements.concat(leftChecked);
    setRightElements(updatedRight);
    onChange(updatedRight);
    setLeftElements(not(leftElements, leftChecked));
    setCheckedElements(not(checkedElements, leftChecked));
  };

  const handleCheckedLeft = () => {
    const updatedRight = not(rightElements, rightChecked);
    setLeftElements(leftElements.concat(rightChecked));
    setRightElements(updatedRight);
    onChange(updatedRight);
    setCheckedElements(not(checkedElements, rightChecked));
  };

  const customList = (
    title: ReactNode,
    items: string[],
    searchText: string,
    onSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void,
    page: number,
    pageSize: TPageSize,
    onPageChange: (page: number) => void,
    onPageSizeChange: (pageSize: TPageSize) => void
  ) => {
    const filteredItems = items.filter((item) => item.toLowerCase().includes(searchText.toLowerCase()));
    const numberOfChecked = intersection(checkedElements, filteredItems).length;

    const handleToggleAll = () => {
      if (numberOfChecked === filteredItems.length && filteredItems.length !== 0) {
        setCheckedElements(not(checkedElements, filteredItems));
      } else {
        setCheckedElements(union(checkedElements, filteredItems));
      }
    };

    const paginatedItems = filteredItems.slice((page - 1) * pageSize, page * pageSize);

    return (
      <Card sx={{ width: '100%' }}>
        <CardHeader
          sx={{ px: 2, py: 1, '.MuiCardHeader-avatar': { marginRight: 0 } }}
          avatar={
            <Checkbox
              onChange={handleToggleAll}
              disabled={filteredItems.length === 0 || isLoading || isSubmitting}
              state={numberOfChecked === filteredItems.length && filteredItems.length !== 0 ? 'checked' : 'default'}
            />
          }
          title={
            <Stack direction="row" display="flex" justifyContent="space-between">
              <Typography variant="subtitle1">{`${numberOfChecked}/${filteredItems.length} selected`}</Typography>
              <Typography variant="subtitle1">{title}</Typography>
            </Stack>
          }
        />
        <Divider />
        <Box p={1}>
          <TextField
            disabled={isLoading || isSubmitting}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name={EIcon.ActionSearch} htmlColor={palette.secondary[300]} />
                </InputAdornment>
              ),
              sx: { height: '36px' }
            }}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: palette.background.default
              }
            }}
            fullWidth
            placeholder={t('common:search') + '...'}
            value={searchText}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onSearchTextChange(e);
              onPageChange(1);
            }}
            data-test-id={`${dataTestId}-search-bar`}
          />
        </Box>
        <Divider />
        <List
          sx={{
            height: height,
            bgcolor: 'background.paper',
            overflow: 'auto',
            border: '0px',
            position: 'relative'
          }}
        >
          {isLoading ? (
            <Spinner isOverlay />
          ) : (
            paginatedItems.map((value: string) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItemButton key={value} role="listitem" onClick={handleToggle(value)}>
                  <Checkbox
                    state={checkedElements.indexOf(value) !== -1 ? 'checked' : 'default'}
                    disabled={isLoading || isSubmitting}
                    data-test-id={'checkbox-' + value}
                  />
                  <ListItemText id={labelId} primary={<TruncatedText variant="subtitle1" text={value} />} />
                </ListItemButton>
              );
            })
          )}
        </List>
        <Divider />
        {/* Render Pagination component */}
        <Box p={1}>
          <Pagination
            page={page}
            total={filteredItems.length}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            showText={false}
          />
        </Box>
      </Card>
    );
  };

  return (
    <Grid container spacing={2} justifyContent="left" alignItems="stretch">
      <Grid item display="flex" flexGrow={1} flexBasis={0} xs={5.5}>
        {customList(
          'Source',
          leftElements,
          leftSearchText,
          (event) => setLeftSearchText(event.target.value),
          leftPage,
          leftPageSize,
          (newPage) => setLeftPage(newPage),
          (newPageSize) => setLeftPageSize(newPageSize)
        )}
      </Grid>
      <Grid item xs={1}>
        <Grid container direction="column" justifyContent="center" height="100%">
          <Button
            sx={{ my: 0.5 }}
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || isSubmitting}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || isSubmitting}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item display="flex" flexGrow={1} flexBasis={0} xs={5.5}>
        {customList(
          'Target',
          rightElements,
          rightSearchText,
          (event) => setRightSearchText(event.target.value),
          rightPage,
          rightPageSize,
          (newPage) => setRightPage(newPage),
          (newPageSize) => setRightPageSize(newPageSize)
        )}
      </Grid>
    </Grid>
  );
}

export default TransferList;
