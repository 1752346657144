/* eslint-disable max-len */
function Upload() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M9.38428 10.6182V18" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.92285 13.0796L9.38425 10.6182L11.8457 13.0796"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1531 11.3564C16.6102 11.0927 17.0055 10.7341 17.3122 10.3048C17.619 9.87547 17.8301 9.38539 17.9314 8.86759C18.0327 8.3498 18.0217 7.81632 17.8993 7.30311C17.7768 6.78991 17.5457 6.30891 17.2216 5.89255C16.8975 5.47618 16.4878 5.13413 16.0202 4.88944C15.5526 4.64475 15.038 4.50311 14.5111 4.47408C13.9841 4.44505 13.457 4.5293 12.9654 4.72115C12.4737 4.91299 12.029 5.20798 11.661 5.58622C11.3384 4.44183 10.6122 3.4529 9.61671 2.80226C8.62125 2.15162 7.4238 1.88325 6.24575 2.04678C5.0677 2.2103 3.9887 2.79466 3.20822 3.69182C2.42774 4.58898 1.99855 5.73828 2 6.92725C2.00088 8.04575 2.38286 9.13059 3.08302 10.003"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Upload;
