import { useMutation, useQueryClient } from 'react-query';
import { useCallback, useMemo } from 'react';
import { config } from '../../../config';
import axios, { AxiosResponse } from 'axios';
import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import { storageTagKeys } from '@arcanna/requests/StorageTags/keys';
import { StorageTagDisplayNameEditRequest } from '@arcanna/models/StorageTags/StorageTagDisplayNameEditRequest';
import { jobInfoKey } from '../../../data-access';
import { showErrorNotification, showSuccessNotification } from '../../../components/shared/utilities/notification';

type TUseStorageDisplayNameEditProps = {
  jobId: number;
  storageTag: string;
};

export default function useStorageDisplayNameEdit({ jobId, storageTag }: TUseStorageDisplayNameEditProps) {
  const queryClient = useQueryClient();
  const URL = useMemo(() => config.api.storageManagement.edit({ jobId, storageTag }), [jobId, storageTag]);

  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<ResponseCommon>>(URL, body)
        .then((response) => Serializer.getInstance().deserializeCommonResponse(response, ResponseCommon)),
    [URL]
  );
  return useMutation(
    storageTagKeys.edit(),
    (payload: StorageTagDisplayNameEditRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);
      return axiosFunction(payloadSerialized);
    },
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(jobInfoKey(jobId)),
          queryClient.invalidateQueries(storageTagKeys.list({ jobId }))
        ]);
        showSuccessNotification('Storage Display Name Updated', `Display name for storage tag: ${storageTag} was updated.`);
      },
      onError: (error: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
        if (error?.data?.resource?.request?.reason) {
          showErrorNotification('Error Updating Storage Display Name', error.data.resource.request.reason);
        } else {
          showErrorNotification('Error Updating Storage Display Name', 'Failed to update display name for storage tag');
        }
      }
    }
  );
}
