import { AIModelExportRequest } from '@arcanna/models/AIModels';
import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { createAndDownloadBlob, Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';
import { AIModelKeys } from './keys';

function useExportAIModel({ jobId }: { jobId: number }) {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);

  // SETUP
  const URL = useMemo(() => config.api.aiModel.export(jobId), [jobId]);
  const axiosFunction = useCallback(
    (body: object, modelId: string) =>
      axios
        .post(URL, body, {
          responseType: 'blob'
        })
        .then((response) => createAndDownloadBlob(response.data, `${modelId}`, { type: 'application/zip' })),
    [URL]
  );

  return useMutation(
    AIModelKeys.export(),
    (payload: AIModelExportRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);
      return axiosFunction(payloadSerialized, payload.modelId);
    },
    {
      onError: (error: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
        if (error?.data?.resource?.request?.reason) {
          showErrorNotification(t('requests:AIModels.exportModel.error.title'), error.data.resource.request.reason);
        } else {
          showErrorNotification(
            t('requests:AIModels.exportModel.error.title'),
            t('requests:AIModels.exportModel.error.subtitle')
          );
        }
      }
    }
  );
}

export default useExportAIModel;
