import { EIcon, Icon } from '@arcanna/generic';
import { Button, ButtonProps, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { TNotificationType } from '../Notifications.types';
import { getNotificationColorsByType, getNotificationIconByType } from '../Notifications.utils';
import StyledInlineNotification from './StyledInlineNotification';

type TActionButtonProps = Pick<ButtonProps, 'variant' | 'sx'> & {
  text: string;
  onClick: () => void;
  icon?: ReactNode;
  dataTestId?: string;
  action?: string;
  disabled?: boolean;
};

type TInlineNotificationProps = {
  type: TNotificationType;
  percentage?: number;
  title: string;
  subtitle?: string;
  actionButton?: TActionButtonProps;
  isClosable?: boolean;
  textPosition?: 'vertical' | 'horizontal';
  children?: ReactNode;
} & StackProps;

/*
  You can use children component to pass special buttons such as
  training button or decision points button,
  or perhaps any button that requires complex logic.
*/

function InlineNotification({
  type,
  percentage,
  title,
  subtitle,
  actionButton,
  isClosable,
  children,
  textPosition = 'horizontal',
  ...otherProps
}: TInlineNotificationProps) {
  const { palette } = useTheme();
  const [showNotification, setShowNotification] = useState<boolean>(true);

  const onClose = () => {
    setShowNotification(false);
  };

  const icon = getNotificationIconByType(type, palette);
  const isTextPositionVertical = textPosition === 'vertical';

  if (showNotification) {
    return (
      <StyledInlineNotification
        notificationColor={getNotificationColorsByType(type, palette).borderColor}
        textPosition={textPosition}
        {...otherProps}
      >
        <Stack
          flexDirection="row"
          justifyContent="flex-start"
          // for the icon to stay on top
          alignItems={isTextPositionVertical ? 'flex-start' : 'center'}
          gap="8px"
          fontSize="24px"
        >
          {icon}
          <Stack
            flexDirection={isTextPositionVertical ? 'column' : 'row'}
            justifyContent="center"
            alignItems={isTextPositionVertical ? 'flex-start' : 'center'}
            gap="8px"
            fontSize="24px"
          >
            {percentage !== null && percentage !== undefined && (
              <Typography variant="subtitle1" fontWeight={500}>
                {percentage}%
              </Typography>
            )}
            <Typography variant="subtitle1" fontWeight={500} color={palette.secondary[100]}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="subtitle1" color={palette.secondary[300]}>
                {subtitle}
              </Typography>
            )}
            {actionButton && (
              <Button
                data-test-id={actionButton.dataTestId}
                variant={actionButton.variant ?? 'contained'}
                size="small"
                endIcon={actionButton.icon ? actionButton.icon : null}
                onClick={actionButton.onClick}
                disabled={actionButton.disabled}
                sx={actionButton.sx}
              >
                {actionButton.text}
              </Button>
            )}
            {children}
          </Stack>
        </Stack>
        {isClosable && (
          <Icon
            name={EIcon.ActionClose}
            onClick={onClose}
            htmlColor={palette.secondary[400]}
            width="24px"
            height="24px"
            sx={{
              fontSize: '16px',
              padding: '2px',
              cursor: 'pointer',
              marginRight: '4px',
              '&:hover': {
                color: palette.secondary[300]
              }
            }}
          />
        )}
      </StyledInlineNotification>
    );
  }

  return null;
}

export default InlineNotification;
