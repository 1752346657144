import { useJobIdFromUrl } from '@arcanna/hooks';
import { StorageTagRecord } from '@arcanna/models/StorageTags';
import { useStorageTags } from '@arcanna/requests/StorageTags';
import { InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import { getPaginationRowModel } from '@tanstack/react-table';
import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { getColumns } from './StorageTagManagementTable.utils';
import { EIcon, Icon, Table } from '@arcanna/generic';
import { useTranslation } from 'react-i18next';

function StorageTagManagementTable() {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const { jobId } = useJobIdFromUrl();

  const { data: storageTags, isLoading } = useStorageTags({ jobId });

  const tableData = useMemo(() => storageTags?.entries ?? [], [storageTags]);

  const columns = useMemo(() => getColumns(), []);

  const tableInstance = useReactTable<StorageTagRecord>({
    data: tableData,
    columns,
    enableColumnPinning: false,
    enableColumnResizing: false,
    enableMultiRowSelection: false,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  return (
    <Stack>
      <Typography variant="caption" fontSize="14px">
        Title
      </Typography>
      <Typography variant="caption" color={theme.palette.secondary[300]} maxWidth="300px">
        This tag uniquely identifies and organizes customer or multi-source data for isolated data management.
      </Typography>
      <TextField
        placeholder={t('common:search') + '...'}
        InputProps={{
          sx: { height: '36px', color: theme.palette.secondary[200] },
          startAdornment: (
            <InputAdornment position="start">
              <Icon name={EIcon.ActionSearch} htmlColor={theme.palette.secondary[300]} />
            </InputAdornment>
          )
        }}
        sx={{ width: '320px', marginY: 1.5 }}
        value={tableInstance.getState().globalFilter}
        onChange={(event) => {
          tableInstance.setGlobalFilter(event.target.value);
        }}
      />
      <Stack gap={2}>
        <Table.component<StorageTagRecord>
          isLoading={isLoading}
          tableInstance={tableInstance}
          isTableResizable={false}
          noResultsMessageTitle={t('common:noResults')}
          noResultsMessageSubtitle={t('common:adjustSearchCriteria')}
          defaultTableSize={Table.TABLE_SIZES.small}
        />
        <Table.TablePagination<StorageTagRecord> tableInstance={tableInstance} />
      </Stack>
    </Stack>
  );
}

export default StorageTagManagementTable;
