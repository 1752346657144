import { JsonObject, JsonProperty } from 'json2typescript';
import { EventEntryRecord } from '@arcanna/models/Event/EventEntryRecord';
import { ResponseCommon } from '@arcanna/models/utils';

@JsonObject('GetRandomEventResponse')
export class GetRandomEventResponse extends ResponseCommon {
  @JsonProperty('entries', [EventEntryRecord])
  entries: EventEntryRecord[];

  @JsonProperty('alert_id', String)
  alertId: string;

  @JsonProperty('raw_alert', String)
  rawAlert: string;
}

type TConstructorParams = {
  entries: GetRandomEventResponse['entries'];
  alertId: GetRandomEventResponse['alertId'];
  rawAlert: GetRandomEventResponse['rawAlert'];
};

export function constructGetRandomEventResponse({ entries, alertId, rawAlert }: TConstructorParams): GetRandomEventResponse {
  const instance = new GetRandomEventResponse();
  instance.entries = entries;
  instance.alertId = alertId;
  instance.rawAlert = rawAlert;
  return instance;
}
