import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '../common/DateConverter';
import { JobCloningStatus } from './JobCloningStatus';
import { JobLLMStatus } from './JobLLMStatus';

@JsonObject('JobStatusRecord')
export class JobStatusRecord {
  @JsonProperty('state', String)
  state?: string = undefined;

  @JsonProperty('reason', String)
  reason?: string;

  @JsonProperty('processed_documents_count', Number)
  processedDocumentsCount?: number = undefined;

  @JsonProperty('feedback_documents_count', Number)
  feedbackDocumentsCount?: number = undefined;

  @JsonProperty('last_processed_timestamp', DateConverter, false)
  last_processed_timestamp?: Date | null = undefined;

  @JsonProperty('last_feedback_timestamp', DateConverter, false)
  last_feedback_timestamp?: Date | null = undefined;

  @JsonProperty('last_train_start_timestamp', DateConverter, false)
  last_train_start_timestamp?: Date | null = undefined;

  @JsonProperty('last_train_finished_timestamp', DateConverter, false)
  last_train_finished_timestamp?: Date | null = undefined;

  @JsonProperty('has_features_selected', Boolean, false)
  has_features_selected?: boolean = undefined;

  @JsonProperty('retrain_state', String, true)
  retrain_state?: string = undefined;

  @JsonProperty('retrain_msg', String, true)
  retrain_msg?: string = undefined;

  @JsonProperty('events_model_update_in_progress', Boolean)
  events_model_update_in_progress?: boolean;

  @JsonProperty('buckets_model_update_in_progress', Boolean)
  buckets_model_update_in_progress?: boolean;

  @JsonProperty('buckets_model_update_percent', Number)
  buckets_model_update_percent?: number;

  @JsonProperty('buckets_update_in_progress', Boolean)
  buckets_update_in_progress?: boolean;

  @JsonProperty('buckets_update_percent', Number)
  buckets_update_percent?: number;

  @JsonProperty('rolling_status', String)
  rolling_status?: string;

  @JsonProperty('rollback_progress', Number)
  rollback_progress?: number;

  @JsonProperty('cloning_status', JobCloningStatus)
  cloningStatus?: JobCloningStatus;

  @JsonProperty('invalid', Boolean)
  invalid?: boolean = false;

  @JsonProperty('invalid_reason', String)
  invalid_reason?: string;

  @JsonProperty('invalid_timestamp', DateConverter, false)
  invalid_timestamp?: Date;

  @JsonProperty('llm_status', JobLLMStatus)
  llm_status: JobLLMStatus;

  @JsonProperty('supports_multi_input', Boolean)
  supports_multi_input: boolean;
}
