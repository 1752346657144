import { Box, Stack, Typography, useTheme } from '@mui/material';
import { TTopValue } from './FeedbackCoverage';
import { TruncatedText } from '@arcanna/components';
import { formatPercentage } from '../Overview.utils';

type TTopFiveHighProps = {
  values: TTopValue[];
};

export default function TopFiveHigh({ values }: TTopFiveHighProps) {
  const { palette } = useTheme();
  return (
    <Stack width={'311px'}>
      <Stack direction={'row'} marginBottom={'8px'} flexWrap={'wrap'}>
        <Typography fontWeight={500} color={palette.secondary[200]} variant={'subtitle1'}>
          Top&nbsp;
        </Typography>
        <Typography fontWeight={500} variant={'subtitle1'} color={palette.success[500]}>
          {values.length}&nbsp;
        </Typography>
        <Typography fontWeight={500} color={palette.secondary[200]} variant={'subtitle1'}>
          Most Common Values
        </Typography>
      </Stack>
      <Stack spacing={'16px'}>
        {values.map((value, index) => {
          return (
            <Stack key={'top-five-high-' + index}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TruncatedText text={value.fieldValue} variant={'subtitle2'} fontWeight={400} color={palette.secondary[100]} />
                <Typography variant={'subtitle2'} fontWeight={400} color={palette.secondary[100]}>
                  {formatPercentage(value.percent)}
                </Typography>
              </Stack>
              <Box height={'4px'} width={'100%'} sx={{ backgroundColor: palette.secondary[700], borderRadius: '8px' }}>
                <Box
                  height={'4px'}
                  width={value.percent + '%'}
                  sx={{ backgroundColor: palette.success[600], borderRadius: '8px' }}
                />
              </Box>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
