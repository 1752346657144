import { memo, ReactNode, useMemo } from 'react';
import { IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon, EIcon } from '@arcanna/generic';
import StyledGeneralCellWithFilters from './StyledGeneralCellWithFilters.styles';
import { getDisplayedValue, mapTableSizeToRowHeight } from './GeneralCellWithFilters.utils';
import { copyToClipboard } from '@arcanna/utils';
import {
  AdvancedFilters,
  TFilterItem,
  EFilterOperatorWithValue,
  EFilterOperatorWithoutValue,
  TruncatedText
} from '@arcanna/components';

export type TGeneralCellWithFiltersProps = {
  columnName: string;
  filterSource: string | undefined;
  value: string | undefined;
  activeFilters: TFilterItem[];
  addAdvancedFilter: (filterItem: TFilterItem) => void;
  tableSize: string;
  customRowSizeValue: number;
  children?: ReactNode;
  valueType: string | undefined | null;
};

function GeneralCellWithFilters({
  value,
  activeFilters,
  filterSource,
  columnName,
  addAdvancedFilter,
  tableSize,
  customRowSizeValue,
  children,
  valueType = 'text'
}: TGeneralCellWithFiltersProps) {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const displayedValue = useMemo(() => getDisplayedValue(value), [value]);
  const lineClamp = useMemo(() => mapTableSizeToRowHeight(tableSize, customRowSizeValue), [tableSize, customRowSizeValue]);

  const { getIsFilterPresent, getFilterToApply } = AdvancedFilters.useQuickFilters({ value, filterSource, columnName });

  const renderFilterButton = (
    operatorWithValue: EFilterOperatorWithValue,
    operatorWithoutValue: EFilterOperatorWithoutValue,
    iconName: EIcon,
    label: string
  ) => {
    const { filterToApply } = getFilterToApply(operatorWithValue, operatorWithoutValue);

    return filterToApply && !getIsFilterPresent(filterToApply, activeFilters) ? (
      <Tooltip arrow title={label} placement="top" enterDelay={1000} enterNextDelay={1000}>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            addAdvancedFilter(filterToApply);
          }}
          sx={{
            padding: '2px',
            '> .MuiSvgIcon-root': { fontSize: '14px' },
            '&:hover': {
              '> .MuiSvgIcon-root': {
                color: palette.info[400]
              }
            }
          }}
        >
          <Icon htmlColor={palette.secondary[500]} name={iconName} />
        </IconButton>
      </Tooltip>
    ) : (
      <></>
    );
  };

  const isArrayValue = useMemo(() => value?.startsWith('[') && value?.endsWith(']'), [value]);

  const renderFilters = () => (
    <Stack className="filters">
      {!(isArrayValue && valueType === 'boolean') && (
        <>
          {renderFilterButton(
            EFilterOperatorWithValue.is,
            EFilterOperatorWithoutValue.notExists,
            EIcon.PlusCircle,
            t('common:tableCellFilters_is')
          )}
          {renderFilterButton(
            EFilterOperatorWithValue.isNot,
            EFilterOperatorWithoutValue.exists,
            EIcon.MinusCircle,
            t('common:tableCellFilters_is_not')
          )}
        </>
      )}
      <Tooltip arrow title={t('common:copyValue')} placement="top" enterDelay={1000} enterNextDelay={1000}>
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            copyToClipboard(value, t);
          }}
          sx={{
            padding: '2px',
            '> .MuiSvgIcon-root': { fontSize: '14px' },
            '&:hover': {
              '> .MuiSvgIcon-root': {
                color: palette.info[400]
              }
            }
          }}
        >
          <Icon htmlColor={palette.secondary[500]} name={EIcon.Copy} />
        </IconButton>
      </Tooltip>
    </Stack>
  );

  return (
    <StyledGeneralCellWithFilters className="filters">
      {children ? (
        <Stack height="100%" width="100%" position="relative">
          {children}
          {renderFilters()}
        </Stack>
      ) : (
        <TruncatedText
          variant="subtitle2"
          text={displayedValue}
          lineClamp={lineClamp}
          position="relative"
          fontWeight={400}
          isTooltipHidden={typeof displayedValue !== 'string'}
        >
          {renderFilters()}
        </TruncatedText>
      )}
    </StyledGeneralCellWithFilters>
  );
}

export default memo(GeneralCellWithFilters);
