import { FlowsValidationResponse } from '@arcanna/models/Flows/FlowsValidationResponse';
import { OrderIntegrationsRequest } from '@arcanna/models/Flows/OrderIntegrationsRequest';
import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { flowsKeys } from 'src/_srcMUI/requests/Flows/keys';
import { showErrorNotification, showSuccessNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';

type TUseReorderFlowsProps = {
  jobId: number;
};

function useReorderFlows({ jobId }: TUseReorderFlowsProps) {
  // OTHER HOOKS
  const queryClient = useQueryClient();

  // SETUP
  const URL = useMemo(() => config.api.flows.order({ jobId }), [jobId]);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<FlowsValidationResponse>>(URL, body)
        .then((response) => Serializer.getInstance().deserializeCommonResponse(response, FlowsValidationResponse)),
    [URL]
  );

  // QUERY
  return useMutation(
    flowsKeys.order(),
    (payload: OrderIntegrationsRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);

      return axiosFunction(payloadSerialized);
    },
    {
      onSuccess: async () => {
        showSuccessNotification('Success', 'Flows reordered successfully.');
        await queryClient.invalidateQueries([config.api.jobInfo.replace(':id', `${jobId}`)]);
      },
      onError: (error: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
        if (error.data?.resource?.request?.reason) {
          showErrorNotification('Error', error.data.resource.request.reason);
        } else {
          showErrorNotification('Error', 'Failed to reorder flows.');
        }
      }
    }
  );
}

export default useReorderFlows;
