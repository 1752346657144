import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '../utils';

@JsonObject('SoftDeleteStorageTagRequest')
export class SoftDeleteStorageTagRequest extends RequestCommon {
  @JsonProperty('storage_tag', String)
  storageTag: string;
}

type TConstructorParams = {
  storageTag: SoftDeleteStorageTagRequest['storageTag'];
};

export function constructSoftDeleteStorageTagRequest({ storageTag }: TConstructorParams): SoftDeleteStorageTagRequest {
  const instance = new SoftDeleteStorageTagRequest();
  instance.storageTag = storageTag;

  return instance;
}
