import { PasswordTextField } from '@arcanna/components';
import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Label } from '@arcanna/generic';

type TRHFTextFieldProps = TextFieldProps & {
  name: string;
  label?: string;
  tooltipText?: string;
  tooltipImportantText?: string;
  dataTestId?: string;
  // By default, the validation is triggered on submit
  triggerValidationOnChange?: boolean;
  usePasswordEyeIcon?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  // Used in CE, for simple params, where we expect a string value, but some input fields must be number
  forceStringConversion?: boolean;
};

function RHFTextField({
  name,
  helperText,
  type,
  disabled,
  label,
  tooltipText,
  tooltipImportantText,
  required,
  dataTestId,
  triggerValidationOnChange,
  usePasswordEyeIcon,
  onChange,
  forceStringConversion,
  ...other
}: TRHFTextFieldProps) {
  const {
    control,
    trigger,
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const commonProps = {
          ...field,
          fullWidth: true,
          error: !!error,
          helperText: error ? error?.message : helperText,
          disabled: disabled || isSubmitting,
          label: label ? (
            <Label text={label} tooltipText={tooltipText} tooltipImportantText={tooltipImportantText} required={required} />
          ) : undefined,
          onWheel: (e: React.WheelEvent) => e.target instanceof HTMLElement && e.target.blur(),
          ...other,
          inputProps: {
            ...(other.inputProps ?? {}),
            'data-test-id': dataTestId
          }
        };

        const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            onChange(event);
          }

          const { value } = event.target;
          if (type === 'number' && !usePasswordEyeIcon) {
            if (forceStringConversion) {
              field.onChange(value.toString());
            } else {
              field.onChange(value ? Number(value) : null);
            }
          } else {
            field.onChange(value);
          }
          if (triggerValidationOnChange) {
            await trigger(name);
          }
        };

        const parsedValue = field.value;

        return usePasswordEyeIcon ? (
          <PasswordTextField {...commonProps} value={parsedValue} onChange={handleChange} />
        ) : (
          <TextField {...commonProps} type={type} value={parsedValue} onChange={handleChange} />
        );
      }}
    />
  );
}

export default RHFTextField;
