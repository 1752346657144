import { formatPercentage } from '../Overview.utils';

export const calculateMiddleDate = (dateStart: string, dateEnd: string) => {
  // Parse the input dates into Date objects
  const start = new Date(dateStart);
  const end = new Date(dateEnd);

  // Ensure both dates are valid
  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    throw new Error('Invalid date format');
  }

  // Calculate the midpoint timestamp
  const middleTimestamp = (start.getTime() + end.getTime()) / 2;

  // Convert the timestamp back to a Date object
  const middleDate = new Date(middleTimestamp);

  // Return the ISO string representation of the middle date
  return middleDate.toISOString();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const computePercentage = (payload: any, labelId: string) => {
  const currentLabelOccurence = payload[0].payload[labelId];
  let percentage = 0;
  if (currentLabelOccurence != 0) {
    percentage = (currentLabelOccurence / payload[0].payload.totalDecisionsPerDate) * 100;
  }
  return formatPercentage(percentage);
};
