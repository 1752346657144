import useECDIOverviewStore from '@arcanna/pages/JobOverview/components/ECDIOverview/stores/ECDIOverviewStore/useECDIOverviewStore';
import { constructGenericOverviewRequest } from '@arcanna/models/Overview';
import { useMemo } from 'react';
import { FilterRecord } from 'src/components/shared/models/filters/FilterRecord';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { Typography } from '@mui/material';

// eslint-disable-next-line
export default function useGetGenericOverviewRequestPayload() {
  const dateFrom = useECDIOverviewStore((state) => state.filtersState.startDate);
  const dateTo = useECDIOverviewStore((state) => state.filtersState.endDate);
  const filters = useECDIOverviewStore((state) => state.advancedFilters);
  const { jobId } = useJobIdFromUrl();

  const payload = useMemo(() => {
    return constructGenericOverviewRequest({
      jobId: +jobId,
      dateFrom: dateFrom?.toISOString() ?? null,
      dateTo: dateTo?.toISOString() ?? null,
      filters: filters as FilterRecord[]
    });
  }, [dateFrom, dateTo, filters, jobId]);

  return { payload };
}

export const renderNumericValue = (value: number, color: string, prefix?: string, suffix?: string) => (
  <Typography fontSize="28px" fontWeight={700} lineHeight="36px" letterSpacing="-0.14px" color={color}>
    {prefix}
    {value.toLocaleString()}
    {suffix}
  </Typography>
);

export const formatPercentage = (percentage: number, emptyPlaceholder?: string) => {
  if (emptyPlaceholder && typeof percentage !== 'number') {
    return emptyPlaceholder;
  }

  if (percentage < 1) {
    return '<1%';
  }

  return (
    percentage.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) + '%'
  );
};
