import { TFilterItem } from '@arcanna/components';
import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { getFilterValue } from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.utils';

@JsonObject('FilterRecordV2')
export class FilterRecordV2 {
  @JsonProperty('field', String)
  field: string;

  @JsonProperty('operator', String)
  operator: string;

  @JsonProperty('value', Any)
  value: string | string[] | null | undefined;

  @JsonProperty('status', String, true)
  status? = 'active';
}

type TConstructorParams = {
  field: FilterRecordV2['field'];
  operator: FilterRecordV2['operator'];
  value: FilterRecordV2['value'];
  status: FilterRecordV2['status'];
};

export function constructFilterRecordV2({ field, operator, value, status }: TConstructorParams): FilterRecordV2 {
  const instance = new FilterRecordV2();
  instance.field = field;
  instance.operator = operator;
  instance.value = value;
  instance.status = status;

  return instance;
}

type TFilterItemArrayToFilterRecordV2ArrayParams = {
  filters: TFilterItem[] | undefined;
};

export function filterItemArrayToFilterRecordV2Array({ filters }: TFilterItemArrayToFilterRecordV2ArrayParams): FilterRecordV2[] {
  return (
    filters?.map((filter) => {
      return constructFilterRecordV2({
        field: filter.field,
        operator: filter.operator,
        value: getFilterValue(filter) ?? '',
        status: filter.status
      });
    }) ?? []
  );
}

type TFilterRecordV2ArrayToFilterItemArrayParams = {
  filters: FilterRecordV2[] | undefined;
};

export function filterRecordV2ArrayToFilterItemArray({ filters }: TFilterRecordV2ArrayToFilterItemArrayParams): TFilterItem[] {
  return (
    filters?.map((filter) => {
      return {
        id: filter.field,
        field: filter.field,
        operator: filter.operator,
        value: filter.value,
        status: filter.status
      } as TFilterItem;
    }) ?? []
  );
}
