/* eslint-disable max-len */
function InfoCircleFilled() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.0102 9.5001L8.0051 9.5H8.375C8.71989 9.5 9 9.21989 9 8.875V4.625C9 4.28011 8.71989 4 8.375 4H7.625C7.28011 4 7 4.28011 7 4.625V8.875C7 9.21989 7.28011 9.5 7.625 9.5H7.9949L7.9898 9.5001C7.66272 9.50678 7.35129 9.6414 7.12234 9.87507C6.89339 10.1087 6.76515 10.4229 6.76515 10.75C6.76515 11.0771 6.89339 11.3913 7.12234 11.6249C7.35129 11.8586 7.66272 11.9932 7.9898 11.9999L8 12.0001L8.0102 11.9999C8.33728 11.9932 8.64871 11.8586 8.87766 11.6249C9.10661 11.3913 9.23485 11.0771 9.23485 10.75C9.23485 10.4229 9.10661 10.1087 8.87766 9.87507C8.64871 9.6414 8.33728 9.50678 8.0102 9.5001ZM1.5 8C1.5 4.41052 4.41052 1.5 8 1.5C11.5895 1.5 14.5 4.41052 14.5 8C14.5 11.5895 11.5895 14.5 8 14.5C4.41052 14.5 1.5 11.5895 1.5 8Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
}

export default InfoCircleFilled;
