import { FormProvider, RHFSubmitButton, RHFTextField } from '@arcanna/forms';
import { EIcon, Icon, Modal } from '@arcanna/generic';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { constructAIModelExportRequest, constructAIModelRecomputeMetricsRequest } from '@arcanna/models/AIModels';
import { useExportAIModel, useRecomputeAIModelMetrics, useRenameAIModel } from '@arcanna/requests/AIModels';
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useForm } from 'react-hook-form';
import AlertDialog from 'src/_srcMUI/shared/components/AlertDialog';
import { DeleteButton } from 'src/_srcMUI/shared/components/Buttons';
import { isDecisionIntelligenceCategoryOnly } from 'src/components/pages/Main/Jobs/helper';
import { DeleteModelRequest } from 'src/components/shared/models/job/DeleteModelRequest';
import { JobRollbackToModel } from 'src/components/shared/models/job/JobRollbackToModel';
import { ModelHistory } from 'src/components/shared/models/retrain-history/JobAllRetrainHistoryResponse';
import { useJobInfo } from 'src/data-access';
import useDeleteModel from 'src/data-access/model-history/useDeleteModel';
import useDownloadKnowledgeBase from 'src/data-access/model-history/useDownloadKnowledgeBase';
import useRollbackToModel from 'src/data-access/model-history/useRollbackToModel';
import { useTranslation } from 'react-i18next';
import { constructAIModelRenameRequest } from '@arcanna/models/AIModels/AIModelRenameRequest';
import { Button } from '@mui/material';
import ModelHistoryPerformanceDrawer from '../ModelHistoryPerformanceDrawer';

type TModelHistoryActionColumnProps = {
  AIModel: ModelHistory;
  currentAIModelId: string | null;
};

function ModelHistoryActionColumn({ AIModel, currentAIModelId }: TModelHistoryActionColumnProps) {
  const { jobId } = useJobIdFromUrl();
  const { t } = useTranslation(['common', 'forms']);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isConfirmUseInProductionDialogOpen, setIsConfirmUseInProductionDialogOpen] = useState<boolean>(false);
  const { data: jobInfo } = useJobInfo(jobId);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false);
  const [isPerformanceDrawerOpen, setIsPerformanceDrawerOpen] = useState<boolean>(false);

  const { mutate: renameAIRename } = useRenameAIModel({ jobId });
  const { mutateAsync: handleModelDelete } = useDeleteModel(jobId.toString());
  const { mutateAsync: mutateRollbackModel, isLoading: isRollbackLoading } = useRollbackToModel(jobId.toString());
  const { mutate: exportAIModel } = useExportAIModel({ jobId });

  const { mutate: recomputeAIModelMetrics } = useRecomputeAIModelMetrics({ jobId });

  const { downloadKnowledgebase } = useDownloadKnowledgeBase(jobId.toString());

  const isDIJob = useMemo(
    () => isDecisionIntelligenceCategoryOnly(jobInfo?.info?.jobDetails?.category_id),
    [jobInfo?.info?.jobDetails?.category_id]
  );
  type TFormValues = {
    modelId: string;
    friendlyName: string;
  };

  const methods = useForm<TFormValues>({
    resolver: joiResolver(
      Joi.object({
        modelId: Joi.string().required(),
        friendlyName: Joi.string()
          .required()
          .min(1)
          .trim()
          .messages({
            'any.required': t('forms:validation.fieldRequired'),
            'string.empty': t('forms:validation.fieldRequired')
          })
      })
    ),
    defaultValues: {
      modelId: AIModel.modelId ?? '',
      friendlyName: ''
    }
  });

  const deleteModel = useCallback(() => {
    AIModel.modelId && handleModelDelete(new DeleteModelRequest(AIModel.modelId));
  }, [AIModel.modelId, handleModelDelete]);

  const downloadModelKnowledgebase = useCallback(() => {
    AIModel.modelId && downloadKnowledgebase(AIModel.modelId);
  }, [AIModel.modelId, downloadKnowledgebase]);

  const isMenuOpen = Boolean(menuAnchorEl);
  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setMenuAnchorEl(null);

  const isAIModelCurrentlyInUse = useMemo(() => AIModel.modelId === currentAIModelId, [AIModel.modelId, currentAIModelId]);

  const isAIModelRecomputing = useMemo(() => AIModel.newModelMetrics.recomputeMetricsFlag, [AIModel]);

  const handleCloseRenameModal = useCallback(() => {
    setIsRenameModalOpen(false);
    methods.reset();
  }, [methods]);

  const handleRenameSubmit = useCallback(
    async (formValues: TFormValues) => {
      const payload = constructAIModelRenameRequest({ ...formValues });
      renameAIRename(payload);
      handleCloseRenameModal();
    },
    [handleCloseRenameModal, renameAIRename]
  );

  const renderMenuButton = useCallback(
    (iconName: EIcon, label: string, onMenuItemClick: (event: MouseEvent<HTMLLIElement>) => void, testId?: string) => {
      return (
        <MenuItem onClick={onMenuItemClick} className="MuiMenuItem-root--smaller" data-test-id={testId}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={4} sx={{ padding: 0 }}>
            <Typography variant="subtitle2">{label}</Typography>
            <Icon name={iconName} fontSize="small" />
          </Stack>
        </MenuItem>
      );
    },
    []
  );

  const handleSaveRollbackModal = useCallback(() => {
    const jobRollbackModel = new JobRollbackToModel(AIModel.modelId ?? '');

    mutateRollbackModel(jobRollbackModel, {
      onSuccess: () => {
        setIsConfirmUseInProductionDialogOpen(false);
      }
    });
  }, [AIModel.modelId, mutateRollbackModel]);

  const handleAIModelExport = useCallback(() => {
    exportAIModel(constructAIModelExportRequest({ modelId: AIModel.versionId ?? '' }));
  }, [AIModel.versionId, exportAIModel]);

  const handleAIModelRecomputeMetrics = useCallback(() => {
    recomputeAIModelMetrics(constructAIModelRecomputeMetricsRequest({ modelId: AIModel.modelId ?? '' }));
  }, [AIModel.modelId, recomputeAIModelMetrics]);

  if (!AIModel.isRevertible) {
    return <></>;
  }

  return (
    <>
      <IconButton
        type="button"
        size="small"
        variant="text"
        color="secondary"
        onClick={handleOpenMenu}
        data-test-id="dropdown-menu"
        sx={{
          fontSize: '16px'
        }}
      >
        <Icon name={EIcon.ThreeDots} fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {!isAIModelRecomputing && renderMenuButton(EIcon.Spark, 'Recompute Metrics', handleAIModelRecomputeMetrics)}
        {renderMenuButton(EIcon.Edit16, t('aiModel.rename'), () => setIsRenameModalOpen(true))}
        {!isAIModelCurrentlyInUse &&
          renderMenuButton(EIcon.Rocket, 'Use in Production', () => setIsConfirmUseInProductionDialogOpen(true))}
        {renderMenuButton(EIcon.Share, 'Export AI Model', handleAIModelExport)}
        {renderMenuButton(EIcon.Expand01, 'View Performance', () => {
          handleCloseMenu();
          setIsPerformanceDrawerOpen(true);
        })}
        {isDIJob && renderMenuButton(EIcon.Download01, 'Download Knowledge Base', downloadModelKnowledgebase)}
        {!isAIModelCurrentlyInUse && (
          <DeleteButton
            handleDelete={deleteModel}
            renderCustomButton={(handleModalOpen: () => void) => renderMenuButton(EIcon.Delete, 'Delete', handleModalOpen)}
            modalBody={'Are you sure you want to delete this AI model?'}
          />
        )}
      </Menu>
      <AlertDialog
        open={isConfirmUseInProductionDialogOpen}
        handleSubmit={handleSaveRollbackModal}
        isLoading={isRollbackLoading}
        onClose={() => setIsConfirmUseInProductionDialogOpen(false)}
        modalBody={
          <Stack direction="column">
            <Typography variant="body2" color="inherit">
              Switching to another model version will result in a series of changes
            </Typography>
            <Typography variant="body2" marginTop={1} color="inherit">
              During this update:
            </Typography>
            <ul>
              <li>The job will be disabled</li>
              <li>Existing feedback will be updated</li>
              <li>You will not be able to perform any actions on the job</li>
            </ul>
            <Typography variant="body2" color="inherit">
              Do you wish to continue?
            </Typography>
          </Stack>
        }
        mode="confirm"
      />
      <Modal open={isRenameModalOpen} width={500} onClose={handleCloseRenameModal} title={t('aiModel.rename')}>
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleRenameSubmit)}>
          <Stack gap={2} alignItems="flex-end">
            <RHFTextField fullWidth required label={t('aiModel.name')} variant="outlined" name="friendlyName" />
            <Stack direction="row" gap={2} marginTop={5} alignItems="flex-end">
              <Button variant="outlined" color="secondary" onClick={handleCloseRenameModal}>
                {t('cancel')}
              </Button>
              <RHFSubmitButton variant="contained" color="primary" text={t('rename')} />
            </Stack>
          </Stack>
        </FormProvider>
      </Modal>
      <ModelHistoryPerformanceDrawer
        AIModel={AIModel}
        isOpen={isPerformanceDrawerOpen}
        handleClose={() => setIsPerformanceDrawerOpen(false)}
      />
    </>
  );
}

export default ModelHistoryActionColumn;
