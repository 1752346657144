import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('GetExplorerFieldsRequest')
export class GetExplorerFieldsRequest extends RequestCommon {
  @JsonProperty('job_id', Number)
  jobId: number;

  @JsonProperty('event_id', String)
  eventId: string | undefined;
}

type TConstructorParams = {
  jobId: GetExplorerFieldsRequest['jobId'];
  eventId: GetExplorerFieldsRequest['eventId'];
};

export function constructGetExplorerFieldsRequest({ jobId, eventId }: TConstructorParams): GetExplorerFieldsRequest {
  const instance = new GetExplorerFieldsRequest();
  instance.jobId = jobId;
  instance.eventId = eventId;

  return instance;
}
