import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from 'src/_srcMUI/models/utils';
import { IntegrationParamsComplex, IntegrationParamsSimple } from './ContextEnrichmentRecord';

@JsonObject('ContextEnrichmentRunRequest')
export class ContextEnrichmentRunRequest extends RequestCommon {
  @JsonProperty('integration_id', String)
  integrationId: string;

  @JsonProperty('body_value', String)
  bodyValue: string;

  @JsonProperty('body_type', String)
  bodyType: string;

  @JsonProperty('event_id', String)
  eventId: string;

  @JsonProperty('integration_params_simple', [IntegrationParamsSimple])
  integrationParamsSimple: IntegrationParamsSimple[];

  @JsonProperty('integration_params_complex', [IntegrationParamsComplex])
  integrationParamsComplex: IntegrationParamsComplex[];
}

type TConstructorParams = {
  integrationId: ContextEnrichmentRunRequest['integrationId'];
  bodyValue: ContextEnrichmentRunRequest['bodyValue'];
  bodyType: ContextEnrichmentRunRequest['bodyType'];
  eventId: ContextEnrichmentRunRequest['eventId'];
  integrationParamsSimple: ContextEnrichmentRunRequest['integrationParamsSimple'];
  integrationParamsComplex: ContextEnrichmentRunRequest['integrationParamsComplex'];
};

export function constructContextEnrichmentRunRequest({
  integrationId,
  bodyValue,
  bodyType,
  eventId,
  integrationParamsSimple,
  integrationParamsComplex
}: TConstructorParams) {
  const instance = new ContextEnrichmentRunRequest();
  instance.integrationId = integrationId;
  instance.bodyValue = bodyValue;
  instance.bodyType = bodyType;
  instance.eventId = eventId;
  instance.integrationParamsSimple = integrationParamsSimple;
  instance.integrationParamsComplex = integrationParamsComplex;

  return instance;
}
