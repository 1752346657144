import { useJobIdFromUrl } from '@arcanna/hooks';
import { Stack, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import CustomLabel from 'src/_srcMUI/shared/components/CustomLabel';
import { getCustomLabelsFromJobs } from 'src/components/pages/Main/Jobs/Feedback/Flow/SelectFeedback';
import { ModelHistory } from 'src/components/shared/models/retrain-history/JobAllRetrainHistoryResponse';
import { useJobInfo } from 'src/data-access';
import StyledModelHistoryConfusionMatrix from './StyledModelHistoryConfusionMatrix.styles';
import { EIcon, Icon } from '@arcanna/generic';
import { useTranslation } from 'react-i18next';

type TModelHistoryConfusionMatrixProps = {
  AIModel: ModelHistory;
};

function ModelHistoryConfusionMatrix({ AIModel }: TModelHistoryConfusionMatrixProps) {
  const { jobId } = useJobIdFromUrl();
  const theme = useTheme();
  const { t } = useTranslation(['overview']);

  const { data: jobInfoData } = useJobInfo(jobId);
  const customLabelInfos = useMemo(() => (jobInfoData ? getCustomLabelsFromJobs([jobInfoData]) : []), [jobInfoData]);

  const customLabels = useMemo(() => {
    return AIModel.newModelMetrics.modelMetrics.classes.map((cl) => {
      return customLabelInfos.find((customLabel) => customLabel.id === cl) ?? { id: cl, hexColor: '#8592a2', name: cl };
    });
  }, [AIModel.newModelMetrics.modelMetrics.classes, customLabelInfos]);

  const confusionMatrixValues = useMemo(
    () => AIModel.newModelMetrics.modelMetrics.confusionMatrix,
    [AIModel.newModelMetrics.modelMetrics.confusionMatrix]
  );

  return (
    <Stack gap={2}>
      <Stack gap={1} direction="row" alignItems="center">
        <Typography variant="body2">{t('overview:confusionMatrix')}</Typography>
        <Tooltip placement="top" arrow title={t('overview:confusionMatrixTooltip')}>
          <Stack>
            <Icon htmlColor={theme.palette.secondary[300]} fontSize="small" name={EIcon.InfoCircle} />
          </Stack>
        </Tooltip>
      </Stack>

      <Stack sx={{ overflow: 'scroll', height: '415px' }}>
        <StyledModelHistoryConfusionMatrix>
          <TableHead>
            <TableRow>
              <TableCell>
                <Stack alignItems="center" justifyContent="center">
                  <Stack gap={0.5} height="64px" width="125px" position="relative">
                    <Typography sx={{ position: 'absolute', top: '0', right: '0' }} variant="caption">
                      Predicted
                    </Typography>
                    <Typography sx={{ position: 'absolute', bottom: '0', left: '0' }} variant="caption">
                      Actual
                    </Typography>
                    <Stack
                      component="span"
                      sx={{
                        height: '1px',
                        width: '150px',
                        background: theme.palette.secondary[600],
                        position: 'absolute',
                        left: '-15px',
                        top: '30px',
                        rotate: '25deg'
                      }}
                    />
                  </Stack>
                </Stack>
              </TableCell>
              {customLabels.map((customLabel) => (
                <TableCell key={customLabel.id}>
                  <CustomLabel disabledTooltip={false} width={120} name={customLabel.name} hexColor={customLabel.hexColor} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customLabels.map((customLabel, rowIdx) => (
              <TableRow key={customLabel.id}>
                <TableCell width="150px">
                  <CustomLabel disabledTooltip={false} width={120} name={customLabel.name} hexColor={customLabel.hexColor} />
                </TableCell>
                {customLabels.map((customLabel, colIdx) => (
                  <TableCell
                    sx={{ color: rowIdx === colIdx ? `${theme.palette.success.main} !important` : '' }}
                    key={customLabel.id}
                  >
                    {confusionMatrixValues?.[rowIdx]?.[colIdx] ?? 0}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledModelHistoryConfusionMatrix>
      </Stack>
    </Stack>
  );
}

export default ModelHistoryConfusionMatrix;
