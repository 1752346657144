import { eventKeys } from '@arcanna/requests/Event/keys';
import { Box, Divider, Stack, Tab, Tabs } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFetching } from 'react-query';
import { config } from '../../../../../../config';
import { EEventSampleDrawerTabs } from '../../EventSampleDrawer.constants';
import { getEventSampleDrawerBodyTabs } from './EventSampleDrawerBody.utils';
import { GetRandomEventResponse } from '@arcanna/models/Event';
import { TFilterItem, TUseAdvancedFilters } from '@arcanna/components';

type TEventSampleDrawerProps = {
  jobId: number;
  filters: TFilterItem[];
  setEventSample: (event: GetRandomEventResponse) => void;
  eventSample: GetRandomEventResponse | undefined;
  advancedFilters: TUseAdvancedFilters;
  getFilterSourceByName: (name: string) => string | undefined;
};

function EventSampleDrawerBody({
  jobId,
  filters,
  setEventSample,
  eventSample,
  advancedFilters,
  getFilterSourceByName
}: TEventSampleDrawerProps) {
  const { t } = useTranslation(['codeBlock']);

  const [activeTabValue, setActiveTabValue] = useState<EEventSampleDrawerTabs>(EEventSampleDrawerTabs.Table);

  const isGetRandomEventLoading = useIsFetching(eventKeys.randoms()) !== 0;
  const isGetEventByIdLoading = useIsFetching(config.api.eventInfo.replace(':eventId', `${eventSample?.alertId}`)) !== 0;

  const isLoading = useMemo(
    () => isGetRandomEventLoading || isGetEventByIdLoading,
    [isGetRandomEventLoading, isGetEventByIdLoading]
  );

  const tabs = useMemo(() => getEventSampleDrawerBodyTabs(t), [t]);
  const Component = useMemo(() => tabs.find((tab) => tab.value === activeTabValue)?.component, [tabs, activeTabValue]);

  return (
    <Stack gap="12px">
      <Stack direction="row">
        <Tabs value={activeTabValue} onChange={(_, value) => setActiveTabValue(value)}>
          {tabs.map((tab) => (
            <Tab key={tab.value} disableRipple label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <Box width="100%" mt="auto">
          <Divider sx={{ width: '100%', mt: 'auto' }} />
        </Box>
      </Stack>
      {Component && (
        <Component
          jobId={jobId}
          isLoading={isLoading}
          filters={filters}
          advancedFilters={advancedFilters}
          getFilterSourceByName={getFilterSourceByName}
          eventSample={eventSample}
          setEventSample={setEventSample}
        />
      )}
    </Stack>
  );
}

export default EventSampleDrawerBody;
