import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from 'src/_srcMUI/models/utils';

@JsonObject('ContextEnrichmentRunResponse')
export class ContextEnrichmentRunResponse extends ResponseCommon {
  @JsonProperty('status', String)
  status?: string | undefined;

  @JsonProperty('bytes_len', Number)
  bytesLen?: number | undefined;

  @JsonProperty('body', Any)
  // eslint-disable-next-line
  body?: any = undefined;

  @JsonProperty('headers', Any)
  // eslint-disable-next-line
  headers?: any = undefined;
}
