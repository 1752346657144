import { EIcon, Icon } from '@arcanna/generic';
import { Stack, Tooltip, TooltipProps, Typography, TypographyProps, useTheme } from '@mui/material';

type TLabelProps = {
  text: string;
  textProps?: TypographyProps;
  tooltipText?: string;
  tooltipImportantText?: string;
  tooltipPlacement?: TooltipProps['placement'];
  required?: boolean;
  marginBottom?: string | number;
};

function Label({ text, textProps, tooltipText, tooltipImportantText, tooltipPlacement, required, marginBottom }: TLabelProps) {
  const { palette } = useTheme();
  const normalTooltip = tooltipText !== undefined && tooltipText !== '';
  const labelText = normalTooltip ? tooltipText : tooltipImportantText;
  const tooltipColor = normalTooltip ? palette.secondary[400] : palette.info[600];
  const iconName = normalTooltip ? EIcon.InfoCircle : EIcon.InfoCircleFilled;

  return (
    <Stack spacing="8px" direction="row" alignItems="center" sx={{ pointerEvents: 'all', mb: marginBottom }}>
      <Stack spacing="2px" direction="row">
        <Typography variant="subtitle1" {...textProps}>
          {text}
        </Typography>
        {required && (
          <Typography component="span" variant="subtitle1" color={palette.error.main}>
            *
          </Typography>
        )}
      </Stack>
      {labelText && (
        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: labelText }}></div>} placement={tooltipPlacement} arrow>
          <Stack component="span" direction="row" alignItems="center" justifyContent="center" color={tooltipColor}>
            <Icon name={iconName} fontSize="small" />
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
}

export default Label;
