import UsersPerformance from './UsersPerformance/UsersPerformance';

export type UserPerformance = {
  username: string;
  feedbackGiven: number;
  consensusPercent: number;
};

type UsersPerformance = {
  entries: UserPerformance[];
  isLoading: boolean;
};

type DecisionDetailsProps = {
  usersPerformance: UsersPerformance;
};

function DecisionsDetails({ usersPerformance }: DecisionDetailsProps) {
  return <UsersPerformance entries={usersPerformance.entries} isLoading={usersPerformance.isLoading} />;
}

export default DecisionsDetails;
