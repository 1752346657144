import { Box, Button, InputAdornment, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import GenericOverviewCard from '../GenericOverviewCard';
import { renderNumericValue } from '../Overview.utils';
import { EIcon, Icon } from '@arcanna/generic';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, RHFSubmitButton, RHFTextField } from '@arcanna/forms';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

export type TArcannaValuesFormValues = {
  hourlyRate: number;
  investigationTime: number;
  hourlyRateCurrency: string;
};

export type TArcannaValueProps = {
  hoursSaved?: number;
  costSaved?: number;
  initialInvestigationTime?: number;
  initialHourlyRate?: number;
  onSave?: (values: TArcannaValuesFormValues) => Promise<void>;
  isLoading: boolean;
};

function ArcannaValue({
  hoursSaved,
  costSaved,
  initialInvestigationTime,
  initialHourlyRate,
  onSave,
  isLoading
}: TArcannaValueProps) {
  const { palette } = useTheme();
  const [isSavedDisabled, setIsSavedDisabled] = useState(!(initialHourlyRate && initialInvestigationTime));
  const [isEdit, setIsEdit] = useState(false);

  const isMoneyModeActive = useMemo(() => typeof onSave === 'function', [onSave]);

  const onFormSave = async (values: TArcannaValuesFormValues) => {
    await onSave?.(values);
    setIsEdit(false);
  };

  const defaultValues = useMemo(
    () => ({
      hourlyRate: initialHourlyRate ?? 30,
      investigationTime: initialInvestigationTime ?? 7,
      hourlyRateCurrency: 'dollar'
    }),
    [initialHourlyRate, initialInvestigationTime]
  );

  const formSchema = useMemo(() => {
    return Joi.object({
      hourlyRate: Joi.number().required().greater(0).messages({
        'number.base': 'The field must be a number.',
        'number.greater': 'The field must be greater than 0.',
        'any.required': 'The field is required and cannot be empty.'
      }),
      investigationTime: Joi.number().required().greater(0).messages({
        'number.base': 'The field must be a number.',
        'number.greater': 'The field must be greater than 0.',
        'any.required': 'The field is required and cannot be empty.'
      }),
      hourlyRateCurrency: Joi.string()
    });
  }, []);

  const methods = useForm<TArcannaValuesFormValues>({
    defaultValues: defaultValues,
    resolver: joiResolver(formSchema)
  });

  const hourlyRate = methods.watch('hourlyRate');
  const investigationTime = methods.watch('investigationTime');

  useEffect(() => {
    if (hourlyRate === null || investigationTime === null) {
      if (!isSavedDisabled) setIsSavedDisabled(true);
      return;
    }
    if (hourlyRate < 0 || investigationTime < 0) {
      if (!isSavedDisabled) setIsSavedDisabled(true);
      return;
    }

    setIsSavedDisabled(false);
  }, [hourlyRate, investigationTime, isSavedDisabled]);

  return (
    <GenericOverviewCard
      title="Arcanna's Value"
      position={'relative'}
      infoTooltipText={
        isMoneyModeActive
          ? 'This metric shows how much time and money Arcanna has saved you by making correct decisions on events.'
          : 'This metric shows how much time Arcanna has saved you by making correct decisions on events.'
      }
      minHeight={'128px'}
      isLoading={isLoading}
      actions={
        <Stack
          sx={{
            cursor: 'pointer',
            fontSize: '18px',
            color: palette.secondary[300],
            '&:hover': { color: palette.secondary[100] }
          }}
        >
          {!isEdit && isMoneyModeActive && (
            <Tooltip title={"Edit Arcanna's Value"}>
              <Stack>
                <Icon
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  sx={{ cursor: 'pointer' }}
                  name={EIcon.Edit16}
                  fontSize={'inherit'}
                  htmlColor={'inherit'}
                />
              </Stack>
            </Tooltip>
          )}
        </Stack>
      }
    >
      {isEdit ? (
        <Stack direction={'row'} spacing={'12px'}>
          <FormProvider<TArcannaValuesFormValues> methods={methods} onSubmit={methods.handleSubmit(onFormSave)}>
            <Stack direction={'row'} spacing={'12px'}>
              <Box sx={{ width: '160px' }}>
                <RHFTextField
                  InputProps={{
                    sx: {
                      height: '36px'
                    }
                  }}
                  label={'Investigation Time'}
                  tooltipText={'Time spent (in minutes) on investigation without Arcanna'}
                  type={'number'}
                  name={'investigationTime'}
                />
              </Box>
              <Box sx={{ width: '100px' }}>
                <RHFTextField
                  InputProps={{
                    sx: {
                      height: '36px'
                    },
                    endAdornment: (
                      <InputAdornment sx={{ color: palette.secondary[100], fontSize: '14px' }} position={'end'}>
                        <Icon name={EIcon.Dollar} fontSize={'inherit'} />
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    sx: { color: palette.secondary[200] }
                  }}
                  label={'Hourly Rate'}
                  type={'number'}
                  name={'hourlyRate'}
                />
              </Box>
              <Stack direction="row" gap={1.5} paddingTop="26px">
                <RHFSubmitButton size={'small'} disabled={isSavedDisabled} text={'Save'} />
                <Button
                  onClick={() => {
                    setIsEdit(false);
                  }}
                  variant={'contained'}
                  color={'secondary'}
                  size={'small'}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
          <Stack width={'140px'}>
            <Typography variant="subtitle1" color={palette.secondary[200]}>
              Work Hours Saved
            </Typography>
            {renderNumericValue(hoursSaved ?? 0, palette.secondary[100])}
          </Stack>
          {isMoneyModeActive && (
            <Stack>
              <Typography variant="subtitle1" color={palette.secondary[200]}>
                Costs reduced
              </Typography>
              {renderNumericValue(costSaved ?? 0, palette.primary[500], '$')}
            </Stack>
          )}
        </Stack>
      )}
    </GenericOverviewCard>
  );
}

export default ArcannaValue;
