/* eslint-disable max-len */

function DottedCircleFullChecked() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#10B981" stroke="#2A3247" />
      <path
        d="M10.2168 15.8281C10.4316 16.043 10.7969 16.043 11.0117 15.8281L17.3281 9.51172C17.543 9.29688 17.543 8.93164 17.3281 8.7168L16.5547 7.94336C16.3398 7.72852 15.9961 7.72852 15.7812 7.94336L10.625 13.0996L8.19727 10.6934C7.98242 10.4785 7.63867 10.4785 7.42383 10.6934L6.65039 11.4668C6.43555 11.6816 6.43555 12.0469 6.65039 12.2617L10.2168 15.8281Z"
        fill="#1B2236"
      />
    </svg>
  );
}

export default DottedCircleFullChecked;
