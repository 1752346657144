import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '../utils';

@JsonObject('AIModelRenameRequest')
export class AIModelRenameRequest extends RequestCommon {
  @JsonProperty('model_id', String)
  modelId: string;

  @JsonProperty('friendly_name', String)
  friendlyName: string;
}

type TConstructorParams = {
  modelId: AIModelRenameRequest['modelId'];
  friendlyName: AIModelRenameRequest['friendlyName'];
};

export function constructAIModelRenameRequest({ modelId, friendlyName }: TConstructorParams): AIModelRenameRequest {
  const instance = new AIModelRenameRequest();
  instance.modelId = modelId;
  instance.friendlyName = friendlyName;

  return instance;
}
