/* eslint-disable max-len */
function SearchColored() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="83" height="80" viewBox="0 0 83 80" fill="none">
      <path
        d="M27.3182 48.5767C40.247 48.5767 50.728 38.5404 50.728 26.16C50.728 13.7795 40.247 3.74316 27.3182 3.74316C14.3893 3.74316 3.90845 13.7795 3.90845 26.16C3.90845 38.5404 14.3893 48.5767 27.3182 48.5767Z"
        fill="#1B2236"
      />
      <path
        opacity="0.81"
        d="M29.7704 17.4761C32.4983 16.887 34.2111 14.2917 33.5958 11.6795C32.9806 9.06723 30.2704 7.42716 27.5424 8.01629C24.8145 8.60542 23.1018 11.2007 23.717 13.8129C24.3322 16.4252 27.0424 18.0652 29.7704 17.4761Z"
        fill="#8592A2"
      />
      <path
        opacity="0.58"
        d="M27.3183 49.2191C14.0398 49.2191 3.23706 38.8743 3.23706 26.1594C3.23706 13.4445 14.0402 3.09961 27.3183 3.09961C40.5964 3.09961 51.3995 13.4442 51.3995 26.1594C51.3995 38.8746 40.5967 49.2191 27.3183 49.2191ZM27.3183 4.38557C14.7801 4.38557 4.57998 14.1534 4.57998 26.1594C4.57998 38.1657 14.7804 47.9332 27.3183 47.9332C39.8561 47.9332 50.0566 38.1654 50.0566 26.1594C50.0566 14.153 39.8561 4.38557 27.3183 4.38557Z"
        fill="#1E3A8A"
      />
      <path
        d="M64.7854 57.978L49.3606 43.51C49.0571 43.2252 48.709 43.0114 48.339 42.8641C52.2704 38.3328 54.636 32.5109 54.636 26.1592C54.636 11.7118 42.4053 0 27.318 0C12.2306 0 0 11.7118 0 26.1592C0 40.6066 12.2306 52.3185 27.318 52.3185C33.6327 52.3185 39.4422 50.2613 44.0685 46.8162C44.2297 47.2267 44.4815 47.6116 44.83 47.9388L60.2547 62.4068C60.8846 62.9977 61.7024 63.2922 62.5196 63.2922C63.3592 63.2922 64.1982 62.9813 64.8321 62.3615C66.0837 61.1385 66.0625 59.1759 64.7854 57.978ZM3.90856 26.1596C3.90856 13.779 14.3894 3.74278 27.3183 3.74278C40.2473 3.74278 50.7281 13.779 50.7281 26.1596C50.7281 38.5401 40.2473 48.5764 27.3183 48.5764C14.3894 48.5764 3.90856 38.5398 3.90856 26.1596Z"
        fill="#1D4ED8"
      />
      <path
        d="M81.2795 70.4489L58.8363 48.9577C58.2773 48.4224 49.9919 56.3127 50.5737 56.8698L73.0169 78.3611C75.2985 80.5459 78.9979 80.5459 81.2795 78.3611C83.5611 76.1762 83.5611 72.6337 81.2795 70.4489ZM79.5858 74.093C79.5858 75.1786 78.6669 76.0586 77.5331 76.0586C76.3993 76.0586 75.4801 75.1786 75.4801 74.093C75.4801 73.0073 76.3993 72.1274 77.5331 72.1274C78.6665 72.1271 79.5858 73.0073 79.5858 74.093Z"
        fill="#60A5FA"
      />
      <g opacity="0.1">
        <path
          d="M78.6658 75.7313C78.3412 75.9377 77.9517 76.0586 77.5327 76.0586C76.399 76.0586 75.4797 75.1787 75.4797 74.093C75.4797 73.6674 75.6224 73.2745 75.8628 72.9527C70.7402 67.8822 63.1783 60.4294 58.6177 55.9421C56.2619 53.6241 54.7071 52.0977 54.7041 52.1006C52.4833 54.1816 50.2631 56.5725 50.5736 56.8702L73.0168 78.3614C75.2984 80.5463 78.9978 80.5463 81.2794 78.3614C81.2912 78.3499 80.2874 77.3426 78.6658 75.7313Z"
          fill="#C9D1D9"
        />
      </g>
      <path
        d="M60.0667 50.3208L51.9659 58.0781C51.5956 58.4327 50.9956 58.4327 50.6253 58.0781L49.1642 56.6789C48.7939 56.3243 48.7939 55.7498 49.1642 55.3952L57.265 47.638C57.6353 47.2834 58.2353 47.2834 58.6056 47.638L60.0667 49.0371C60.437 49.3918 60.437 49.9666 60.0667 50.3208Z"
        fill="#C9D1D9"
      />
    </svg>
  );
}

export default SearchColored;
