import { create } from 'zustand';
import { TUseGetEventGroupsProps } from './useGetEventGroups';

type TEventGroupingQueryCachingBaseParams = Omit<TUseGetEventGroupsProps, 'jobIds' | 'pageSize' | 'pageCount'>;

export type TEventGroupingQueryCachingStore = {
  queryCachingIdsPerJobs: Record<string, TEventGroupingQueryCachingBaseParams & { queryCachingId: string | undefined }>;
  setQueryCachingIdPerJobs: (
    jobIds: number[],
    params: TEventGroupingQueryCachingBaseParams,
    queryCachingId: string | undefined
  ) => void;
};

export const eventGroupingQueryCachingStore = create<TEventGroupingQueryCachingStore>()((set) => ({
  queryCachingIdsPerJobs: {},
  setQueryCachingIdPerJobs: (jobIds, params, queryCachingId) =>
    set((state) => {
      const jobsKey = jobIds.sort().join(',');

      return {
        queryCachingIdsPerJobs: {
          ...state.queryCachingIdsPerJobs,
          [jobsKey]: {
            ...params,
            queryCachingId
          }
        }
      };
    })
}));
