import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EnvVariableRecord')
export class EnvVariableRecord {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('value', String)
  value: string;

  @JsonProperty('is_secret', Boolean)
  isSecret: boolean;

  @JsonProperty('should_encrypt', Boolean, true)
  shouldEncrypt = false;
}
