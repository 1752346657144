import { TFilterItem } from '@arcanna/components';
import { constructGetRandomEventRequest } from '@arcanna/models/Event/GetRandomEventRequest';
import { ResourceWrapper } from '@arcanna/models/utils';
import { filterItemArrayToFilterRecordV2Array } from '@arcanna/models/utils/FilterRecord';
import { getHasNoDocumentsProcessed, Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { GetRandomEventResponse } from '@arcanna/models/Event';
import { useJobInfo } from 'src/data-access';
import { eventKeys } from './keys';
import { showErrorNotification, showWarningNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';

type TUseGetRandomEventProps = {
  jobId: number;
  enabled?: boolean;
  filters?: TFilterItem[];
};

function useGetRandomEvent({ jobId, enabled = true, filters }: TUseGetRandomEventProps) {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);
  const { data: jobInfoData } = useJobInfo(jobId);

  // SETUP
  const URL = useMemo(() => config.api.jobAlertRandom.replace(':jobId', String(jobId)), [jobId]);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<GetRandomEventResponse>>(URL, body)
        .then((responseData) => Serializer.getInstance().deserializeCommonResponse(responseData, GetRandomEventResponse)),
    [URL]
  );

  // PAYLOAD
  const filtersMapped = useMemo(() => filterItemArrayToFilterRecordV2Array({ filters }) ?? [], [filters]);
  const payload = useMemo(() => constructGetRandomEventRequest({ filters: filtersMapped }), [filtersMapped]);
  const payloadSerialized = useMemo(() => Serializer.getInstance().serializeObject(payload), [payload]);

  // QUERY
  return useQuery(eventKeys.random(jobId, filters), () => axiosFunction(payloadSerialized), {
    onError: (error: AxiosResponse<ResourceWrapper<GetRandomEventResponse>>) => {
      if (getHasNoDocumentsProcessed(jobInfoData?.info)) {
        showWarningNotification(
          t('codeBlock:notifications.toast.noDocumentsProcessedEventExample.title'),
          t('codeBlock:notifications.toast.noDocumentsProcessedEventExample.subtitle')
        );
      } else if (error.data?.resource?.request?.reason) {
        showErrorNotification('Error', error.data.resource.request.reason);
      } else {
        showErrorNotification(t('requests:events.random.error.title'), t('requests:events.random.error.subtitle'));
      }
    },
    enabled: enabled,
    staleTime: Infinity
  });
}

export default useGetRandomEvent;
