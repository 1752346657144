import { AdvancedFilters, TFilterItem, TUseAdvancedFilters } from '@arcanna/components';
import { EIcon, Icon, Table } from '@arcanna/generic';
import { InputAdornment, Stack, TextField, useTheme } from '@mui/material';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import GetRandomEventButton from '../GetRandomEventButton';
import { TEventSampleDrawerTableData } from './EventSampleDrawerTable.types';
import { getColumns } from './EventSampleDrawerTable.utils';
import { GetRandomEventResponse } from '@arcanna/models/Event';

type TEventSampleDrawerTableProps = {
  jobId: number;
  isLoading: boolean;
  filters: TFilterItem[];
  advancedFilters: TUseAdvancedFilters;
  getFilterSourceByName: (name: string) => string | undefined;
  eventSample: GetRandomEventResponse | undefined;
  setEventSample: (event: GetRandomEventResponse) => void;
};

function EventSampleDrawerTable({
  jobId,
  isLoading,
  filters,
  advancedFilters,
  getFilterSourceByName,
  eventSample,
  setEventSample
}: TEventSampleDrawerTableProps) {
  const { t } = useTranslation(['codeBlock']);
  const { palette } = useTheme();

  const event = useMemo(
    () =>
      eventSample?.entries.map((entry) => {
        return {
          key: entry.name,
          value: entry.value
        };
      }),
    [eventSample?.entries]
  );

  const columns = useMemo(
    () =>
      getColumns({
        t,
        getFilterSourceByName,
        activeFilters: advancedFilters.activeFilters,
        addAdvancedFilter: advancedFilters.addAdvancedFilter
      }),
    [advancedFilters.activeFilters, advancedFilters.addAdvancedFilter, getFilterSourceByName, t]
  );

  const tableInstance = useReactTable<TEventSampleDrawerTableData>({
    columns,
    data: event ?? [],
    enableSorting: true,
    enableHiding: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableColumnPinning: false,
    enableColumnResizing: false,
    enableGlobalFilter: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    autoResetPageIndex: false,
    getRowId: (row) => row.key,
    initialState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0
      }
    }
  });

  return (
    <Stack gap="12px">
      <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
        <GetRandomEventButton jobId={jobId} filters={filters} setEventSample={setEventSample} />
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name={EIcon.ActionSearch} htmlColor={palette.secondary[300]} />
              </InputAdornment>
            ),
            sx: { height: '36px' }
          }}
          size="small"
          placeholder={t('codeBlock:editTestInputDrawer.tableTab.search')}
          sx={{
            width: '300px',
            '.MuiInputBase-root': {
              backgroundColor: palette.background.default
            }
          }}
          value={tableInstance.getState().globalFilter}
          onChange={(ev) => {
            tableInstance.setGlobalFilter(ev.target.value);
            tableInstance.resetPageIndex();
          }}
        />
        <AdvancedFilters.Button
          fieldOptions={advancedFilters.fieldOptions}
          operatorOptionsMap={advancedFilters.operatorOptionsMap}
          addAdvancedFilter={advancedFilters.addAdvancedFilter}
          getValueOptions={advancedFilters.getValueOptions}
        />
      </Stack>
      <AdvancedFilters.List
        filters={advancedFilters.activeFilters}
        fieldOptions={advancedFilters.fieldOptions}
        operatorOptionsMap={advancedFilters.operatorOptionsMap}
        onDelete={advancedFilters.removeAdvancedFilter}
        onUpdate={advancedFilters.addAdvancedFilter}
        onClearAll={advancedFilters.clearAllAdvancedFilters}
        getValueOptions={advancedFilters.getValueOptions}
      />
      <Stack maxHeight="50vh" gap={1}>
        <Table.component<TEventSampleDrawerTableData>
          tableInstance={tableInstance}
          noResultsMessageTitle={t('codeBlock:editTestInputDrawer.tableTab.emptyState.title')}
          noResultsMessageSubtitle={t('codeBlock:editTestInputDrawer.tableTab.emptyState.subtitle')}
          isTableResizable={false}
          isLoading={isLoading}
          defaultTableSize={Table.TABLE_SIZES.small}
        />
        <Table.TablePagination<TEventSampleDrawerTableData> tableInstance={tableInstance} />
      </Stack>
    </Stack>
  );
}

export default EventSampleDrawerTable;
