import { useFormContext } from 'react-hook-form';
import { IntegrationJobParameterRecord } from 'src/components/shared/models/integration/job/IntegrationJobParameterRecord';
import { TRHFPill } from '@arcanna/forms';
import { getFormParameterName } from './RHFSmartGeneratedField.utils';
import { Grid, SxProps, Theme } from '@mui/material';
import {
  BooleanParameter,
  DateTimeParameter,
  DropdownParameter,
  MappingParameter,
  NumberParameter,
  RadioGroupParameter,
  StringParameter,
  TextParameter
} from './components';
import UserApiKeyParameter from './components/UserApiKeyParameter';
import PasswordParameter from './components/PasswordParameter';
import { useCallback, useMemo } from 'react';

export type TRHFSmartGeneratedFieldProps = {
  parameter: IntegrationJobParameterRecord;
  customLabels?: TRHFPill[];
  formSectionName?: string;
  sx?: SxProps<Theme>;
  // Used in CE, for simple params, where we expect a string value, but some input fields must be number
  forceStringConversion?: boolean;
};

function RHFSmartGeneratedField(props: TRHFSmartGeneratedFieldProps) {
  const { watch } = useFormContext();

  const { parameter, formSectionName } = props;

  // @ts-expect-error TS(2769): No overload matches this call.
  const dependencyValue = watch(parameter.dependency_field);
  // @ts-expect-error TS(2769): No overload matches this call.
  const fieldValue = watch(parameter.field);

  const nestedDependencyValue = watch(
    getFormParameterName({ field: parameter.dependency_field } as IntegrationJobParameterRecord, formSectionName)
  );

  const span = useMemo(
    () => (parameter.active_span && fieldValue ? parameter.active_span : parameter.span || 12),
    [parameter, fieldValue]
  );

  const getIsDependencyEnabled = useCallback(() => {
    if (!parameter.dependency_field) {
      return true;
    }

    if (dependencyValue) {
      return dependencyValue.toString() === parameter.dependency_value;
    }

    if (nestedDependencyValue !== undefined && nestedDependencyValue !== null) {
      return nestedDependencyValue.toString() === parameter.dependency_value;
    }

    return false;
  }, [parameter, nestedDependencyValue, dependencyValue]);

  if (!getIsDependencyEnabled()) {
    return <></>;
  }

  switch (parameter.type) {
    case 'string':
      return (
        <Grid item xs={span}>
          <StringParameter {...props} />
        </Grid>
      );

    case 'date':
      return (
        <Grid item xs={span}>
          <DateTimeParameter {...props} />
        </Grid>
      );

    case 'number':
      return (
        <Grid item xs={span}>
          <NumberParameter {...props} />
        </Grid>
      );

    case 'text':
      return (
        <Grid item xs={span}>
          <TextParameter {...props} />
        </Grid>
      );

    case 'boolean':
      return (
        <Grid item xs={span}>
          <BooleanParameter {...props} />
        </Grid>
      );

    case 'dropdown':
      return (
        <Grid item xs={span}>
          <DropdownParameter {...props} />
        </Grid>
      );

    case 'user_api_key':
      return (
        <Grid item xs={span}>
          <UserApiKeyParameter {...props} />
        </Grid>
      );

    case 'radio_button':
      return (
        <Grid item xs={span}>
          <RadioGroupParameter {...props} />
        </Grid>
      );

    case 'password':
      return (
        <Grid item xs={span}>
          <PasswordParameter {...props} />
        </Grid>
      );

    case 'mapping':
      return (
        <Grid item xs={span}>
          <MappingParameter {...props} />
        </Grid>
      );

    default:
      // eslint-disable-next-line
      console.error(`ERROR! The following parameter type is not supported: ${parameter.type}`);
      return <></>;
  }
}

export default RHFSmartGeneratedField;
