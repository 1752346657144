import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('StorageTagDisplayNameEditRequest')
export class StorageTagDisplayNameEditRequest extends RequestCommon {
  @JsonProperty('display_name', String)
  displayName: string;
}

type TConstructorParams = {
  displayName: StorageTagDisplayNameEditRequest['displayName'];
};

export function constructStorageTagDisplayNameEditRequest({ displayName }: TConstructorParams): StorageTagDisplayNameEditRequest {
  const instance = new StorageTagDisplayNameEditRequest();
  instance.displayName = displayName;

  return instance;
}
