const formatBytes = (bytes: number): string => {
  if (bytes < 0) {
    throw new Error('Bytes value cannot be negative.');
  }

  const MB = 1024 * 1024;
  const GB = MB * 1024;
  const TB = GB * 1024;

  if (bytes < GB) {
    const mb = bytes / MB;
    return `${mb.toFixed(2)} MB`;
  } else if (bytes < TB) {
    const gb = bytes / GB;
    return `${gb.toFixed(2)} GB`;
  } else {
    const tb = bytes / TB;
    return `${tb.toFixed(2)} TB`;
  }
};

export { formatBytes };
