import { apiKeysKeys } from '@arcanna/requests/ApiKeys';
import axios, { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../../config';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { IntegrationCreateRequest } from '../../components/shared/models/integration/IntegrationCreateRequest';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useTranslation } from 'react-i18next';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { useMemo } from 'react';
import { storageTagKeys } from '@arcanna/requests/StorageTags/keys';

type Response = AxiosResponse<{ resource: { request: CommonResponse } }>;

export function useCreateIntegration() {
  const { t } = useTranslation(['common']);
  const queryClient = useQueryClient();
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  return useMutation<Response, Response, IntegrationCreateRequest>(
    (payload: IntegrationCreateRequest) => axios.post(config.api.integrationAdd, payload),
    {
      onSuccess: (responseData: Response) => {
        if (!responseData.data) {
          return;
        }
        if (!responseData.data.resource) {
          showErrorNotification(t('common:actionCompleted'), t('common:createError'));
          return;
        }

        const integrationCreateResponse = jsonConvert.deserializeObject(responseData.data.resource.request, CommonResponse);

        if (integrationCreateResponse.status === 'OK') {
          showSuccessNotification(t('common:actionCompleted'), t('common:createdSuccessfully'));
        } else {
          showErrorNotification(t('common:actionCompleted'), t('common:createError') + integrationCreateResponse.reason);
        }
      },
      onError: (error) => {
        if (!error.data?.resource) {
          showErrorNotification(t('common:actionCompleted'), t('common:createError'));
          return;
        }

        const integrationCreateResponse = jsonConvert.deserializeObject(error.data.resource.request, CommonResponse);

        if (integrationCreateResponse.status === 'NOK') {
          showErrorNotification(t('common:actionCompleted'), t('common:createError') + integrationCreateResponse.reason);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([config.api.integrations]);
        queryClient.invalidateQueries(apiKeysKeys.list());
        queryClient.invalidateQueries(storageTagKeys.all);
      }
    }
  );
}
