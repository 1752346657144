import { EIcon, Icon, Spinner } from '@arcanna/generic';
import { useGetRandomEvent } from '@arcanna/requests/Event';
import { Button } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetRandomEventResponse } from '@arcanna/models/Event';
import { TFilterItem } from '@arcanna/components';

type TGetRandomEventButtonProps = {
  jobId: number;
  filters: TFilterItem[];
  setEventSample: (event: GetRandomEventResponse) => void;
};

function GetRandomEventButton({ jobId, filters, setEventSample }: TGetRandomEventButtonProps) {
  const { t } = useTranslation(['codeBlock']);

  const filteredActiveFilters = useMemo(() => filters.filter((filter) => filter.status === 'active'), [filters]);

  const { refetch: refetchGetRandomEvent, isRefetching: isGetRandomEventRefetching } = useGetRandomEvent({
    jobId,
    enabled: false,
    filters: filteredActiveFilters
  });

  const handleGetRandomEvent = useCallback(async () => {
    const { data: randomEvent } = await refetchGetRandomEvent();

    if (!randomEvent) {
      return;
    }

    setEventSample(randomEvent);
  }, [refetchGetRandomEvent, setEventSample]);

  return (
    <Button
      variant="contained"
      color="secondary"
      size="small"
      endIcon={isGetRandomEventRefetching ? <Spinner /> : <Icon name={EIcon.Shuffle01} />}
      onClick={handleGetRandomEvent}
      disabled={isGetRandomEventRefetching}
      sx={{
        width: '220px'
      }}
    >
      {t('codeBlock:common.buttons.getRandomEvent')}
    </Button>
  );
}

export default GetRandomEventButton;
