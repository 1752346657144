import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from 'src/_srcMUI/models/utils';
import { FilterRecordV2 } from 'src/_srcMUI/models/utils/FilterRecord';
import { ContextEnrichmentField, IntegrationParamsComplex, IntegrationParamsSimple } from './ContextEnrichmentRecord';

@JsonObject('ContextEnrichmentSaveRequest')
export class ContextEnrichmentSaveRequest extends RequestCommon {
  @JsonProperty('auto_id', String)
  autoId: string | undefined;

  @JsonProperty('enrichment_name', String)
  internalId: string;

  @JsonProperty('friendly_name', String)
  contextName: string;

  @JsonProperty('integration_id', String)
  integrationId: string;

  @JsonProperty('integration_params_simple', [IntegrationParamsSimple])
  integrationParamsSimple: IntegrationParamsSimple[];

  @JsonProperty('integration_params_complex', [IntegrationParamsComplex])
  integrationParamsComplex: IntegrationParamsComplex[];

  @JsonProperty('body_value', String)
  bodyValue: string;

  @JsonProperty('body_type', String)
  bodyType: string;

  @JsonProperty('field_mapping', [ContextEnrichmentField])
  fieldMapping: ContextEnrichmentField[];

  @JsonProperty('filters', [FilterRecordV2])
  filters: FilterRecordV2[];
}

type TConstructorParams = {
  autoId: ContextEnrichmentSaveRequest['autoId'];
  internalId: ContextEnrichmentSaveRequest['internalId'];
  contextName: ContextEnrichmentSaveRequest['contextName'];
  integrationId: ContextEnrichmentSaveRequest['integrationId'];
  integrationParamsSimple: ContextEnrichmentSaveRequest['integrationParamsSimple'];
  integrationParamsComplex: ContextEnrichmentSaveRequest['integrationParamsComplex'];
  bodyValue: ContextEnrichmentSaveRequest['bodyValue'];
  bodyType: ContextEnrichmentSaveRequest['bodyType'];
  fieldMapping: ContextEnrichmentSaveRequest['fieldMapping'];
  filters: ContextEnrichmentSaveRequest['filters'];
};

export function constructContextEnrichmentSaveRequest(params: TConstructorParams): ContextEnrichmentSaveRequest {
  const {
    autoId,
    internalId,
    contextName,
    integrationId,
    integrationParamsSimple,
    integrationParamsComplex,
    bodyValue,
    bodyType,
    fieldMapping,
    filters
  } = params;

  const request = new ContextEnrichmentSaveRequest();
  request.autoId = autoId;
  request.internalId = internalId;
  request.contextName = contextName;
  request.integrationId = integrationId;
  request.integrationParamsSimple = integrationParamsSimple;
  request.integrationParamsComplex = integrationParamsComplex;
  request.bodyValue = bodyValue;
  request.bodyType = bodyType;
  request.fieldMapping = fieldMapping;
  request.filters = filters;

  return request;
}
