/* eslint-disable @typescript-eslint/no-explicit-any */

import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from 'src/_srcMUI/models/utils';

@JsonObject('ContextEnrichmentLineariseResponse')
export class ContextEnrichmentLineariseResponse extends ResponseCommon {
  @JsonProperty('data', Any)
  data: any;
}
