import { useJobIdFromUrl } from '@arcanna/hooks';
import { constructDeleteIntegrationRequest } from '@arcanna/models/Flows/DeleteIntegrationRequest';
import { constructDeleteStorageTagRequest, StorageTagRecord } from '@arcanna/models/StorageTags';
import useGetIntegrationFromStorageTag from '@arcanna/pages/AddJob/components/AddJobForm/components/AddJobIntegrationDrawerContent/components/IntegrationForm/components/StorageTagSection/hooks/useGetIntegrationFromStorageTag';
import { useDeleteIntegration } from '@arcanna/requests/Flows';
import { useDeleteStorageTag } from '@arcanna/requests/StorageTags';
import { Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import AlertDialog from 'src/_srcMUI/shared/components/AlertDialog';

type TStorageTagDeleteDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  storageTag: StorageTagRecord;
};

function StorageTagDeleteDialog({ isOpen, handleClose, storageTag }: TStorageTagDeleteDialogProps) {
  const { jobId } = useJobIdFromUrl();

  const { mutateAsync: deleteStorageTag, isLoading } = useDeleteStorageTag({ jobId });
  const { integration, currentOrder } = useGetIntegrationFromStorageTag({ storageTag: storageTag.storageTag });

  const { mutateAsync: deleteIntegration, isLoading: isDeleteIntegrationLoading } = useDeleteIntegration({
    jobId,
    currentOrder
  });

  const handleSubmit = useCallback(async () => {
    await deleteStorageTag(constructDeleteStorageTagRequest({ storageTag: storageTag.storageTag }), {
      onSuccess: () => {
        if (integration) {
          deleteIntegration(constructDeleteIntegrationRequest({ autoId: integration.autoId }));
        }
        handleClose();
      }
    });
  }, [deleteIntegration, deleteStorageTag, handleClose, integration, storageTag.storageTag]);

  return (
    <AlertDialog
      open={isOpen}
      handleSubmit={handleSubmit}
      onClose={handleClose}
      title="Delete"
      isLoading={isLoading || isDeleteIntegrationLoading}
      modalBody={
        <Stack direction="column">
          <Typography variant="caption" color="inherit" fontSize="14px">
            All of&nbsp;
            <Typography component="span" variant="caption" fontSize="14px">
              {storageTag.storageTag}&nbsp;
            </Typography>
            events and the input integration using it will be deleted and cannot be restored.
          </Typography>
          <Typography variant="caption" marginTop={1} color="inherit" fontSize="14px">
            Would you like delete it?
          </Typography>
        </Stack>
      }
      confirmBtnText="Yes, Delete"
    />
  );
}

export default StorageTagDeleteDialog;
