import { useJobIdFromUrl } from '@arcanna/hooks';
import { useMemo } from 'react';
import { useJobInfo } from 'src/data-access';

type TStorageTagInputsProps = {
  integrationAutoIds: string[];
};
function StorageTagInputs({ integrationAutoIds }: TStorageTagInputsProps) {
  const { jobId } = useJobIdFromUrl();
  const { data: jobInfo } = useJobInfo(jobId);

  const integrationNames = useMemo(
    () =>
      integrationAutoIds
        .map((integrationAutoId) => {
          const integration = jobInfo?.info?.pipelineIntegrations.find((int) => int.autoId === integrationAutoId);

          return integration?.title ?? null;
        })
        .filter(Boolean)
        .join(', '),
    [integrationAutoIds, jobInfo]
  );

  return <>{integrationNames}</>;
}

export default StorageTagInputs;
