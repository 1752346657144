import { Button, ButtonProps, Menu } from '@mui/material';
import { EIcon, Icon, TOption } from '@arcanna/generic';
import { MouseEvent, useState } from 'react';
import AdvancedFiltersMenu from '../AdvancedFiltersMenu';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import { TFilterItem } from '../../AdvancedFilters.types';
import { useTranslation } from 'react-i18next';

type TAdvancedFiltersButtonProps = {
  fieldOptions: (FeedbackFiltersFieldsRecord & TOption)[];
  operatorOptionsMap: Record<string, TOption[]>;
  addAdvancedFilter: (filter: TFilterItem) => void;
  getValueOptions: (field: string) => Promise<TOption[]>;
} & ButtonProps;

function AdvancedFiltersButton({
  fieldOptions,
  operatorOptionsMap,
  addAdvancedFilter,
  getValueOptions,
  ...buttonProps
}: TAdvancedFiltersButtonProps) {
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        color="secondary"
        variant={isMenuOpen ? 'containedHover' : 'contained'}
        data-test-id="add-filters"
        size="small"
        endIcon={<Icon name={EIcon.Settings04} />}
        {...buttonProps}
      >
        {t('advancedFilters.addFilter')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <AdvancedFiltersMenu
          onSave={(filter) => {
            addAdvancedFilter(filter);
            handleClose();
          }}
          onCancel={() => handleClose()}
          fieldOptions={fieldOptions}
          operatorOptionsMap={operatorOptionsMap}
          getValueOptions={getValueOptions}
        />
      </Menu>
    </>
  );
}

export default AdvancedFiltersButton;
