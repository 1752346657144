import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'react-i18next';
import { TModelHistoryInsightsTableEntry } from './ModelHistoryInsights';
import CustomLabelByJob from '@arcanna/pages/Feedback/components/FeedbackTable/models/CustomLabelByJob';
import { CustomLabel } from '@arcanna/components';

export const getColumns = (t: TFunction, jobInfoCustomLabels?: CustomLabelByJob[]) => {
  const columnHelper = createColumnHelper<TModelHistoryInsightsTableEntry>();

  return [
    columnHelper.accessor('label', {
      id: 'label',
      header: String(t('overview:feedbackLabel')),
      cell: ({ row }) => {
        const labelId = row.original.label;
        const customLabel = jobInfoCustomLabels?.find((cl: CustomLabelByJob) => cl.id === labelId);

        return customLabel ? <CustomLabel hexColor={customLabel.hexColor} name={customLabel.name} /> : labelId;
      },
      enableHiding: false,
      size: 200,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:feedbackLabelInfo')
      }
    }),
    columnHelper.accessor('true_positives', {
      id: 'tp',
      header: 'TP',
      enableHiding: false,
      size: 100,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:truePositivesInfo')
      }
    }),
    columnHelper.accessor('true_negatives', {
      id: 'tn',
      header: 'TN',
      enableHiding: false,
      size: 100,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:trueNegativesInfo')
      }
    }),
    columnHelper.accessor('false_positives', {
      id: 'fp',
      header: 'FP',
      enableHiding: false,
      size: 100,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:falsePositivesInfo')
      }
    }),
    columnHelper.accessor('false_negatives', {
      id: 'fn',
      header: 'FN',
      enableHiding: false,
      size: 100,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:falseNegativesInfo')
      }
    }),
    columnHelper.accessor('recall', {
      id: 'recall',
      header: String(t('overview:recall')),
      cell: ({ row }) => `${row.original.recall.toFixed(2)}%`,
      enableHiding: false,
      size: 100,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:recallInfo')
      }
    }),
    columnHelper.accessor('precision', {
      id: 'precision',
      header: String(t('overview:precision')),
      cell: ({ row }) => `${row.original.precision.toFixed(2)}%`,
      enableHiding: false,
      size: 100,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:precisionInfo')
      }
    }),
    columnHelper.accessor('f1_score', {
      id: 'fScore',
      header: String(t('overview:fScore')),
      cell: ({ row }) => `${row.original.f1_score.toFixed(2)}%`,
      enableHiding: false,
      size: 100,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:fScoreInfo')
      }
    })
  ];
};
