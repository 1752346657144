import { Modal } from '@arcanna/generic';
import { Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { DISPLAY_NAME_STORAGE_TAG_FIELD_NAME } from '@arcanna/pages/AddJob/components/AddJobForm/components/AddJobIntegrationDrawerContent/components/IntegrationForm/components/StorageTagSection/StorageTagSection.utils';
import { useCallback, useMemo } from 'react';
import useStorageDisplayNameEdit from '@arcanna/requests/StorageTags/useStorageDisplayNameEdit';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { constructStorageTagDisplayNameEditRequest } from '@arcanna/models/StorageTags/StorageTagDisplayNameEditRequest';
import { FormProvider, RHFTextField } from '@arcanna/forms';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';

type TDisplayNameEditModalProps = {
  isOpenEditDisplayNameModal: boolean;
  setIsOpenEditDisplayNameModal: (isOpen: boolean) => void;
  displayName: string;
  storageTag: string;
};
export default function DisplayNameEditModal({
  isOpenEditDisplayNameModal,
  setIsOpenEditDisplayNameModal,
  displayName,
  storageTag
}: TDisplayNameEditModalProps) {
  const theme = useTheme();
  const { jobId } = useJobIdFromUrl();

  const schema = useMemo(() => {
    return Joi.object({
      [DISPLAY_NAME_STORAGE_TAG_FIELD_NAME]: Joi.string().trim().required().min(5).max(30).messages({
        'string.empty': 'Display name for storage tag can not be empty',
        'any.required': 'Display name is required',
        'string.min': 'Display name should have minimum length of 5',
        'string.max': 'Display name should have a maximum length of 30'
      })
    });
  }, []);

  const defaultValues = useMemo(() => ({ [DISPLAY_NAME_STORAGE_TAG_FIELD_NAME]: displayName }), [displayName]);

  const methods = useForm<{ [DISPLAY_NAME_STORAGE_TAG_FIELD_NAME]: string }>({
    resolver: joiResolver(schema),
    defaultValues: defaultValues
  });

  const storageDisplayNameMutation = useStorageDisplayNameEdit({ jobId, storageTag });

  const handleSubmit = useCallback(
    async (values: { [DISPLAY_NAME_STORAGE_TAG_FIELD_NAME]: string }) => {
      await storageDisplayNameMutation.mutateAsync(
        constructStorageTagDisplayNameEditRequest({ displayName: values[DISPLAY_NAME_STORAGE_TAG_FIELD_NAME].trim() })
      );
      setIsOpenEditDisplayNameModal(false);
    },
    [storageDisplayNameMutation, setIsOpenEditDisplayNameModal]
  );
  return (
    <Modal
      titleNode={
        <Typography variant={'h4'} color={theme.palette.secondary[100]}>
          Change Storage Tag Display Name
        </Typography>
      }
      open={isOpenEditDisplayNameModal}
      onClose={() => setIsOpenEditDisplayNameModal(false)}
      footer={
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSubmit)}>
          <Stack direction={'row'} flexDirection={'row-reverse'} gap={'16px'}>
            <Button size="small" variant={'tertiary'} onClick={() => setIsOpenEditDisplayNameModal(false)}>
              Cancel
            </Button>
            <Button size="small" variant={'contained'} onClick={methods.handleSubmit(handleSubmit)}>
              Save
            </Button>
          </Stack>
        </FormProvider>
      }
    >
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSubmit)}>
        <RHFTextField
          label={'Display Name'}
          name={DISPLAY_NAME_STORAGE_TAG_FIELD_NAME}
          id={DISPLAY_NAME_STORAGE_TAG_FIELD_NAME}
          fullWidth
          helperText={'The display name of your tag. It can be changed later.'}
          required
        />
      </FormProvider>
    </Modal>
  );
}
