import { EIcon, Icon } from '@arcanna/generic';
import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useMemo } from 'react';
import { ModelHistory } from 'src/components/shared/models/retrain-history/JobAllRetrainHistoryResponse';

type TModelHistoryLastRecomputedColumnProps = {
  AIModel: ModelHistory;
};

function ModelHistoryLastRecomputedColumn({ AIModel }: TModelHistoryLastRecomputedColumnProps) {
  const theme = useTheme();

  const formattedDate = useMemo(
    () =>
      AIModel.newModelMetrics.lastRecomputeMetricsTimestamp
        ? moment(AIModel.newModelMetrics.lastRecomputeMetricsTimestamp).format('MMM DD, YYYY HH:mm')
        : 'Never',
    [AIModel.newModelMetrics.lastRecomputeMetricsTimestamp]
  );

  return (
    <Stack gap={1} alignItems="center" direction="row">
      <Typography variant="caption">{formattedDate}</Typography>
      {AIModel.newModelMetrics.recomputeMetricsError && (
        <Tooltip
          title={AIModel.newModelMetrics.recomputeMetricsError}
          placement="top"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                color: theme.palette.secondary[100],
                background: theme.palette.secondary[600]
              }
            },
            arrow: {
              sx: {
                color: theme.palette.secondary[600]
              }
            }
          }}
        >
          <Stack>
            <Icon name={EIcon.AlertTriangle} fontSize="small" />
          </Stack>
        </Tooltip>
      )}
    </Stack>
  );
}

export default ModelHistoryLastRecomputedColumn;
