import { constructGetExpandedEventByIdRequest, GetExpandedEventByIdRequest } from '@arcanna/models/Event';
import { ResourceWrapper } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { eventKeys } from './keys';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';
import { GetExpandedEventByIdResponse } from '@arcanna/models/Event/GetExpandedEventByIdResponse';

type TUseGetExpandedEventByIdProps = {
  jobId: GetExpandedEventByIdRequest['jobId'];
  eventId?: GetExpandedEventByIdRequest['eventId'];
};

function useGetExpandedEventById({ jobId, eventId }: TUseGetExpandedEventByIdProps) {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);

  // SETUP
  const URL = useMemo(() => config.api.events.getExpandedEvent, []);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResourceWrapper<GetExpandedEventByIdResponse>>(URL, body)
        .then((response) => Serializer.getInstance().deserializeCommonResponse(response, GetExpandedEventByIdResponse)),
    [URL]
  );

  // PAYLOAD
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const payload = useMemo(() => constructGetExpandedEventByIdRequest({ jobId, eventId: eventId! }), [eventId, jobId]);
  const payloadSerialized = useMemo(() => Serializer.getInstance().serializeObject(payload), [payload]);

  // QUERY
  return useQuery(eventKeys.get(eventId ?? ''), () => axiosFunction(payloadSerialized), {
    onError: () => {
      showErrorNotification(t('requests:events.getById.error.title'), t('requests:events.getById.error.subtitle'));
    },
    enabled: Boolean(eventId),
    staleTime: Infinity
  });
}

export default useGetExpandedEventById;
