import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('EnableDisableIntegrationRequest')
export class EnableDisableIntegrationRequest extends RequestCommon {
  @JsonProperty('auto_id', String)
  autoId: string;

  @JsonProperty('enabled', Boolean)
  enabled: boolean;
}

type TConstructorParams = {
  autoId: EnableDisableIntegrationRequest['autoId'];
  enabled: EnableDisableIntegrationRequest['enabled'];
};

export function constructEnableDisableIntegrationRequest({ autoId, enabled }: TConstructorParams) {
  const instance = new EnableDisableIntegrationRequest();
  instance.autoId = autoId;
  instance.enabled = enabled;

  return instance;
}
