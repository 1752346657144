import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';
import { GetEventsBatchFilterRecord } from './GetEventsBatchRequest';

@JsonObject('GetEventGroupsRequest')
export class GetEventGroupsRequest extends RequestCommon {
  @JsonProperty('job_ids', [Number])
  jobIds: number[];

  @JsonProperty('grouped_field', String)
  groupedField: string;

  @JsonProperty('start_date', String)
  startDate: string | undefined;

  @JsonProperty('end_date', String)
  endDate: string | undefined;

  @JsonProperty('filters', [GetEventsBatchFilterRecord])
  filters: GetEventsBatchFilterRecord[];

  @JsonProperty('from', Number)
  from: number;

  @JsonProperty('size', Number)
  size: number;

  @JsonProperty('query_caching_id', String)
  queryCachingId: string | undefined;
}

type TConstructorParams = {
  jobIds: GetEventGroupsRequest['jobIds'];
  groupedField: GetEventGroupsRequest['groupedField'];
  startDate: GetEventGroupsRequest['startDate'];
  endDate: GetEventGroupsRequest['endDate'];
  filters: GetEventGroupsRequest['filters'];
  queryCachingId: GetEventGroupsRequest['queryCachingId'];
  pageCount: GetEventGroupsRequest['from'];
  pageSize: GetEventGroupsRequest['size'];
};

export function constructGetEventGroupsRequest({
  jobIds,
  groupedField,
  startDate,
  endDate,
  filters,
  queryCachingId,
  pageCount,
  pageSize
}: TConstructorParams): GetEventGroupsRequest {
  const instance = new GetEventGroupsRequest();
  instance.jobIds = jobIds;
  instance.groupedField = groupedField;
  instance.startDate = startDate;
  instance.endDate = endDate;
  instance.filters = filters;
  instance.queryCachingId = queryCachingId;
  instance.from = (pageCount - 1) * pageSize;
  instance.size = pageSize;

  return instance;
}
