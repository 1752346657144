/* eslint-disable @typescript-eslint/no-explicit-any */

import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from 'src/_srcMUI/models/utils';

@JsonObject('ContextEnrichmentLineariseRequest')
export class ContextEnrichmentLineariseRequest extends RequestCommon {
  @JsonProperty('data', Any)
  data: any;
}

type TConstructorParams = {
  data: any;
};

export function constructContextEnrichmentLineariseRequest(params: TConstructorParams): ContextEnrichmentLineariseRequest {
  const { data } = params;
  const request = new ContextEnrichmentLineariseRequest();
  request.data = data;
  return request;
}
