import { useCallback, useEffect, useMemo, useState } from 'react';
import { TOption } from '@arcanna/generic';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import {
  EFilterOperatorMultiple,
  EFilterOperatorWithValue,
  EFilterOperatorWithoutValue,
  TFilterItem
} from '../AdvancedFilters.types';
import { getAreFilterItemsEqual, getAreFilterItemsOpposites, getFilterValue, getIsFilterValid } from '../AdvancedFilters.utils';
import _ from 'lodash';

type TUseAdvancedFiltersProps = {
  fields: (FeedbackFiltersFieldsRecord & { valueType?: string })[] | undefined;
  getValueOptions: (field: string, activeFilters: TFilterItem[]) => Promise<TOption[]>;
  initialFilters: TFilterItem[];
  onFiltersChange?: (filters: TFilterItem[]) => void;
};

export type TUseAdvancedFilters = {
  fieldOptions: (FeedbackFiltersFieldsRecord & TOption)[];
  operatorOptionsMap: Record<string, TOption[]>;
  addAdvancedFilter: (filter: TFilterItem) => void;
  activeFilters: TFilterItem[];
  removeAdvancedFilter: (filterId: string) => void;
  removeAdvancedFilters: (filterIds: string[]) => void;
  clearAllAdvancedFilters: () => void;
  getValueOptions: (fieldName: string, filterId?: string) => Promise<TOption[]>;
};

const ARCANNA_DECISION_COLUMN = 'label';

const useAdvancedFilters = ({
  fields,
  getValueOptions: getValueOptionsProp,
  initialFilters,
  onFiltersChange
}: TUseAdvancedFiltersProps): TUseAdvancedFilters => {
  const [activeFilters, setActiveFilters] = useState<TFilterItem[]>(initialFilters);

  useEffect(() => {
    setActiveFilters(initialFilters?.map((filterItem) => ({ ...filterItem, status: filterItem.status ?? 'active' })));
  }, [initialFilters]);

  const fieldOptions: (FeedbackFiltersFieldsRecord & TOption & { valueType?: string })[] = useMemo(
    () =>
      fields?.map((field) => {
        return {
          ...field,
          label: field.name === ARCANNA_DECISION_COLUMN ? `Arcanna's decision` : field.name ?? '',
          value: field.source ?? '',
          valueType: field.valueType
        };
      }) ?? [],
    [fields]
  );

  const operatorOptionsMap: Record<string, TOption[]> = useMemo(() => {
    return fieldOptions.reduce((acc, field) => {
      if (!field.source) {
        return acc;
      }

      return {
        ...acc,
        [field.source]:
          field.operators?.map((operator) => ({
            label: operator,
            value: operator
          })) ?? []
      };
    }, {});
  }, [fieldOptions]);

  const addAdvancedFilter = useCallback(
    (filter: TFilterItem) => {
      const isFilterValid = getIsFilterValid(filter);
      if (!isFilterValid) {
        return;
      }

      let hasFilterBeenAdded = false;

      const filtersWithoutDuplicates = activeFilters.reduce((acc: TFilterItem[], activeFilter) => {
        const isSameFilter = activeFilter.id === filter.id || getAreFilterItemsEqual(activeFilter, filter);

        if (isSameFilter) {
          if (activeFilter.status === 'active' && !hasFilterBeenAdded) {
            hasFilterBeenAdded = true;

            return [...acc, filter];
          }

          return acc;
        }

        if (activeFilter.field === filter.field) {
          if ([activeFilter.operator, filter.operator].includes(EFilterOperatorWithoutValue.notExists)) {
            return acc;
          }

          if (getAreFilterItemsOpposites(activeFilter, filter) && getFilterValue(activeFilter) === getFilterValue(filter)) {
            return acc;
          }

          if (
            (EFilterOperatorWithValue.startsWith === filter.operator ||
              EFilterOperatorWithValue.notStartsWith === filter.operator) &&
            getFilterValue(activeFilter) === getFilterValue(filter)
          ) {
            return acc;
          }

          if (activeFilter.status === 'active') {
            if (EFilterOperatorWithValue.is === filter.operator) {
              if (EFilterOperatorWithValue.is === activeFilter.operator) {
                const accParsed = hasFilterBeenAdded ? acc.filter((accFilterItem) => accFilterItem.id !== filter.id) : acc;
                hasFilterBeenAdded = true;

                return [
                  ...accParsed,
                  {
                    ...activeFilter,
                    operator: EFilterOperatorMultiple.isOneOf,
                    value: _.uniq([getFilterValue(activeFilter) as string, getFilterValue(filter) as string])
                  }
                ];
              }
              if (EFilterOperatorMultiple.isOneOf === activeFilter.operator) {
                const accParsed = hasFilterBeenAdded ? acc.filter((accFilterItem) => accFilterItem.id !== filter.id) : acc;
                hasFilterBeenAdded = true;

                return [
                  ...accParsed,
                  {
                    ...activeFilter,
                    operator: EFilterOperatorMultiple.isOneOf,
                    value: _.uniq([...(getFilterValue(activeFilter) as string[]), getFilterValue(filter) as string])
                  }
                ];
              }
              if (EFilterOperatorMultiple.isNotOneOf === activeFilter.operator) {
                if ((getFilterValue(activeFilter) as string[]).includes(getFilterValue(filter) as string)) {
                  return acc;
                }
              }
            }
            if (EFilterOperatorWithValue.isNot === filter.operator) {
              if (EFilterOperatorWithValue.isNot === activeFilter.operator) {
                const accParsed = hasFilterBeenAdded ? acc.filter((accFilterItem) => accFilterItem.id !== filter.id) : acc;
                hasFilterBeenAdded = true;

                return [
                  ...accParsed,
                  {
                    ...activeFilter,
                    operator: EFilterOperatorMultiple.isNotOneOf,
                    value: _.uniq([getFilterValue(activeFilter) as string, getFilterValue(filter) as string])
                  }
                ];
              }
              if (EFilterOperatorMultiple.isNotOneOf === activeFilter.operator) {
                const accParsed = hasFilterBeenAdded ? acc.filter((accFilterItem) => accFilterItem.id !== filter.id) : acc;
                hasFilterBeenAdded = true;

                return [
                  ...accParsed,
                  {
                    ...activeFilter,
                    operator: EFilterOperatorMultiple.isNotOneOf,
                    value: _.uniq([...(getFilterValue(activeFilter) as string[]), getFilterValue(filter) as string])
                  }
                ];
              }
              if (EFilterOperatorMultiple.isOneOf === activeFilter.operator) {
                if ((getFilterValue(activeFilter) as string[]).includes(getFilterValue(filter) as string)) {
                  return acc;
                }
              }
            }
            if (EFilterOperatorMultiple.isOneOf === filter.operator) {
              if (EFilterOperatorMultiple.isOneOf === activeFilter.operator) {
                const accParsed = hasFilterBeenAdded ? acc.filter((accFilterItem) => accFilterItem.id !== filter.id) : acc;
                hasFilterBeenAdded = true;

                return [
                  ...accParsed,
                  {
                    ...activeFilter,
                    operator: EFilterOperatorMultiple.isOneOf,
                    value: _.uniq([...(getFilterValue(activeFilter) as string[]), ...(getFilterValue(filter) as string[])])
                  }
                ];
              }
              if (EFilterOperatorMultiple.isNotOneOf === activeFilter.operator) {
                if (
                  (getFilterValue(activeFilter) as string[]).some((value) => (getFilterValue(filter) as string[]).includes(value))
                ) {
                  return acc;
                }
              }
              if (EFilterOperatorWithValue.is === activeFilter.operator) {
                const accParsed = hasFilterBeenAdded ? acc.filter((accFilterItem) => accFilterItem.id !== filter.id) : acc;
                hasFilterBeenAdded = true;

                return [
                  ...accParsed,
                  {
                    ...activeFilter,
                    operator: EFilterOperatorMultiple.isOneOf,
                    value: _.uniq([getFilterValue(activeFilter) as string, ...(getFilterValue(filter) as string[])])
                  }
                ];
              }
              if (EFilterOperatorWithValue.isNot === activeFilter.operator) {
                if ((getFilterValue(filter) as string[]).includes(getFilterValue(activeFilter) as string)) {
                  return acc;
                }
              }
            }
            if (EFilterOperatorMultiple.isNotOneOf === filter.operator) {
              if (EFilterOperatorMultiple.isNotOneOf === activeFilter.operator) {
                const accParsed = hasFilterBeenAdded ? acc.filter((accFilterItem) => accFilterItem.id !== filter.id) : acc;
                hasFilterBeenAdded = true;

                return [
                  ...accParsed,
                  {
                    ...activeFilter,
                    operator: EFilterOperatorMultiple.isNotOneOf,
                    value: _.uniq([...(getFilterValue(activeFilter) as string[]), ...(getFilterValue(filter) as string[])])
                  }
                ];
              }
              if (EFilterOperatorMultiple.isOneOf === activeFilter.operator) {
                if (
                  (getFilterValue(activeFilter) as string[]).some((value) => (getFilterValue(filter) as string[]).includes(value))
                ) {
                  return acc;
                }
              }
              if (EFilterOperatorWithValue.isNot === activeFilter.operator) {
                const accParsed = hasFilterBeenAdded ? acc.filter((accFilterItem) => accFilterItem.id !== filter.id) : acc;
                hasFilterBeenAdded = true;

                return [
                  ...accParsed,
                  {
                    ...activeFilter,
                    operator: EFilterOperatorMultiple.isNotOneOf,
                    value: _.uniq([getFilterValue(activeFilter) as string, ...(getFilterValue(filter) as string[])])
                  }
                ];
              }
              if (EFilterOperatorWithValue.is === activeFilter.operator) {
                if ((getFilterValue(filter) as string[]).includes(getFilterValue(activeFilter) as string)) {
                  return acc;
                }
              }
            }
          }
        }

        return [...acc, activeFilter];
      }, []);

      const updatedFilters = hasFilterBeenAdded ? filtersWithoutDuplicates : [...filtersWithoutDuplicates, filter];

      setActiveFilters(updatedFilters);
      onFiltersChange?.(updatedFilters);
    },
    [setActiveFilters, activeFilters, onFiltersChange]
  );

  const removeAdvancedFilter = useCallback(
    (filterId: string) => {
      const updatedFilters = activeFilters.filter((filter) => filter.id !== filterId);
      setActiveFilters(updatedFilters);
      onFiltersChange?.(updatedFilters);
    },
    [activeFilters, setActiveFilters, onFiltersChange]
  );

  const removeAdvancedFilters = useCallback(
    (filterIds: string[]) => {
      const updatedFilters = activeFilters.filter((filter) => !filterIds.includes(filter.id));
      setActiveFilters(updatedFilters);
      onFiltersChange?.(updatedFilters);
    },
    [activeFilters, setActiveFilters, onFiltersChange]
  );

  const clearAllAdvancedFilters = useCallback(() => {
    onFiltersChange?.([]);
    setActiveFilters([]);
  }, [setActiveFilters, onFiltersChange]);

  const getValueOptions = useCallback(
    (fieldName: string, filterId?: string): Promise<TOption[]> => {
      return getValueOptionsProp(
        fieldName,
        filterId ? activeFilters.filter((filterItem) => filterItem.id !== filterId) : activeFilters
      );
    },
    [getValueOptionsProp, activeFilters]
  );

  return useMemo(
    () => ({
      fieldOptions,
      operatorOptionsMap,
      addAdvancedFilter,
      activeFilters,
      removeAdvancedFilter,
      clearAllAdvancedFilters,
      getValueOptions,
      removeAdvancedFilters
    }),
    [
      fieldOptions,
      operatorOptionsMap,
      addAdvancedFilter,
      activeFilters,
      removeAdvancedFilter,
      clearAllAdvancedFilters,
      getValueOptions,
      removeAdvancedFilters
    ]
  );
};

export default useAdvancedFilters;
