import { Bar, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip as ChartTooltip, XAxis, YAxis } from 'recharts';
import { shortenNumber } from '../../../../../utils/shortenNumber';
import { CustomLabel as CustomLabelModel } from '../../../../../components/shared/models';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useJobInfo } from '../../../../../data-access';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useDecisionsMetrics } from '@arcanna/requests/Overview';
import useGetGenericOverviewRequestPayload, { formatPercentage } from '../Overview.utils';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import BarTooltip from './BarTooltip';
import CustomLabelsList from './CustomLabelsList';
import { formattedData } from '../ArcannaAccuracy/utils';
import { computePercentage } from './ArcannaDecisions.utils';

export default function ArcannaDecisions() {
  const responsiveContainerRef = useRef(null);

  const { palette } = useTheme();
  const { jobId } = useJobIdFromUrl();
  const { payload: decisionsMetricsPayload } = useGetGenericOverviewRequestPayload();
  const decisionMetricsQuery = useDecisionsMetrics({ jobId, payload: decisionsMetricsPayload });

  const jobInfoQuery = useJobInfo(jobId);
  const jobCustomLabels = useMemo(
    () => jobInfoQuery.data?.info?.advancedSettings.customLabels ?? [],
    [jobInfoQuery.data?.info?.advancedSettings.customLabels]
  );
  const [highlightedLabels, setHighlightedLabels] = useState<string[]>(jobCustomLabels.map((label) => label.id));
  const [hoveredLabelId, setHoveredLabelId] = useState<string | null>(null);
  const [tooltipActive, setTooltipActive] = useState(false);

  const data = useMemo(() => {
    return decisionMetricsQuery.data?.chartData.map((entry) => {
      const labelEntries = entry.entries;
      let totalDecisionsPerDate = 0;
      //eslint-disable-next-line
      const labelsMap = (labelEntries as any).reduce(
        //eslint-disable-next-line
        (acc: { [x: string]: any }, label: { label_id: string; count: any }) => {
          if (!highlightedLabels.includes(label.label_id)) {
            return acc;
          }
          totalDecisionsPerDate += label.count;
          acc[label.label_id] = label.count;
          return acc;
        },
        {}
      );
      return {
        date: entry.date_start,
        dateStart: entry.date_start,
        dateEnd: entry.date_end,
        ...labelsMap,
        labelIds: Object.keys(labelsMap),
        totalDecisionsPerDate,
        barId: uuidv4().toString()
      };
    });
  }, [decisionMetricsQuery.data, highlightedLabels]);

  const totalDecisions = useMemo(
    () =>
      decisionMetricsQuery.data?.chartData.reduce((acc: number, chartEntry) => {
        if (!chartEntry) {
          return acc;
        }
        // @ts-ignore
        if (chartEntry.entries.length === 0) {
          return acc;
        }
        return (
          acc +
          // @ts-ignore
          chartEntry.entries.reduce((countPerEntry, entry: { label_id: string; count: number }) => {
            return countPerEntry + entry.count;
          }, 0)
        );
      }, 0),
    [decisionMetricsQuery.data]
  );

  const totalHighlightedDecisions = useMemo(
    () =>
      data?.reduce((acc, entry) => {
        return acc + entry.totalDecisionsPerDate;
      }, 0),
    [data]
  );

  const labelIdToLabelNameMap = jobCustomLabels.reduce((acc: Record<string, { name: string; color: string }>, label) => {
    acc[label.id] = { name: label.name, color: label.hexColor };
    return acc;
  }, {});

  const getBarFillColor = useCallback(
    (labelInfo: CustomLabelModel) => {
      if (hoveredLabelId === null && !tooltipActive) {
        return labelInfo.hexColor;
      }
      if (hoveredLabelId === labelInfo.id) {
        return labelInfo.hexColor;
      }
      return labelInfo.hexColor + '6D';
    },
    [tooltipActive, hoveredLabelId]
  );

  return (
    <Stack spacing={'20px'} height={'228px'} direction={'row'}>
      <Stack width={'100%'} height={'100%'} spacing={'20px'}>
        <Stack direction={'row'} alignItems={'center'} spacing={'4px'} paddingLeft={'20px'}>
          <Typography variant={'subtitle2'} color={palette.secondary[100]} fontWeight={400}>
            Decisions:
          </Typography>
          <Typography color={palette.secondary[100]} variant={'h4'}>
            {totalHighlightedDecisions?.toLocaleString()}
          </Typography>
          {totalDecisions && totalHighlightedDecisions !== totalDecisions ? (
            <Typography color={palette.secondary[100]} variant={'h4'}>
              ({formatPercentage((totalHighlightedDecisions * 100) / totalDecisions)})
            </Typography>
          ) : null}
        </Stack>
        <ResponsiveContainer width={835} height={200} ref={responsiveContainerRef}>
          <ComposedChart
            onMouseLeave={() => {
              setTooltipActive(false);
            }}
            data={formattedData(data ?? [])}
          >
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              interval={Math.round((data?.length || 0) / 10)}
              tick={{ fontSize: '12px' }}
              tickMargin={14}
              stroke={palette.secondary[400]}
            />

            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={shortenNumber}
              tick={{ fill: palette.secondary[400], fontSize: '12px' }}
            />

            <CartesianGrid vertical={false} stroke={'var(--secondary-color-nb-800)'} />

            <Bar barSize={16} key="empty" dataKey="empty" stackId="stack" />

            {[...jobCustomLabels].reverse().map((labelInfo: CustomLabelModel) => {
              if (labelInfo) {
                const labelId = labelInfo.id;
                return (
                  <Bar
                    activeBar={{ fill: labelInfo.hexColor }}
                    barSize={16}
                    key={labelId}
                    dataKey={labelId}
                    fill={getBarFillColor(labelInfo)}
                    stackId="stack"
                  />
                );
              }
            })}
            <ChartTooltip
              content={({ active, payload }) => {
                if (!payload) return null;
                if (!payload[0]) return null;

                setTooltipActive(active ?? false);
                const tooltipData = {
                  dateStart: moment(payload[0].payload.dateStart).format('DD-MM-YYYY HH:mm'),
                  dateEnd: moment(payload[0].payload.dateEnd).format('DD-MM-YYYY HH:mm'),
                  total: payload[0].payload.totalDecisionsPerDate,
                  labelsData: payload[0].payload.labelIds.map((labelId: string) => ({
                    name: labelIdToLabelNameMap[labelId].name,
                    color: labelIdToLabelNameMap[labelId].color,
                    count: payload[0].payload[labelId],
                    percentage: computePercentage(payload, labelId)
                  }))
                };
                return <BarTooltip content={tooltipData ?? {}} />;
              }}
              cursor={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Stack>
      <Box width={'1px'} height={'100%'} sx={{ backgroundColor: palette.secondary[800] }} />
      <CustomLabelsList
        labels={jobCustomLabels}
        onHide={(labelId) => {
          setHighlightedLabels([...highlightedLabels.filter((highlightedLabel) => labelId !== highlightedLabel)]);
        }}
        onHighlight={(labelId) => {
          setHighlightedLabels(labelId === null ? [...jobCustomLabels.map((label) => label.id)] : [labelId]);
        }}
        onShow={(labelId) => {
          setHighlightedLabels([...highlightedLabels, labelId]);
        }}
        onHover={setHoveredLabelId}
      />
    </Stack>
  );
}
