import { Drawer } from '@arcanna/generic';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EventSampleDrawerBody, EventSampleDrawerCollapsible, EventSampleDrawerSubtitle } from './components';
import { GetRandomEventResponse } from '@arcanna/models/Event';
import { TFilterItem, TUseAdvancedFilters } from '@arcanna/components';

type TEventSampleDrawerProps = {
  jobId: number;
  isEventSampleDrawerOpen: boolean;
  setIsEventSampleDrawerOpen: (isOpen: boolean) => void;
  eventSample: GetRandomEventResponse | undefined;
  setEventSample: (event: GetRandomEventResponse) => void;
  eventId: string;
  setEventId: (eventId: string) => void;
  filters: TFilterItem[];
  advancedFilters: TUseAdvancedFilters;
  getFilterSourceByName: (name: string) => string | undefined;
};

function EventSampleDrawer({
  jobId,
  isEventSampleDrawerOpen,
  setIsEventSampleDrawerOpen,
  eventSample,
  setEventSample,
  eventId,
  setEventId,
  filters,
  advancedFilters,
  getFilterSourceByName
}: TEventSampleDrawerProps) {
  const { t } = useTranslation(['codeBlock']);
  const { palette } = useTheme();

  return (
    <Drawer
      anchor="right"
      open={isEventSampleDrawerOpen}
      onClose={() => setIsEventSampleDrawerOpen(false)}
      header={
        <Typography variant="h4" color={palette.secondary[300]}>
          Event sample
        </Typography>
      }
      sx={{
        '.drawerBody': {
          padding: '12px 16px'
        }
      }}
      width="55vw"
      type="light"
    >
      <EventSampleDrawerSubtitle />
      <Stack my={2} gap={2}>
        <Typography variant="h4" color={palette.secondary[300]}>
          {t('codeBlock:editTestInputDrawer.titleEvent')}
        </Typography>
        <EventSampleDrawerCollapsible
          jobId={jobId}
          setEventSample={setEventSample}
          eventSample={eventSample}
          eventId={eventId}
          setEventId={setEventId}
        />
        <EventSampleDrawerBody
          jobId={jobId}
          filters={filters}
          setEventSample={setEventSample}
          eventSample={eventSample}
          advancedFilters={advancedFilters}
          getFilterSourceByName={getFilterSourceByName}
        />
      </Stack>
      <Button onClick={() => setIsEventSampleDrawerOpen(false)} variant="tertiary" color="secondary" sx={{ marginTop: '24px' }}>
        {t('common:close')}
      </Button>
    </Drawer>
  );
}

export default EventSampleDrawer;
