import { Drawer } from '@arcanna/generic';
import StorageTagManagementTable from './components/StorageTagManagementTable';

type TStorageTagManagementDrawerProps = {
  isOpen: boolean;
  handleClose: () => void;
};

function StorageTagManagementDrawer({ isOpen, handleClose }: TStorageTagManagementDrawerProps) {
  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose} header="Storage Tags Management" hideBackdrop={false}>
      <StorageTagManagementTable />
    </Drawer>
  );
}

export default StorageTagManagementDrawer;
