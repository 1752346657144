import { AIModelRecomputeMetricsRequest } from '@arcanna/models/AIModels';
import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';
import { AIModelKeys } from './keys';

function useRecomputeAIModelMetrics({ jobId }: { jobId: number }) {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);
  const queryClient = useQueryClient();

  // SETUP
  const URL = useMemo(() => config.api.aiModel.recomputeMetrics(jobId), [jobId]);
  const axiosFunction = useCallback((body: object) => axios.post<ResponseCommon>(URL, body), [URL]);

  return useMutation(
    AIModelKeys.recomputeMetrics(),
    (payload: AIModelRecomputeMetricsRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);
      return axiosFunction(payloadSerialized);
    },
    {
      onError: (error: AxiosResponse<ResourceWrapper<ResponseCommon>>) => {
        if (error?.data?.resource?.request?.reason) {
          showErrorNotification(t('requests:AIModels.recomputeMetrics.error.title'), error.data.resource.request.reason);
        } else {
          showErrorNotification(
            t('requests:AIModels.recomputeMetrics.error.title'),
            t('requests:AIModels.recomputeMetrics.error.subtitle')
          );
        }
      },
      onSettled: async () => {
        await queryClient.invalidateQueries([config.api.jobAllRetrainHistory.replace(':jobId', jobId.toString())]);
      }
    }
  );
}

export default useRecomputeAIModelMetrics;
