import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { LOCAL_STORAGE_TOKEN_NAME } from 'src/components/pages/Main/Login/AuthConstants';
import { config } from 'src/config';
import { AIModelKeys } from './keys';
import { useTranslation } from 'react-i18next';
import { showSuccessNotification } from 'src/components/shared/utilities/notification';

function useImportModel({ jobId }: { jobId: number }) {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);
  const queryClient = useQueryClient();

  // SETUP
  const URL = useMemo(() => config.api.aiModel.import(jobId), [jobId]);
  const axiosFunction = useCallback(
    (formData: FormData) =>
      axios.post(URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }),
    [URL]
  );

  return useMutation(
    AIModelKeys.importModel(),
    ({ files, fileName }: { files: File[]; fileName: string }) => {
      const formData = new FormData();

      formData.append('file', files[0]);

      const body = JSON.stringify({
        user_session_id: localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME) ?? '',
        model_id: files[0]?.name?.split('.')?.[0],
        filename: fileName
      });
      formData.append('body', body);

      return axiosFunction(formData);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([config.api.jobAllRetrainHistory.replace(':jobId', jobId.toString())]);

        showSuccessNotification(
          t('requests:AIModels.importModel.success.title'),
          t('requests:AIModels.importModel.success.subtitle')
        );
      }
    }
  );
}

export default useImportModel;
