import { ResponseCommon } from '@arcanna/models/utils';
import { Serializer } from '@arcanna/utils';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { showErrorNotification, showSuccessNotification } from 'src/components/shared/utilities/notification';
import { config } from 'src/config';
import { AIModelKeys } from './keys';
import { AIModelRenameRequest } from '@arcanna/models/AIModels/AIModelRenameRequest';

function useRenameAIModel({ jobId }: { jobId: number }) {
  // OTHER HOOKS
  const { t } = useTranslation(['requests']);
  const queryClient = useQueryClient();

  // SETUP
  const URL = useMemo(() => config.api.aiModel.rename(jobId), [jobId]);
  const axiosFunction = useCallback(
    (body: object) =>
      axios
        .post<ResponseCommon>(URL, body)
        .then((response) => Serializer.getInstance().deserializeObject(response.data, ResponseCommon)),
    [URL]
  );

  return useMutation(
    AIModelKeys.rename(),
    (payload: AIModelRenameRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);
      return axiosFunction(payloadSerialized);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([config.api.jobAllRetrainHistory.replace(':jobId', jobId.toString())]);
        showSuccessNotification(
          t('requests:AIModels.renameModel.success.title'),
          t('requests:AIModels.renameModel.success.subtitle')
        );
      },
      onError: (error: AxiosResponse<ResponseCommon>) => {
        if (error?.data?.request?.reason) {
          showErrorNotification(t('requests:AIModels.renameModel.error.title'), error.data.request.reason);
        } else {
          showErrorNotification(
            t('requests:AIModels.renameModel.error.title'),
            t('requests:AIModels.renameModel.error.subtitle')
          );
        }
      }
    }
  );
}

export default useRenameAIModel;
